import { Stack, Typography, Button, SvgIconTypeMap, Theme, SxProps } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

/**
 * @typedef {object} ComponentParams
 * @property {SvgIconTypeMap} icon
 * @property {string} title
 * @property {string} message
 * @property {string} ButtonMessage
 * @property {Function} callback
 * @property {boolean} horizontal
 * @property {string} message
 * @property {string} justifyContent
 * @property {string} alignItems
 * @property {SxProps<Theme>} sx
 */

/**
 *
 * @param {ComponentParams} params
 * @returns
 */

export default function Message(params) {
  const { icon, title, message, buttonMessage, callback, horizontal, justifyContent, alignItems, sx } = params
  if (horizontal) {
    return (
      <Stack
        spacing={1}
        direction='row'
        justifyContent={justifyContent ?? 'center'}
        alignItems={alignItems ?? 'center'}
        sx={{ ...sx }}
      >
        {icon ?? <ErrorOutlineOutlinedIcon />}
        <Stack
          spacing={1}
          direction='column'
          justifyContent='center'
          alignItems='flex-start'
        >
          {title && <Typography variant='h2'>{title}</Typography>}
          {message && <Typography variant='body1'>{message}</Typography>}
          {typeof callback === 'function' && <Button onClick={() => callback()}>{buttonMessage}</Button>}
        </Stack>
      </Stack>
    )
  } else {
    return (
      <Stack
        spacing={1}
        justifyContent={justifyContent ?? 'center'}
        alignItems={alignItems ?? 'center'}
      >
        {icon ?? <ErrorOutlineOutlinedIcon />}
        <Typography variant='h2'>{title}</Typography>
        <Typography variant='body1'>{message}</Typography>
        {typeof callback === 'function' && <Button onClick={() => callback()}>{buttonMessage}</Button>}
      </Stack>
    )
  }
}
