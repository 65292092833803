import React, { useState } from 'react'
import { Typography, Stack, Button } from '@mui/material'
import { ModalView } from '../../Shared'
import QRCode from 'react-qr-code'

export default function ShowGiveCutOff({ displayState, cutOff, ticket }) {
  const [show, setShow] = displayState
  const [downloadingPDF, setDownloadingPDF] = useState(false)
  const { cutOffId } = cutOff

  const handleGetToken = async () => {
    try {
      setDownloadingPDF(true)

      const response = await fetch(`${sessionStorage.getItem('localhost')}/api/v2/cut-offs/${cutOffId}/pdf`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            'Access-Control-Allow-Origin': '*'
        }
      });

      if(response.ok){
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'archivo.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        return
      }

      throw new Error("No se pudo en descargar el PDF, intentelo más tarde.")
    } catch (err) {
      console.error('Error al descargar el PDF:', err);
    } finally {
      setDownloadingPDF(false)
    }
  };

  return (
    <ModalView
      displayState={[show, setShow]}
      width={'30%'}
      maxHeight={'70%'}
    >
      <Stack spacing={2}>
        <Typography variant='h3'>Escanea el QR desde la aplicación para recibir el corte.</Typography>
        <Stack
          justifyContent='center'
          alignItems='center'
        >
          <div style={{ background: 'white', padding: '16px' }}>
            <QRCode
              value={`${ticket}`}
              level='H'
            />
          </div>
        </Stack>
      </Stack>
      <Stack
        marginTop={1}
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
      >
        <Button
          disabled={downloadingPDF}
          onClick={handleGetToken}
        >
          Obtener Pdf
        </Button>
        <Button 
          onClick={() => setShow(false)}
        >
          Cerrar
        </Button>
      </Stack>
    </ModalView>
  )
}
