import React, { useState } from 'react';
import {Howl, Howler} from 'howler';

import CoinsSound from "../../../../sounds/coins.mp3"
import BuzzerSound from "../../../../sounds/buzzer.mp3"

import {
    FormControlLabel,
    FormControl,
    FormGroup,
    Button,
    InputLabel,
    IconButton,
    InputAdornment,
    Grid,
    OutlinedInput,
    Switch,
    Typography,
    Checkbox
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import {
    Download
} from '@mui/icons-material';

import { SiMicrosoftexcel } from "react-icons/si";
import { MdSend } from "react-icons/md";
import {
    GiKitchenScale,
    GiHouse
} from "react-icons/gi";
import { TfiRuler } from "react-icons/tfi";
import { AiOutlineBarcode } from "react-icons/ai";
import { BsFillFileTextFill } from "react-icons/bs";
import {
    TbArrowAutofitWidth,
    TbArrowAutofitHeight
} from "react-icons/tb";

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import '../Shipping/shippingStyles.css';
import escaneoDeGuias from '../../../../assets/XLSXTemplates/escaneoDeGuias.xlsx';

const GuiasFormAdd = props => {
    const {
            listItems,
            setListItems,
            waybills,
            setWaybills,
            indexWaybillNumber,
            setIndexWaybillNumber,
            indexWaybillNumberAutoFocus,
            setIndexWaybillNumberAutoFocus,
            showingPackageDimensions,
            setShowingPackageDimensions,
            indexDescription,
            setIndexDescription,
            indexKeepDescription,
            setIndexKeepDescription
        } = props;

    /* Estados */
    const [ waybillNumber , setWaybillNumber ] = useState( indexWaybillNumber );
    const [ disabledDownloadButton , setDisabledDownloadButton ] = useState( false );
    const [ disabledXLSXFileImport , setDisabledXLSXFileImport ] = useState( false );
    const [ kg , setKg ] = useState("1.00");
    const [ width , setWidth ] = useState("0.00");
    const [ height , setHeight ] = useState("0.00");
    const [ long , setLong ] = useState("0.00");
    const [ origenCP , setOrigenCP ] = useState("");
    const [ destinoCP , setDestinoCP ] = useState("");
    const [ description , setDescription ] = useState( indexDescription );
    const [ keepDescription , setKeepDescription ] = useState( indexKeepDescription );
    const [ areThereMistakesIntoExcelImport , setAreThereMistakesIntoExcelImport ] = useState(null);
    const [ excelFileName , setExcelFileName ] = useState("");

    /* Información del usuario */
    //const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    /* Objeto para la configuración de enviroment */
    const envConfig = {
        url : ( process.env.REACT_APP_ENVIROMENT === "prod" ) ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
    }

    /* Declaraciones de objetos y funciones para reproducir los efectos de sonidos */
    const sound = Howler;
    sound.volume(1);

    const succesSound = new Howl({
        src:CoinsSound,
        preload:false,
        html5:true
    })
    const errorSound = new Howl({
        src:BuzzerSound,
        preload:false,
        html5:true
    })

    const playSuccess = async ()=>{
        succesSound.play();
        return;
    }
    const playError = async ()=> {
        errorSound.play();
        return;
    }

    /* Funciones para el formulario de escaneo de guias */

    //Funciones para actualizar el estado de cada input del formulario.
    const catchWaybillNumber = (e) => setWaybillNumber( e.target.value );
    const catchWidth = (e) => setWidth( e.target.value.replace(/[^0-9.]/g,"").replace(/^(\d*\.?)|(\d*)\.?/g,"$1$2") );
    const catchHeight = (e) => setHeight( e.target.value.replace(/[^0-9.]/g,"").replace(/^(\d*\.?)|(\d*)\.?/g,"$1$2") );
    const catchLong = (e) => setLong( e.target.value.replace(/[^0-9.]/g,"").replace(/^(\d*\.?)|(\d*)\.?/g,"$1$2") );
    const catchKG = (e) => setKg( e.target.value.replace(/[^0-9.]/g,"").replace(/^(\d*\.?)|(\d*)\.?/g,"$1$2") );
    const catchOrigenCP = (e) => setOrigenCP( e.target.value.replace(/[^0-9]/g,"") );
    const catchDestinoCP = (e) => setDestinoCP( e.target.value.replace(/[^0-9]/g,"") );
    const catchDescription = (e) => setDescription( e.target.value.replace(/[^a-zA-Z0-9.,@ ]/g,"") );
    const blockingTheEnterKeyAndScanner = e => { if( e.keyCode === 13 && showingPackageDimensions ){ e.preventDefault() } };
    
    //Función para limpiar los inputs del formulario de escaneo de guias
    const cleanTheScannerWaybillForm = () => {
        setIndexWaybillNumber("");
        setKg("1.00");
        setWidth("0.00");
        setHeight("0.00");
        setLong("0.00");
        setOrigenCP("");
        setDestinoCP("");
        setAreThereMistakesIntoExcelImport(null);
        setExcelFileName("");

        setIndexDescription( !keepDescription ? "" : description );
        setIndexKeepDescription( keepDescription );
    };

    //Función para el switch de ingresar información adicional
    const showPackageDimensions = () => {
        setIndexWaybillNumberAutoFocus( !indexWaybillNumberAutoFocus );
        setIndexWaybillNumber( waybillNumber );
        setIndexDescription( "" );
        setIndexKeepDescription( false ); 
        setShowingPackageDimensions( !showingPackageDimensions ); 
    }

    //Función para el checkbox de mantener la descripción
    const isCheckedKeepDescription = () => setKeepDescription( !keepDescription );

    const validWaybill = (waybill) => (
                                        waybill.length>=10 &&
                                        waybill.length<=30 &&
                                        waybill!=="" &&
                                        waybill.search(' ', '\t', '\n')===-1 &&
                                        !waybill.match(/[^a-zA-Z0-9]/g) &&
                                        listItems.filter((value)=>(value.numero===waybill)).length === 0
                                    );

    //Funcion para descargar el template de escaneo de guias
    const downloadScannWaybillsTemplate = async () => {

        setDisabledDownloadButton( true );

        await fetch( escaneoDeGuias , {
            method : 'GET',
            mode : 'no-cors',
            referrerPolicy : 'no-referrer'
        } )
            .then( res => res.blob() )
            .then( blob => {
                const download = require('downloadjs');
                download( blob , 'escaneoDeGuias.xlsx' , 'application/vnd.ms-excel' );
            } )
            .catch( error => alert( 'Algo ocurrio :C ' + error ) );

        setDisabledDownloadButton( false );
    }

    //Funcion para obtener la informcion de un archivo Excel
    const XLSXFileImport = async ( event ) => {
        const excelFile = event.target.files[0];

        /* Comprobando si el archivo sea extención (.xlsx) */
        if( event.target.files[0].name.slice( -4 ) === 'xlsx' ){
            const data = await excelFile.arrayBuffer();
            const workBook = XLSX.read( data );
            const workSheet = workBook.Sheets[ workBook.SheetNames[0] ];
            const sheetToJSON = XLSX.utils.sheet_to_json( workSheet );

            sheetToJSON.forEach( object => {
                /* Sí tiene la columna status, sera retirado. */
                if( object.status ){ delete object.status; }
                /* Comprobando si esta vacio el dato de numero */
                if( !object.numero ){ object.numero = ""; }

                /* Realizando ciertas modificaciones */
                Object.keys( object ).forEach( keyName => {
                    /* Verificando y removiendo la comilla en los siguientes datos */
                    if(
                        (
                            keyName === "origenNumExt"     ||
                            keyName === "origenNumInt"     ||
                            keyName === "origenCP"         ||
                            keyName === "origenTelefono1"  ||
                            keyName === "origenTelefono2"  ||
                            keyName === "destinoNumExt"    ||
                            keyName === "destinoNumInt"    ||
                            keyName === "destinoCP"        ||
                            keyName === "destinoTelefono1" ||
                            keyName === "destinoTelefono2"
                        ) &&
                        (
                            object[ keyName ].slice( 0 , 1 ) === "'"
                        )
                    ){
                        object[ keyName ] = object[ keyName ].slice( 1 , object[ keyName ].length );
                    }

                    /* Removiendo los asteriscos de las columnas obligatorias */
                    if( keyName.slice( -1 ) === '*' ){
                        object[ keyName.slice( 0 , keyName.length - 1 ) ] = object[ keyName ];
                        delete object[ keyName ];
                    }
                } );

                /* Agregando los datos faltantes */
                object.created_at = new Date();
            } );

            /* Se revisara si hubo un detalle en nuestro arreglo de objetos */
            setExcelFileName( event.target.files[0].name );
            checkingExcelImportData( sheetToJSON );
            return;
        }

        /* En caso contrario */
        setAreThereMistakesIntoExcelImport(null);
        alert(`Error en la importación\n\n-Asegure que el archivo que importo tiene que ser de extensión (.xlsx):\n\t•${ event.target.files[0].name }`);
    }

    //Función para recrear el archivo XLSX
    const arrayJsonToXLSX = ( excelArray , fileName ) => {
        const workSheet = XLSX.utils.json_to_sheet( excelArray );
        const workBook = { Sheets : { data : workSheet } , SheetNames : ['data'] };
        const excelBuffer = XLSX.write( workBook , { bookType : 'xlsx' , type : 'array' } );
        const data = new Blob( [ excelBuffer ] , { type : 'application/vnd.ms-excel' } );
        FileSaver.saveAs( data , fileName );
    };

    //Realizando petición HTTP para revisar la informacíon extraida de excel
    const checkingExcelImportData = async ( waybills ) => {
        /* Deshabilitando el boton de importacion de archivos XLSX */
        setDisabledXLSXFileImport( true );
        const XLSXImportInput = document.querySelector('#XLSXImportInput');
        XLSXImportInput.style.border = '2px solid #ebebe4';
        XLSXImportInput.style.backgroundColor = '#ebebe4';
        XLSXImportInput.style.color = '#d3d3d3';

        /* Asignando la URL correspondiente */
        const URL = envConfig.url + '/api/v2/tracking/check';

        /* Objeto para enviar a back-end */
        const excelData = {
            data : {
                waybills : waybills,
                listItems : listItems
            }
        }

        /* Realizando petición HTTP */
        await fetch( URL , {
            method : "POST",
            headers : {
                "Content-Type" : "application/json",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`,
                "Cache-Control": "no-cache"
            },
            body : JSON.stringify( excelData )
        } )
            .then( response => ( !response.ok ) ? alert( `Error de conexión\n\nHubo un detalle al momento de realizar la conexión con el servidor.\nIntentelo más tarde.` ) : response.json() )
            .then( data => {
                /* Asignando el objeto al estado de importación de excel */
                setAreThereMistakesIntoExcelImport( data.data );
                //console.log( data.data );

                /* Ejecutamos el submit del formulario */
                const submitButton = document.getElementById('submitButton');
                submitButton.click();
            })
            .catch( error => alert( `Error de conexión\n\nNo fue posible en establecer conexión con el servidor.\nIntentelo más tarde.` ) )
        
        /* Habilitando nuevamente la importación de XLSX */
        setDisabledXLSXFileImport( false );
        XLSXImportInput.style.border = '2px solid #009f2d';
        XLSXImportInput.style.backgroundColor = '#009f2d';
        XLSXImportInput.style.color = '#ffffff';
    }

    //Función para detectar registros invalidos de la importación de excel
    const searchingExcelRecordsInvalids = () => {
        /* Cargamos el listado de registros de excel */
        const excelRecords = areThereMistakesIntoExcelImport.ExcelImportData;
        /* Cargamos un listado de registros de excel temporal por si se ocupa actualizarlo */
        let gettingExcelRecordsValids = areThereMistakesIntoExcelImport.ExcelImportData;
        /* Listado para guardar registros de excel invalidos */
        let gettingExcelRecordsInvalids = [];

        /* Capturando registros de excel invalidos */
        excelRecords.forEach( record => {
            if( record.status !== 'Valido ✔' ){
                delete record.created_at;
                gettingExcelRecordsInvalids.push( record );
            }
        } );

        /* Conservando los registros de excel validos */
        gettingExcelRecordsValids = gettingExcelRecordsValids.filter( record => record.status === 'Valido ✔' );

        /* Retirando los asteriscos de las columnas obligatorias */
        gettingExcelRecordsValids.forEach( record => {
            Object.keys( record ).forEach( column => {
                if( column.slice( -1 ) === '*' ){
                    record[ column.slice( 0 , column.length - 1 ) ] = record[ column ];
                    delete record[ column ];
                }
            } )
        } );

        return [ gettingExcelRecordsInvalids , gettingExcelRecordsValids ];
    }

    //Función para actualizar el estado de listItems y waybills
    const handleSubmit = async (e) => {
        e.preventDefault();

        /* Comprobando si hubo importación (.xlsx) */
        if( areThereMistakesIntoExcelImport !== null ){

            /* Si hubo algún detalle, entonces se realiza lo siguiente */
            if( areThereMistakesIntoExcelImport.IsThereAMistake ){
                /* Comprobando en nuestra importación de excel ¿cual de ellas es valida y cuales no? */
                const areThereExcelRecordsInvalids = searchingExcelRecordsInvalids();

                /* Se actualizan los estados de listItems y waybills */
                await setListItems([ ...listItems , ...areThereExcelRecordsInvalids[1] ]);
                playError();
                setWaybills( waybills + areThereExcelRecordsInvalids[1].length );

                /* Recreando y devolviendo el archivo de excel(.xlsx) */
                arrayJsonToXLSX( areThereExcelRecordsInvalids[0] , excelFileName );

                /* Cargando el mensaje de error */
                setTimeout( () => {
                    alert(`Hubo un detalle en la importación en el template.\n\n-Se le va a devolver el archivo(.XLSX) para que lo revise:\n\t•Cantidad de registros invalidos: ${ areThereExcelRecordsInvalids[0].length }`);
                } , 1000 );

                /* Reseteando estados */
                cleanTheScannerWaybillForm();
            }else{
                /* Cargamos las guias */
                await setListItems([ ...listItems , ...areThereMistakesIntoExcelImport.ExcelImportData ]);
                playSuccess();
                setWaybills( waybills + areThereMistakesIntoExcelImport.ExcelImportData.length );

                /* Reseteando estados */
                cleanTheScannerWaybillForm();
            }
        }else{
            if(
                (
                    validWaybill(waybillNumber) &&
                    origenCP.length === 5 &&
                    destinoCP.length === 5
                ) ||
                (
                    validWaybill(waybillNumber) &&
                    (
                        (
                            origenCP.length === 5 &&
                            destinoCP === ""
                        ) ||
                        (
                            destinoCP.length === 5 &&
                            origenCP === ""
                        )
                    )
                ) ||
                (
                    validWaybill(waybillNumber) &&
                    origenCP === "" &&
                    destinoCP === ""
                )
            ){
                /* Actualizando los estados de listItems y waybills */
                await setListItems([ ...listItems ,
                    {
                        numero: waybillNumber,
                        kg: ( kg !== '' ) ? parseFloat( kg ) : 1,
                        alto: ( height !== '' ) ? parseFloat( height ) : 0,
                        ancho: ( width !== '' ) ? parseFloat( width ) : 0,
                        largo: ( long !== '' ) ? parseFloat( long ) : 0,
                        origenCP: ( origenCP !== "" ) ? origenCP : "",
                        destinoCP: ( destinoCP !== "" ) ? destinoCP : "",
                        descripcion: description,
                        created_at: new Date()
                    }
                ]);
                playSuccess();
                setWaybills( waybills + 1 );

                /* Reseteando estados */
                cleanTheScannerWaybillForm();
            }else{
                playError();
                setWaybills(waybills+1);
                setTimeout(function (){
                    alert("Guia ´"+waybillNumber+"´ no valida o repetida")
                },1000);

                /* Reseteando estados */
                cleanTheScannerWaybillForm();
            }
        }
    }

    return(
        <form action="#" onSubmit={handleSubmit}>
            <FormGroup>
                <FormControl
                    variant = "outlined"
                >
                    <InputLabel htmlFor="my-input">Guia Llegada</InputLabel>
                    <OutlinedInput
                        id="guia"
                        inputProps={{
                            maxLength: 30,
                            style: {
                                fontSize : "15px"
                            }
                        }}
                        label="Guia Llegada"
                        aria-describedby="my-helper-text"
                        autoFocus={indexWaybillNumberAutoFocus}
                        onChange={catchWaybillNumber}
                        onKeyDown={blockingTheEnterKeyAndScanner}
                        value={waybillNumber}
                        startAdornment = {
                            <InputAdornment position = "start">
                                <AiOutlineBarcode
                                    color = "#ff4500"
                                    fontWeight = "700"
                                    size = "1.21rem"
                                />
                            </InputAdornment>
                        }
                        endAdornment = {
                            <InputAdornment position="end">
                                <div
                                    style = {{
                                        display : "flex",
                                        gap : "4px"
                                    }}
                                >
                                    <IconButton
                                        sx = {{
                                            backgroundColor : "#03a7c1",
                                            color : "#ffffff",
                                            width : "30px",
                                            height : "30px",
                                            borderRadius : 2,
                                            border : "2px solid #03a7c1",
                                            "&:hover" : {
                                                backgroundColor : "#028ba1",
                                                color : "#dcdcdc",
                                                border : "2px solid #028ba1"
                                            }
                                        }}
                                        disabled = { disabledDownloadButton }
                                        title = "Descargar template de escaneo de guia"
                                        onClick = { downloadScannWaybillsTemplate }
                                    >
                                        <Download sx = {{ fontSize : "1.21rem" }}/>
                                    </IconButton>

                                    <label
                                        for = "inputExcel"
                                        className = "labelExcel labelExcel--moduloDeEscaneo"
                                        id = "XLSXImportInput"
                                    >
                                        <SiMicrosoftexcel
                                            size = "1.19rem"
                                            title = "Cargar un template de hoja de calculo"
                                        />
                                        <input
                                            id = "inputExcel"
                                            type = "file"
                                            onChange = { XLSXFileImport }
                                            disabled = { disabledXLSXFileImport }
                                            style = {{
                                                display : "none"
                                            }}
                                        />
                                    </label>
                                </div>
                            </InputAdornment>
                        }
                        size = "small"
                    />
                    <FormHelperText id="my-helper-text">Escanea guia de llegada</FormHelperText>
                </FormControl>
            </FormGroup>

            <FormGroup
                sx = {{
                    paddingLeft : "8px",
                    marginBottom : "8px"
                }}
            >
                <FormControlLabel
                    control = {
                        <Switch
                            size = "small"
                            checked = { showingPackageDimensions }
                            onClick = { showPackageDimensions }
                            inputProps = {{ "aria-label" : "controlled" }}
                        />
                    }
                    label = {
                        <Typography sx = {{ fontSize : "15px" }} >
                            Ingresar información adicional
                        </Typography>
                    }
                />
            </FormGroup>

            {
                showingPackageDimensions ? <>
                    <Grid container spacing = {2} marginBottom = {1.7}>
                        {/* KG */}
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControl
                                    variant = "outlined"
                                    fullWidth = { true }
                                >
                                    <InputLabel htmlFor="KG">KG</InputLabel>

                                    <OutlinedInput
                                        id = "KG"
                                        label = "KG"
                                        onChange = { catchKG }
                                        onKeyDown = { blockingTheEnterKeyAndScanner }
                                        inputProps = {{
                                            style : {
                                                fontSize : "15px"
                                            }
                                        }}
                                        value = { kg }
                                        startAdornment = {
                                            <InputAdornment position = "start">
                                                <GiKitchenScale
                                                    color = "#ff4500"
                                                    fontWeight = "700"
                                                    size = "1.21rem"
                                                />
                                            </InputAdornment>
                                        }
                                        size = "small"
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        {/* Ancho */}
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControl
                                    variant = "outlined"
                                    fullWidth = { true }
                                >
                                    <InputLabel htmlFor="Ancho">Ancho</InputLabel>

                                    <OutlinedInput
                                        id = "Ancho"
                                        label = "Ancho"
                                        value = { width }
                                        onChange = { catchWidth }
                                        onKeyDown = { blockingTheEnterKeyAndScanner }
                                        inputProps = {{
                                            style : {
                                                fontSize : "15px"
                                            }
                                        }}
                                        startAdornment = {
                                            <InputAdornment position = "start">
                                                <TbArrowAutofitWidth
                                                    color = "#ff4500"
                                                    fontWeight = "700"
                                                    size = "1.21rem"
                                                />
                                            </InputAdornment>
                                        }
                                        size = "small"
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        {/* Altura */}
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControl
                                    variant = "outlined"
                                    fullWidth = { true }
                                >
                                    <InputLabel htmlFor="Altura">Altura</InputLabel>

                                    <OutlinedInput
                                        id = "Altura"
                                        label = "Altura"
                                        onChange = { catchHeight }
                                        onKeyDown = { blockingTheEnterKeyAndScanner }
                                        inputProps = {{
                                            style : {
                                                fontSize : "15px"
                                            }
                                        }}
                                        value = { height }
                                        startAdornment = {
                                            <InputAdornment position = "start">
                                                <TbArrowAutofitHeight
                                                    color = "#ff4500"
                                                    fontWeight = "700"
                                                    size = "1.21rem"
                                                />
                                            </InputAdornment>
                                        }
                                        size = "small"
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        {/* Largo */}
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControl
                                    variant = "outlined"
                                    fullWidth = { true }
                                >
                                    <InputLabel htmlFor="Largo">Largo</InputLabel>

                                    <OutlinedInput
                                        id = "Largo"
                                        label = "Largo"
                                        onChange = { catchLong }
                                        onKeyDown = { blockingTheEnterKeyAndScanner }
                                        inputProps = {{
                                            style : {
                                                fontSize : "15px"
                                            }
                                        }}
                                        value = { long }
                                        startAdornment = {
                                            <InputAdornment position = "start">
                                                <TfiRuler
                                                    color = "#ff4500"
                                                    fontWeight = "700"
                                                    size = "1.21rem"
                                                />
                                            </InputAdornment>
                                        }
                                        size = "small"
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    {/* Codigos postales. */}
                    <Grid container spacing = {2} marginBottom = {1.7}>
                        {/* C.P de origen */}
                        <Grid item xs = {6}>
                            <FormGroup>
                                <FormControl
                                    variant = "outlined"
                                    fullWidth = { true }
                                >
                                    <InputLabel htmlFor = "origenCP">C.P de origen</InputLabel>

                                    <OutlinedInput
                                        id = "origenCP"
                                        label = "C.P de origen"
                                        placeholder = "#####"
                                        onChange = { catchOrigenCP }
                                        onKeyDown = { blockingTheEnterKeyAndScanner }
                                        inputProps = {{
                                            style : {
                                                fontSize : "15px"
                                            }
                                        }}
                                        value = { origenCP }
                                        startAdornment = {
                                            <InputAdornment position = "start">
                                                <GiHouse
                                                    color = "#ff4500"
                                                    fontWeight = "700"
                                                    size = "1.21rem"
                                                />
                                            </InputAdornment>
                                        }
                                        size = "small"
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        {/* C.P de destino */}
                        <Grid item xs = {6}>
                            <FormGroup>
                                <FormControl
                                    variant = "outlined"
                                    fullWidth = { true }
                                >
                                    <InputLabel htmlFor = "destinoCP">C.P de destino</InputLabel>

                                    <OutlinedInput
                                        id = "destinoCP"
                                        label = "C.P de destino"
                                        placeholder = "#####"
                                        onChange = { catchDestinoCP }
                                        onKeyDown = { blockingTheEnterKeyAndScanner }
                                        inputProps = {{
                                            style : {
                                                fontSize : "15px"
                                            }
                                        }}
                                        value = { destinoCP }
                                        startAdornment = {
                                            <InputAdornment position = "start">
                                                <MdSend
                                                    color = "#ff4500"
                                                    fontWeight = "700"
                                                    size = "1.21rem"
                                                />
                                            </InputAdornment>
                                        }
                                        size = "small"
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    {/* Descripción */}
                    <FormGroup sx = {{ marginBottom : "6px" }}>
                        <FormControl
                            variant = "outlined"
                            fullWidth = { true }
                        >
                            <InputLabel htmlFor = "Descripcion">Descripción</InputLabel>

                            <OutlinedInput
                                id = "Descripcion"
                                label = "Descripción"
                                multiline
                                minRows = {2}
                                maxRows = {2}
                                value = { description }
                                onChange = { catchDescription }
                                onKeyDown = { blockingTheEnterKeyAndScanner }
                                startAdornment = {
                                    <InputAdornment position = "start">
                                        <BsFillFileTextFill
                                            color = "#ff4500"
                                            fontWeight = "700"
                                            size = "1.25rem"
                                        />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </FormGroup>
                </> : <></>
            }

            {
                showingPackageDimensions ? <>
                    <div
                        style = {{
                            display : "flex",
                            alignItems : "center",
                            justifyContent : "space-around"
                        }}
                    >
                        <FormGroup>
                            <FormControlLabel 
                                control = {
                                    <Checkbox 
                                        checked = { keepDescription }
                                        onChange = { isCheckedKeepDescription }
                                        onKeyDown = { blockingTheEnterKeyAndScanner }
                                    />
                                }
                                label = {
                                    <Typography 
                                        sx = {{ 
                                            fontSize : "12px",
                                            fontWeight : "700"
                                        }}
                                    >
                                        Mantener la descripción
                                    </Typography>
                                }
                            />
                        </FormGroup>

                        <Button id="submitButton" variant="contained" type="submit">Agregar</Button>
                    </div>
                </> : <>
                    <Button id="submitButton" variant="contained" type="submit">Agregar</Button>
                </>
            }
        </form>
    )
}

export default GuiasFormAdd;