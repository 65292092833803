import { Button, Stack, TextField, Typography, Alert } from '@mui/material'
import { useState, useEffect } from 'react'
import { ModalView } from '../../Shared'
import { createUser, updateUser } from './Services'

/**
 * @typedef {object} User
 * @property {string} name
 * @property {string} email
 * @property {string} phone
 * @property {boolean} es_activo
 * @property {number} user_id
 * @property {('CREATE'|'UPDATE')} action
 *
 */

/**
 * @typedef {object} ComponentParams
 * @property {[any, React.SetStateAction<undefined>]} displayState
 * @property {User} [user = {}]
 * @property {React.SetStateAction<undefined>} reFetch
 */

/**
 *
 * @param {ComponentParams} params
 * @returns
 */
export default function UserForm(params) {
  const { displayState, user, reFetch } = params
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [show, setShow] = displayState
  const create = user.action === 'CREATE'

  // Data states
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [secondLastName, setSecondLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const handleAction = async () => {
    try {
      setLoading(true)
      let response = {}
      if (create) response = await createUser({ firstName, lastName, secondLastName, email, phone })
      else response = await updateUser({ firstName, lastName, secondLastName, email, phone, user_id: user.user_id })
      const { error } = response
      if (error) {
        setShowError(true)
        return
      }
      setShow(false)
      reFetch(prev => !prev)
    } catch (error) {
      setShowError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (show) {
      if (!create) {
        const nameArr = user.name.split(' ')
        const first = nameArr[0]
        const last = nameArr.length === 1 ? '' : nameArr[nameArr.length - 1]
        setFirstName(first)
        setLastName(last)
        setEmail(user.email)
        setPhone(user.phone)
      } else {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
      }
      setSecondLastName('')
    } else {
      setShowError(false)
    }
  }, [show])

  return (
    <ModalView
      displayState={displayState}
      avoidClose={loading}
    >
      <Stack spacing={2}>
        <Typography>Edite los datos necesarios</Typography>
        <TextField
          label='Nombre(s)'
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <Stack
          direction='row'
          spacing={1}
        >
          <TextField
            label='Apellido paterno'
            value={lastName}
            fullWidth
            onChange={e => setLastName(e.target.value)}
          />

          <TextField
            label='Apellido materno'
            fullWidth
            value={secondLastName}
            onChange={e => setSecondLastName(e.target.value)}
          />
        </Stack>
        <TextField
          label='Email'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          label='Teléfono'
          value={phone}
          onChange={e => setPhone(e.target.value.replace(/[^0-9]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2'))}
        />
        {showError && <Alert severity='error'>¡Ocurrió un error! Inténtelo de nuevo más tarde por favor.</Alert>}
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
          justifyContent='flex-end'
        >
          <Button
            onClick={() => setShow(false)}
            disabled={loading}
          >
            Cerrar
          </Button>
          <Button
            onClick={() => handleAction()}
            disabled={loading}
          >
            {user.action === 'CREATE' ? 'Añadir' : 'Cambiar'}
          </Button>
        </Stack>
      </Stack>
    </ModalView>
  )
}
