/* Importaciones de React */
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';

/* Importaciones de elementos de MUI */
import {
    Button,
    Grid,
    Typography,
    Container,
    Paper,
    CircularProgress,
    Link
} from '@mui/material';

/* Importación de CSVLink */
import { CSVLink } from "react-csv";

/* Importaciones de componentes personalizados */
import Copyright from '../../../General/Copyright';
import HorizontalLinearStepper from '../../../General/HorizontalStepper';
import FormLocation from './form_select_location';
import ReceivedWaybills from './resume';
import GuiasFormAdd from './form_add';
import GuiasFormAddGroup from './form_add_group';

export default function GuiasIndex(props) {
    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    /* Estados para el index de ReceiveGuias */
    const [listItems, setListItems] = useState([]);
    const [waybills, setWaybills] = useState(0);
    const {locationId} = props
    const [lugar, setLugar] = useState([]);
    const [procesos, setProcesos] = useState([]);
    const [formValue, setFormValue] = useState({
        "place":0,
        "status":0
    });
    const [dataToSend, setDataToSend] = useState({
        "user_id": userData.id,
        "sucursal_id":formValue['place'],
        "rastreo_proceso_id":formValue['status'],
        "tipo_servicio_id":1,
        "kg_amparados":1,
        "waybills":listItems
    });

    /* Estados para el formulario de escaneo de guias */
    const [ indexWaybillNumber , setIndexWaybillNumber ] = useState("");
    const [ indexWaybillNumberAutoFocus , setIndexWaybillNumberAutoFocus ] = useState(true);
    const [ showingPackageDimensions , setShowingPackageDimensions ] = useState(false);
    const [ indexDescription , setIndexDescription ] = useState("");
    const [ indexKeepDescription , setIndexKeepDescription ] = useState(false);

    /* Objeto de enviroment */
    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };
    
    /* useEffect */
    useEffect(()=>{
        setDataToSend(
            {
                "user_id": userData.id,
                "sucursal_id":formValue['place'],
                "rastreo_proceso_id":formValue['status'],
                "tipo_servicio_id":1,
                "kg_amparados":1,
                "waybills":listItems
            }
        );
    },[formValue,listItems,locationId]);

    useEffect(()=>{
        /* Cargando los estados de dataToSend, listItems y formValue */
        const localItems = JSON.parse(localStorage.getItem("dataToSend"));
        if(localItems!==null){
            //console.log();
            setDataToSend(
                localItems
            );
            
            setListItems(localItems["waybills"])
            setFormValue({"place":localItems["sucursal_id"],"status":localItems["rastreo_proceso_id"]})
        }

        /* Creando item isOpenPackageDimensionsSwitch para el localstorage */
        localStorage.setItem( "isOpenPackageDimensionsSwitch" , true );
    },[]);
    
    useEffect(()=>{
        localStorage.setItem("dataToSend", JSON.stringify(dataToSend));
    },[dataToSend]);

    /* Funciones para el modulo de escaneo de guias */
    const dateTimeToCSVRows = ( created_at ) => {
        const date = new Date( created_at );
        
        return(
            "" +
            ( ( date.getDate() < 10 ) ? "0" + String( date.getDate() ) : date.getDate() ) + "/" +
            ( ( date.getMonth() < 9 ) ? "0" + String( date.getMonth() + 1 ) : date.getMonth() + 1 ) + "/" +
            ( date.getFullYear() ) + " -- " +
            ( ( date.getHours() < 10 ) ? "0" + String( date.getHours() ) : date.getHours() ) + ":" + 
            ( ( date.getMinutes() < 10 ) ? "0" + String( date.getMinutes() ) : date.getMinutes() )
        );
    }

    const downloadTicket = async uuid => {
        await fetch(`${ envConfig.url }/api/v2/tracking/download/${ uuid }`, {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : `Bearer ${ sessionStorage.getItem("token") }`,
                "Cache-Control" : "no-cache"
            }
        })
            .then( response => {
                if( response.ok ) return response.blob();

                alert("Hubo un problema con la petición\n\n-Por favor intente de nuevo en unos momentos.");
                return;
            })
            .then( blob => {
                const download = require("downloadjs");
                download( blob, "TicketDeMovimiento.pdf", "application/pdf");
            } )
            .catch( error => alert("Hubo un problema con la petición\n\n-No logramos establecer una conexión con el servidor.") );
    }

    const sendInfo = async (e)=>{
        e.preventDefault();

        /* Export */
        var element = (
            <CircularProgress color="success" />
        );
        ReactDOM.render(element, document.querySelector('#popUp'));

        /* Deshabilita temporalmente el boton de subir el listado de guias */
        const uploadButton = document.querySelector('#upload');
        uploadButton.disabled = true;
        uploadButton.style.backgroundColor = '#ebebe4';
        uploadButton.style.color = '#d3d3d3';

        /* Declarando nuestro arreglo bidimensional para generar el archivo() */
        const dataCsv = [
            [
                "Fecha y Hora",
                "Numero de guia",
                "Proceso",
                "Descripcion",
                "KG",
                "Largo",
                "Alto",
                "Ancho",
                "CP(Origen)",
                "CP(Destino)"
            ]
        ];

        /* Asignando arreglos a nuestro arreglo principal(dataCsv) */
        listItems.forEach(element => {
            dataCsv.push([
                dateTimeToCSVRows( element.created_at ), //Fecha y Hora
                element.numero, //Numero de guia
                procesos[formValue['status']-1]['name'], //Proceso
                element.descripcion, //Descripcion
                element.kg, //KG
                element.largo, //Largo
                element.alto, //Alto
                element.ancho,
                element.origenCP, //CP(Origen)
                element.destinoCP //CP(Destino)
            ]);
        });

        try{
            const response = await fetch(`${envConfig.url}/api/v2/tracking`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Cache-Control': 'no-cache'
                },
                body: JSON.stringify(dataToSend)
            });

            const data = await response.json();

            alert("Subido exitoso!");

            element = (
                <div>
                    <Typography
                        fontWeight = "700"
                    >
                        ¿En que formato lo quiere para descargar el movimiento que se realizo?
                    </Typography>

                    <div
                        style = {{
                            display : "flex",
                            alignItems : "center",
                            justifyContent : "center",
                            gap : "6px"
                        }}
                    >
                        <CSVLink 
                            filename={String(Date.now())+"-Recibidas.csv"}
                            data={dataCsv}
                        >
                            Descargar hoja de cálculo
                        </CSVLink>

                        <Typography
                            fontWeight = "700"
                        >
                            ó
                        </Typography>

                        <Link
                            component = "button"
                            underline = "none"
                            onClick = { () => downloadTicket( data["uuid"] ) }
                            sx = {{
                                textDecoration : "underline",
                                fontSize : "14px",
                                fontWeight : "300",
                                color : "#1f28e7"
                            }}
                        >
                            Descargar ticket
                        </Link>
                    </div>
                </div>
            );

            ReactDOM.render(element, document.querySelector('#popUp'));
        }
        catch(error){
            console.log(error);
            alert("Error al subir, descargar archivo y mandar, antes de limpiar")

            element = (
                <div>
                    <Typography
                        fontWeight = "700"
                    >
                        ¿En que formato lo quiere para descargar el movimiento que se realizo?
                    </Typography>

                    <div
                        style = {{
                            display : "flex",
                            alignItems : "center",
                            justifyContent : "center",
                            gap : "6px"
                        }}
                    >
                        <CSVLink 
                            filename={String(Date.now())+"-Recibidas.csv"}
                            data={dataCsv}
                        >
                            Descargar hoja de cálculo
                        </CSVLink>

                        <Typography
                            fontWeight = "700"
                        >
                            ó
                        </Typography>

                        <Link
                            underline = "none"
                            component = "button"
                            onClick = { () => {} }
                            sx = {{
                                textDecoration : "underline",
                                fontSize : "14px",
                                fontWeight : "300",
                                color : "#1f28e7"
                            }}
                        >
                            Descargar ticket
                        </Link>
                    </div>
                </div>
            );
            ReactDOM.render(element, document.querySelector('#popUp'));
        }

        /* Habilitando nuevamente el boton de subir el listado de guias */
        uploadButton.disabled = false;
        uploadButton.style.backgroundColor = '#ff4500';
        uploadButton.style.color = '#ffffff';
    }

    const cleanInfo=()=>{
        //Reset
        localStorage.setItem('dataToSend',null);
        window.location.reload();
    }

    const cleanInfoAlert=()=>{
        if (window.confirm("Esta accion no se puede deshacer, ¿Seguro que quieres limpiar?")) {
            cleanInfo()
        }
    }

    const ListForm=(props)=>{
        const {formValue}=props;
        const Title =()=>{
            return(((formValue['status']!==0))?
                lugar[0]['name']+": "+
                procesos[formValue['status']-1]['name']:
                <p>Falta seleccionar un Proceso</p>
            );
        }
        return(
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ 
                        p: 2, 
                        display: 'flex', 
                        flexDirection: 'column',
                        height: 100,
                        }}>
                            <Title/>
                            <div id="popUp"></div>
                    </Paper>
                </Grid>
                    {/* Chart */}
                <Grid item xs={12} md={4} lg={5}>
                    <Paper
                        sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 400,
                        }}
                    >
                        <GuiasFormAdd 
                            listItems = { listItems } 
                            setListItems = { setListItems } 
                            waybills = { waybills } 
                            setWaybills = { setWaybills }
                            indexWaybillNumber = { indexWaybillNumber }
                            setIndexWaybillNumber = { setIndexWaybillNumber }
                            indexWaybillNumberAutoFocus = { indexWaybillNumberAutoFocus }
                            setIndexWaybillNumberAutoFocus = { setIndexWaybillNumberAutoFocus }
                            showingPackageDimensions={ showingPackageDimensions }
                            setShowingPackageDimensions ={ setShowingPackageDimensions }
                            indexDescription = { indexDescription }
                            setIndexDescription = { setIndexDescription }
                            indexKeepDescription = { indexKeepDescription }
                            setIndexKeepDescription = { setIndexKeepDescription }
                        />
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={8} lg={7}>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                            p: 2,
                            mb:2,
                            display: 'flex',
                            flexDirection: 'row',
                            height: 80,
                            flexWrap:"wrap",
                            justifyContent: 'center',
                            alignItems:'center'
                            }}
                        >
                            <Typography
                                component="h3"
                                variant="h6"
                                color="#000000"
                                sx={{
                                    pr:3
                                }}
                                >
                                    Escaneadas:{waybills}
                            </Typography>
                            <Typography
                                component="h3"
                                variant="h6"
                                color="#000000"
                                sx={{
                                    pr:3
                                }}
                                >
                                    Exito:{listItems.length}
                            </Typography>
                            {((formValue['status']!==0))?
                            <Button
                                id = "upload"
                                variant = "contained" 
                                sx = {{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 40,
                                    width:"20%",
                                }}
                                onClick={ sendInfo }
                            >
                                Subir
                            </Button>
                            :""
                            }
                            <Button 
                                variant="secondary" 
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 40,
                                    width:"20%",
                                    }}
                                onClick={cleanInfoAlert}
                                >
                                    Limpiar
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                            }}
                        >
                            <GuiasFormAddGroup
                                listItems = {listItems}
                                setListItems={setListItems}
                                />
                        </Paper>
                    </Grid>
                </Grid>
                
            </Grid>
        )}
    
    /* Retornando interfaz del modulo de guias */
    return(
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <HorizontalLinearStepper 
                    titles={["Lugar Accion","Escanear"]} 
                    steps={[
                            <FormLocation
                                setLugar = {setLugar}
                                lugar = {lugar}
                                setProcesos = {setProcesos}
                                procesos = {procesos}
                                formValue = {formValue}
                                setFormValue = {setFormValue}
                            />,
                            <ListForm formValue ={formValue}/>,
                            <ReceivedWaybills/>,
                        ]}
                    disable={!lugar[0]}
                    finalMessage="Pasos Completados, presione Reiniciar"
                    />
            <Grid container paddingTop={2}>
                <Grid item xs={12}>
                    <Paper sx={{ 
                            p: 1,
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems:'center',
                            justifyContent:'center',
                            height: 50,
                        }}>
                        <Copyright sx={{ pt: 4 }} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
    
}
