import React from 'react'

import { Card, CardContent, Container, ButtonBase, Stack, Typography, Box } from '@mui/material'
import { DescriptionOutlined } from '@mui/icons-material'

/**
 * @typedef {object} ComponentParams
 * @property {object} data
 * @property {boolean} showPrePaymentCost
 */

/**
 * @param {ComponentParams} params
 * @returns
 */

export default function PrePaymentCard(params) {
  const { data, showPrePaymentCost = true } = params

  const companyColors = {
    Logistica_reyes: {
      primaryColor: '#ff4500',
      secondaryColor: '#ff8c00'
    },
    Estafeta: {
      primaryColor: '#7b0a0a',
      secondaryColor: '#c01818'
    }
  }

  const prePaymentCardCSS = {
    card: {
      border: `2px solid ${companyColors[data?.empresa].primaryColor}`,
      width: '210px',
      '&:hover': {
        border: '2px solid #dc3d01'
      }
    },
    cardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: '7px'
      }
    },
    currency: {
      waybill: { fontSize: '15px' },
      prePaymentTotal: { fontSize: '10px' }
    },
    fact: {
      color: '#000000'
    }
  }

  return (
    <Card
      variant='outlined'
      sx={prePaymentCardCSS.card}
    >
      <CardContent sx={prePaymentCardCSS.cardContent}>
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
        >
          <Container
            disableGutters={true}
            sx={{
              width: '206px',
              height: '84px'
            }}
          >
            <Box
              component='img'
              width='100%'
              height='100%'
              src={data?.logotipo}
              alt='Logotipo de empresa'
            />
          </Container>

          <Typography
            fontSize={11}
            fontWeight='700'
            color={companyColors[data?.empresa].primaryColor}
          >
            Saldo equivalente a {data?.cantidad_de_guias} servicios
          </Typography>

          {
            showPrePaymentCost ?
              <Stack
                sx={{
                  width: '95%',
                  backgroundColor: companyColors[data?.empresa].secondaryColor,
                  borderRadius: 2,
                  p: 0.4
                }}
              >
                <Stack
                  color={companyColors[data?.empresa].primaryColor}
                  bgcolor='#ffffff'
                  borderRadius={1.4}
                >
                  <Typography
                    fontSize={11.8}
                    fontWeight='800'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    Cada guía <DescriptionOutlined sx={{ fontSize: 18 }}/> te costara:
                  </Typography>

                  <Typography
                    fontSize={32}
                    fontWeight='300'
                    marginTop={-0.8}
                  >
                    ${(Number(data?.costo_del_paquete)/Number(data?.cantidad_de_guias)).toFixed(2)}<span style={{ ...prePaymentCardCSS.currency.waybill, fontWeight: '400' }}>MXN</span>
                  </Typography>
                </Stack>

                <Stack
                  color='#ffffff'
                >
                  <Typography
                    fontSize={13}
                    fontWeight='700'
                  >
                    Costo del paquete:
                  </Typography> 

                  <Typography
                    fontSize={17}
                    fontWeight='500'
                  >
                    ${data?.costo_del_paquete}<span style={{ ...prePaymentCardCSS.currency.prePaymentTotal, fontWeight: '600' }}>MXN</span>
                  </Typography>
                </Stack>
              </Stack>
            : 
              <></>
          }

          <Typography
            fontSize='15px'
            fontWeight='700'
            color={companyColors[data?.empresa].secondaryColor}
            display='flex'
            flexDirection='column'
          >
            Servicio: <span style={prePaymentCardCSS.fact}>{data?.servicio}</span>
          </Typography>

          <Typography
            fontSize='15px'
            fontWeight='700'
            color={companyColors[data?.empresa].secondaryColor}
            display='flex'
            flexDirection='column'
          >
            Vigencia: <span style={prePaymentCardCSS.fact}>{data?.vigencia_del_servicio}</span>
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}
