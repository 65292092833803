import { AutorenewOutlined, UploadFileOutlined, DeleteOutlineOutlined, ErrorOutlineOutlined } from '@mui/icons-material'
import { Alert, Button, CircularProgress, Collapse, Grid, Paper, Stack, TextField, Typography, useTheme } from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export default function TransferPayment({ amount, prePayment, setCostumerPrePayment, reference, setReference, setActualStep }) {
  const theme = useTheme()
  const actualDate = new Date().toISOString()
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')

  const [folio, setFolio] = useState('')
  const [traceFolio, setTraceFolio] = useState('')
  const [date, setDate] = useState(dayjs(actualDate))
  const [file, setFile] = useState('')

  const [gettingReference, setGettingReference] = useState(false)
  const [sendingReference, setSendingReference] = useState(false)
  const [showError, setShowError] = useState(false)
  const [timer, setTimer] = useState(null)

  async function getReference() {
    try {
      setGettingReference(true)

      const request = await fetch(`${localhost}/api/v2/payment/transfer/reference`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      if (request?.ok) {
        const response = await request.json()
        setReference(response.reference)

        return
      }

      throw new Error(`${request.status} - ${request.statusText}`)
    } catch (error) {
      console.error('Get reference:', error)
      setReference(null)
    } finally {
      setGettingReference(false)
    }
  }

  async function sendPayment() {
    try {
      setSendingReference(true)

      const request = await fetch(`${localhost}/api/v2/payment/transfer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          amount: amount,
          prePayment: prePayment !== null ? {
            id: prePayment.id,
            tipo_servicio_id: prePayment.tipo_servicio_id,
            costo_del_paquete: amount
          } : null,
          reference: reference,
          concept: 'Abono de saldo',
          folio: folio,
          trace_folio: traceFolio,
          operation_date: date.$d.toISOString(),
          voucher: file
        })
      })

      if (request?.ok) {
        const response = await request.json()
        console.log(response.costumerPrePayment)
        setCostumerPrePayment(response.costumerPrePayment)
        setActualStep(4)
        return
      }

      throw new Error(`${request.status} - ${request.statusText}`)
    } catch (error) {
      console.error('Send payment', error)
      clearTimeout(timer)
      setShowError(true)
      setTimer(
        setTimeout(() => {
          setShowError(false)
        }, 5000)
      )
    } finally {
      setSendingReference(false)
    }
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const uploadImage = async event => {
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    setFile(base64.split('base64,')[1])
    // console.log(base64.split('base64,')[1])
  }

  useEffect(() => {
    if (reference === undefined) getReference()
  }, [reference])

  if (gettingReference === true)
    return (
      <Stack
        spacing={2}
        sx={{ p: 2 }}
      >
        <Stack
          direction='row'
          spacing={2}
        >
          <CircularProgress size={40} />
          <Stack>
            <Typography variant='subtitle1'>Espera un momento</Typography>
            <Typography variant='body1'>Estamos preparando todo</Typography>
          </Stack>
        </Stack>

        <Stack
          direction='row'
          spacing={1}
          justifyContent='end'
        >
          <Button
            variant='contained'
            disabled={sendingReference}
            onClick={() => setActualStep(2)}
          >
            Atrás
          </Button>
        </Stack>
      </Stack>
    )

  if (reference === null || reference === undefined)
    return (
      <Stack
        spacing={2}
        sx={{ p: 2 }}
      >
        <Stack
          direction='row'
          spacing={2}
        >
          <ErrorOutlineOutlined sx={{ fontSize: 50 }} />
          <Stack>
            <Typography variant='subtitle1'>Ocurrió un problema</Typography>
            <Typography variant='body1'>No pudimos realizar algunas cosas, inténtalo de nuevo</Typography>
          </Stack>
        </Stack>

        <Stack
          direction='row'
          spacing={1}
          justifyContent='end'
        >
          <Button
            variant='outlined'
            disabled={sendingReference}
            onClick={() => setActualStep(2)}
          >
            Atrás
          </Button>

          <Button
            variant='contained'
            onClick={() => getReference()}
          >
            Volver a intentar
          </Button>
        </Stack>
      </Stack>
    )

  return (
    <>
      <Stack
        spacing={2}
        useFlexGap
      >
        <Typography variant='body1'>Para continuar, realiza la transferencia a la cuenta CLABE con la siguiente información</Typography>

        <Paper
          variant='outlined'
          sx={{ p: 2 }}
        >
          <Stack spacing={2}>
            <Stack
              direction='row'
              spacing={2}
              flexWrap='wrap'
              useFlexGap
            >
              <Stack>
                <Typography variant='caption'>Importe</Typography>
                <Typography
                  variant='body1'
                  sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontSize: 25 }}
                >
                  ${amount}
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction='row'
              spacing={2}
              flexWrap='wrap'
              useFlexGap
            >
              <Stack>
                <Typography variant='caption'>Cuenta CLABE</Typography>
                <Typography variant='body1'>02 1320 0406 9758 7794</Typography>
              </Stack>

              <Stack>
                <Typography variant='caption'>Banco</Typography>
                <Typography variant='body1'>HSBC</Typography>
              </Stack>

              <Stack>
                <Typography variant='caption'>Titular de la cuenta</Typography>
                <Typography variant='body1'>KILOGIS S de RL de CV</Typography>
              </Stack>
            </Stack>

            <Stack
              direction='row'
              spacing={2}
              flexWrap='wrap'
              useFlexGap
            >
              <Stack>
                <Typography
                  variant='caption'
                  sx={{ color: theme.palette.primary.main }}
                >
                  Concepto del pago
                </Typography>
                <Typography variant='body1'>Abono a cuenta</Typography>
              </Stack>

              <Stack>
                <Typography
                  variant='caption'
                  sx={{ color: theme.palette.primary.main }}
                >
                  Referencia del pago
                </Typography>
                <Typography variant='body1'>{reference ?? 'Esperando'}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>

        <Typography variant='body1'>Una vez hayas realizado la transferencia, rellana el siguiente formulario para validar el pago</Typography>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              label='Folio de operación'
              variant='outlined'
              value={folio}
              onChange={event => setFolio(event.target.value)}
              disabled={sendingReference}
              inputProps={{
                maxLength: 30
              }}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              label='Clave de rastreo (opcional)'
              variant='outlined'
              value={traceFolio}
              onChange={event => setTraceFolio(event.target.value)}
              disabled={sendingReference}
              inputProps={{
                maxLength: 30
              }}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={'en-gb'}
            >
              <DatePicker
                label='Fecha de la transacción'
                value={date}
                onChange={value => setDate(value)}
                sx={{ width: '100%' }}
                disableHighlightToday={true}
                disablePast={true}
                disabled={sendingReference}
              />
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={'en-gb'}
            >
              <TimePicker
                label='Fecha de la transacción'
                value={date}
                onChange={value => setDate(value)}
                sx={{ width: '100%' }}
                ampm={false}
                disabled={sendingReference}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Typography variant='body1'>También es necesario que incluyas un comprobante de la transacción, puede ser captura de pantalla del movimiento o el comprobante que te proporciona tu banco</Typography>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm
          >
            <Button
              variant='contained'
              component='label'
              startIcon={file === '' ? <UploadFileOutlined /> : <AutorenewOutlined />}
              fullWidth
            >
              {file === '' ? 'Subir comprobante' : 'Reemplazar comprobante'}
              <input
                type='file'
                onChange={uploadImage}
                hidden
              />
            </Button>
          </Grid>

          {file !== '' && (
            <Grid
              item
              xs={12}
              sm
            >
              <Button
                variant='outlined'
                onClick={() => setFile('')}
                color='error'
                startIcon={<DeleteOutlineOutlined />}
                fullWidth
              >
                Eliminar comprobante
              </Button>
            </Grid>
          )}
        </Grid>

        <Alert severity='info'>Es importante que a la hora de realizar la transferencia, en el campo de concepto y referencia introduzcas los datos que aparecen arriba, de esta manera podremos agilizar y realizar más rápido el abonado de saldo a tu cuenta</Alert>
      </Stack>

      <Collapse in={showError}>
        <Alert
          sx={{ marginTop: 2 }}
          severity='error'
        >
          Ocurrió un problema, inténtalo nuevamente más tarde
        </Alert>
      </Collapse>

      <Stack
        direction='row'
        justifyContent='end'
        sx={{ marginTop: 2 }}
        spacing={2}
      >
        <Button
          variant='outlined'
          disabled={sendingReference}
          onClick={() => setActualStep(2)}
        >
          Atrás
        </Button>

        <Button
          variant='contained'
          disabled={folio === '' || file === '' || sendingReference}
          startIcon={sendingReference ? <CircularProgress size={20} /> : null}
          onClick={() => sendPayment()}
        >
          Siguiente
        </Button>
      </Stack>
    </>
  )
}
