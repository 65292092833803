import { useState } from 'react'
import { VisibilityOutlined, VisibilityOffOutlined, LockResetOutlined } from '@mui/icons-material'
import { Alert, Avatar, Button, Card, CardContent, CircularProgress, Collapse, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material'

export default function ChangePassword({ close, success }) {
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')
  const theme = useTheme()

  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [timer, setTimer] = useState(null)

  async function changePassword() {
    try {
      setLoading(true)

      const request = await fetch(`${localhost}/api/v2/profile/change/password`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          old_password: password.trim(),
          new_password: newPassword.trim(),
          new_password_confirmation: newPasswordConfirmation.trim()
        })
      })

      if (request?.ok) {
        success()
        return
      } else {
        setHasError(true)
        clearTimeout(timer)
        setTimer(
          setTimeout(() => {
            setHasError(false)
          }, 5000)
        )
        return
      }
    } catch (error) {
      console.error('Change password', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card
      variant='outlined'
      // sx={{ height: '100%' }}
      sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Stack
            direction='row'
            spacing={1}
            alignContent='center'
          >
            <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>
              <LockResetOutlined sx={{ fontSize: 18 }} />
            </Avatar>
            <Typography variant='subtitle1'>Cambio de contraseña</Typography>
          </Stack>

          <Typography variant='body1'>Completa el siguiente formulario para poder actualizar tu contraseña</Typography>

          <Stack spacing={2}>
            <TextField
              variant='outlined'
              label='Contraseña actual'
              type={showPassword ? 'text' : 'password'}
              onChange={event => setPassword(event.target.value)}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}</IconButton>
                  </InputAdornment>
                )
              }}
            />

            <TextField
              variant='outlined'
              label='Nueva contraseña'
              type={showNewPassword ? 'text' : 'password'}
              onChange={event => setNewPassword(event.target.value)}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}</IconButton>
                  </InputAdornment>
                )
              }}
            />

            <TextField
              variant='outlined'
              label='Confirma tu nueva contraseña'
              type={showNewPassword ? 'text' : 'password'}
              onChange={event => setNewPasswordConfirmation(event.target.value)}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}</IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Stack>

          <Collapse in={hasError}>
            <Alert severity='error'>Ocurrió un error, revisa tu información e inténtalo nuevamente</Alert>
          </Collapse>

          <Stack
            direction='row'
            justifyContent='end'
            spacing={2}
          >
            <Button
              variant='outlined'
              disabled={loading}
              onClick={() => close()}
            >
              Cancelar
            </Button>

            <Button
              variant='contained'
              disabled={loading || newPassword.length < 8 || newPassword !== newPasswordConfirmation || password.length === 0}
              startIcon={loading ? <CircularProgress size={20} /> : null}
              onClick={() => changePassword()}
            >
              Actualizar
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>

    // <Paper
    //   variant='elevation'
    //   elevation={5}
    //   sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 5 }}
    // >
    //   <Stack spacing={2}>
    //     <Stack
    //       direction='row'
    //       alignItems='center'
    //       spacing={1}
    //     >
    //       <LockResetOutlined />
    //       <Typography variant='h2'>Cambio de contraseña</Typography>
    //     </Stack>

    //   </Stack>
    // </Paper>
  )
}
