import React, { useState, useEffect } from 'react'
import { Stack, Typography, Grid, TextField, Container, Paper, Box, Alert, AlertTitle, styled, Checkbox, Button, Collapse } from '@mui/material'
import { loadOpenPay, loadAntiFraud } from '../../Shared/index'

export default function CardPayment({ amount, prePayment, setCostumerPrePayment, holderName, cardNumber, expirationMonth, expirationYear, cvv2, setHolderName, setCardNumber, setExpirationMonth, setExpirationYear, setCvv2, setCardType, setActualStep }){
    /* Estados */
    const [openPayLoaded, setOpenPayLoaded] = useState(false)
    const [antiFraudLoaded, setAntiFraudLoaded] = useState(false)
    const [showingCardPayment, setShowingCardPayment] = useState(true)
    const [cardFrontOrBack, setCardFrontOrBack] = useState(true)
    const [cardRightFacts, setCardRightFacts] = useState(false)
    const [agreedWithThePolitic, setAgreedWithThePolitic] = useState(false)
    const [openPayChargeMessageError, setOpenPayChargeMessageError] = useState('')

    /* Componentes personalizados con styled */
    const CardFactWarningAlert = styled(Alert)({
        fontSize: '9.3px',
        fontWeight: '700',
        color: '#d88101',
        padding: '0px 13px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#ffe6c6',
        '& .MuiAlert-icon': {
            color: '#d88101',
            padding: '3px 0px'
        }
    })

    const CardFactInfoAlert = styled(Alert)({
        fontSize: '9.3px',
        fontWeight: '700',
        color: '#007abd',
        padding: '0px 13px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#9de2fe',
        '& .MuiAlert-icon': {
            color: '#007abd',
            padding: '3px 0px'
        }
    })

    /* Funciones */
    const stringToCardNumberFormat = numbers => {
        numbers = numbers.split('')
        let fourthNumbers = []
        let cardNumberFormat = ''
        const cardNumberFormatArray = []

        numbers.forEach((number, index) => {
            fourthNumbers.push(number)

            if((index + 1)%4 === 0 || (index === (numbers.length - 1) && cardNumberFormatArray.length !== 4)) {
                cardNumberFormatArray.push(fourthNumbers)
                fourthNumbers = []
            }
        })

        cardNumberFormatArray.forEach((array, index) => index !== (cardNumberFormatArray.length - 1) ? cardNumberFormat = cardNumberFormat + `${array.toString().replace(/,/g, '')} ` : cardNumberFormat = cardNumberFormat + array.toString().replace(/,/g, ''))

        return cardNumberFormat
    }

    const creatingCardToken = event => {
        try {
            /* Desactivando la función por defecto del form tag */
            event.preventDefault()

            /* Reseteando el estado de mensaje de error de openpay */
            setOpenPayChargeMessageError('')

            /* Importando OpenPay */
            const openpay = window.OpenPay

            /* Se procede en crear el token de la tarjeta */
            openpay.token.create({
                card_number: cardNumber,
                holder_name: holderName,
                expiration_year: expirationYear,
                expiration_month: expirationMonth,
                cvv2: cvv2
            }, response => makingCreditBalance(response.data.id),
               response => setOpenPayChargeMessageError(response.data.description)
            )
        } catch (error) {
            console.log(error.message)
        }
    }

    const makingCreditBalance = async cardID => {
        try {
            const fullName = holderName.split(' ')
            const openpay = window.OpenPay
            const user = JSON.parse(sessionStorage.getItem('user'))

            const request = await fetch(`${sessionStorage.getItem('localhost')}/api/v2/payment/card/abonarSaldo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Cache-Control': 'no-cache'
                },
                body: JSON.stringify({
                    comprador: {
                        nombre: fullName[0],
                        apellidos: fullName[1],
                        correo: user.email
                    },
                    pago: {
                        id_tipo_movimiento: 9,
                        token: cardID,
                        cobro: amount
                    },
                    sesion: openpay.deviceData.setup('cardPaymentForm')
                })
            })

            if(request.ok) {
                const response = await request.json()
                setCardType(openpay.card.cardType(cardNumber))
                setActualStep(4)
                return
            }

            const response = await request.json()
            throw new Error(JSON.stringify(response))
        } catch (error) {
            console.log(error.message)
        }
    }

    /* useEffects */
    useEffect(() => { if(!cardFrontOrBack && !showingCardPayment) setCardFrontOrBack(!cardFrontOrBack) }, [cardNumber, expirationMonth, expirationYear, holderName])
    useEffect(() => { if(cardFrontOrBack && !showingCardPayment) setCardFrontOrBack(!cardFrontOrBack) }, [cvv2])
    useEffect(() => setShowingCardPayment(!showingCardPayment), [])
    useEffect(() => {
        if(!openPayLoaded) loadOpenPay(setOpenPayLoaded)

        if(!antiFraudLoaded) loadAntiFraud(setAntiFraudLoaded)
    }, [openPayLoaded, antiFraudLoaded])

    return (
        <>
            <Stack
                direction='column'
                spacing={2.5}
            >
                <Typography 
                    variant='body1'
                    fontWeight='700'
                    textAlign='center'
                >
                    A continuación ingrese los datos de su tarjeta de <span style={{color: '#ff4500'}}>credito</span> ó <span style={{color: '#ff4500'}}>debito</span>.
                </Typography>

                <Grid
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        sx={theme => ({
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1.5,
                            [theme.breakpoints.between('xs', 'sm')]: {
                                paddingBottom: '16px'
                            }
                        })}
                    >
                        <Container 
                            sx={theme => ({
                                width: '399px',
                                position: 'relative',
                                transition: '.3s ease all',
                                transform: `rotateY(${cardFrontOrBack ? '0' : '180'}deg)`,
                                transformStyle: 'preserve-3d',
                                marginRight: '-4px',
                                zIndex: 2,                          
                            })}
                        >
                            <Paper
                                variant='outlined'
                                sx={{
                                    padding: '10px 25px 25px 25px',
                                    border: '1px solid #000000',
                                    borderRadius: '20px',
                                    backgroundColor: '#0097ff',
                                    color: '#cbcbcb'
                                }}
                            >
                                <Stack>
                                    <div 
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'right',
                                            gap: 4
                                        }}
                                    >
                                        <Box 
                                            component='img'
                                            src={require('../../../assets/paymentBrands/mastercard.webp')}
                                            width={56}
                                            height={41}
                                        />

                                        <Typography
                                            fontSize={33}
                                            fontWeight='400'
                                        >
                                            /
                                        </Typography>

                                        <Box 
                                            component='img'
                                            src={require('../../../assets/paymentBrands/visa.webp')}
                                            width={72}
                                            height={64}
                                        />
                                    </div>

                                    <div
                                        align='left'
                                    >
                                        <Box 
                                            component='img'
                                            src={require('../../../assets/cardElements/cardChip.png')}
                                            width='45px'
                                            height='40px'
                                        />
                                    </div>
                            
                                    <Typography
                                        fontSize='20px'
                                        fontWeight='500' 
                                        textAlign='left'
                                    >
                                        {cardNumber !== '' ? stringToCardNumberFormat(cardNumber) : '0000 0000 0000 0000'}
                                    </Typography>

                                    <Typography
                                        fontSize='16px'
                                        fontWeight='500'
                                        textAlign='right'
                                    >
                                        {Number(expirationMonth) < 10 ? `0${expirationMonth}` : expirationMonth}/{expirationYear !== '' ? Number(expirationYear) < 10 ? `0${expirationYear}` : expirationYear : '00'}
                                    </Typography>

                                    <Typography
                                        fontSize='17.5px'
                                        fontWeight='500'
                                        textAlign='left'
                                    >
                                        {holderName !== '' ? holderName : '----- ----- ----- -----'}
                                    </Typography>
                                </Stack>
                            </Paper>

                            <Paper
                                variant='outlined'
                                sx={theme => ({
                                    border: '1px solid #000000',
                                    borderRadius: '20px',
                                    backgroundColor: '#0097ff',
                                    position: 'absolute',
                                    top: 0,
                                    transform: 'rotateY(180deg)',
                                    backfaceVisibility: 'hidden',
                                    width: '89.4%',
                                    height: 214.15,
                                    [theme.breakpoints.between('xs', 'sm')]: {
                                        width: '91%'
                                    }
                                })}
                            >
                                <div
                                    style={{
                                        backgroundColor: '#000000',
                                        width: '100%',
                                        height: '40px',
                                        marginTop: '13px'
                                    }}
                                >    
                                </div>
                            
                                <div align='center' style={{ marginTop: '17px' }}>
                                    <Typography
                                       fontSize='15px'
                                       fontWeight='500'
                                       textAlign='right'
                                       color='#000000'
                                       paddingRight='5px'
                                       bgcolor='#ffffff'
                                       width='95%'
                                    >
                                        <span style={{ fontSize: '9px' }}>CVV:</span> {cvv2 !== '' ? cvv2 : '000'}
                                    </Typography>
                                </div>
                            </Paper>
                        </Container>

                        <Stack
                            direction='row'
                            width='100%'
                            sx={theme => ({
                                justifyContent: 'right'
                            })}
                        >
                            <Stack
                                direction='column'
                                width='31%'
                            >
                                <Typography
                                    fontSize='10px'
                                    fontWeight='700'
                                >
                                    Transacciones realizadas vía:
                                </Typography>

                                <Box 
                                    component='img'
                                    src={require('../../../assets/cardElements/OpenPay.webp')}
                                    width='110px'
                                    height='29px'
                                />
                            </Stack>

                            <Stack
                                direction='row'
                                alignItems='center'
                                borderLeft='2px solid #000000'
                                width='50%'
                                paddingLeft='10px'
                            >
                                <Box 
                                    component='img'
                                    src={require('../../../assets/cardElements/Shield.webp')}
                                    width='40px'
                                    height='40px'
                                />

                                <Typography
                                    fontSize='10px'
                                    fontWeight='700'
                                >
                                    {`Tus pagos se realizan de forma segura
                                    con encriptación de 256 bits.`}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        sx={theme => ({
                            display: 'flex',
                            justifyContent: 'left',
                            paddingLeft: '15.5px',
                            [theme.breakpoints.between('md', 'xl')]: {
                                borderLeft: '5px solid #000000'
                            },
                            [theme.breakpoints.between('xs', 'sm')]: {
                                borderTop: '5px solid #000000',
                                padding: '16px 0px 0px 0px'
                            }
                        })}
                    >
                        <form
                            action='#'
                            id='cardPaymentForm'
                            onSubmit={creatingCardToken}
                        >
                            <Stack
                                direction='column'
                                spacing={1}
                                width='373.45px'
                            >
                                <TextField 
                                    label='Nombre del titular'
                                    placeholder='Liliana Gomez Torres'
                                    variant='outlined'
                                    value={holderName}
                                    onChange={event => setHolderName(event.target.value.replace(/[^a-zA-Z ]/g, ''))}
                                    helperText={<CardFactWarningAlert severity='warning'>Procure en ingresar su nombre completo, tal como se registro.</CardFactWarningAlert>}
                                    focused
                                    required
                                />

                                <TextField 
                                    label='Número de tarjeta'
                                    placeholder='0000000000000000'
                                    variant='outlined'
                                    value={cardNumber}
                                    onChange={event => setCardNumber(event.target.value.replace(/[^0-9]/g, ''))}
                                    helperText={<CardFactInfoAlert severity='info'>El número de tarjeta deben ser 16 dígitos.</CardFactInfoAlert>}
                                    inputProps={{
                                        maxLength: 16
                                    }}
                                    focused
                                    required
                                />

                                <Stack spacing={0.5}>
                                    <Typography 
                                        variant="subtitle2"
                                        fontWeight='700'
                                        textAlign="center"
                                    >
                                        Fecha de <span style={{color: '#ff4500'}}>expiración</span>
                                    </Typography>

                                    <Stack
                                        direction='row'
                                        spacing={1}
                                    >
                                        <TextField 
                                            label='Mes'
                                            placeholder='00'
                                            variant='outlined'
                                            value={Number(expirationMonth) < 10 ? `0${expirationMonth}` : expirationMonth}
                                            onChange={event => setExpirationMonth(event.target.value)}
                                            onKeyDown={event => { if(event.key !== 'Tab') event.preventDefault() }}
                                            inputProps={{
                                                type: 'number',
                                                maxLength: 2,
                                                min: 1,
                                                max: 12
                                            }}
                                            fullWidth
                                            focused
                                            required
                                        />

                                        <TextField 
                                            label='Año'
                                            placeholder='00'
                                            variant='outlined'
                                            value={expirationYear}
                                            onChange={event => setExpirationYear(event.target.value)}
                                            onKeyDown={event => { if(event.key !== 'Tab') event.preventDefault() }}
                                            inputProps={{
                                                type: 'number',
                                                maxLength: 2,
                                                min: 23,
                                                max: 38
                                            }}
                                            fullWidth
                                            focused
                                            required
                                        />
                                    </Stack>
                                </Stack>

                                <Stack spacing={0.5}>
                                    <Typography
                                        variant='subtitle2'
                                        fontWeight='700'
                                        textAlign='center'
                                    >
                                        Código de <span style={{color: '#ff4500'}}>seguridad</span>
                                    </Typography>

                                    <TextField 
                                        label='CVV'
                                        placeholder='000'
                                        variant='outlined'
                                        value={cvv2}
                                        onChange={event => setCvv2(event.target.value.replace(/[^0-9]/g, ''))}
                                        helperText={<CardFactInfoAlert severity='info'>El CVV deben ser 3 dígitos.</CardFactInfoAlert>}
                                        inputProps={{
                                            maxLength: 3
                                        }}
                                        focused
                                        required
                                    />
                                </Stack>
                        
                                <Stack
                                    direction='column'
                                    spacing={0.8}
                                >
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                    >
                                        <Checkbox
                                            checked={cardRightFacts}
                                            onChange={event => setCardRightFacts(event.target.checked)} 
                                            sx={{padding: '4px 9px'}}
                                        />

                                        <Typography
                                            fontSize='13px'
                                            fontWeight='500'
                                        >
                                            Confirmo que soy el titular de la tarjeta y que los datos son correctos.
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                    >
                                        <Checkbox
                                            checked={agreedWithThePolitic}
                                            onChange={event => setAgreedWithThePolitic(event.target.checked)} 
                                            sx={{ padding: '4px 9px' }}
                                        />

                                        <Typography
                                            fontSize='13px'
                                            fontWeight='500'
                                        >
                                            Estoy de acuerdo con la política y manejo de datos personales.
                                        </Typography>
                                    </Stack> 
                                </Stack>

                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='center'
                                    spacing={2.5}
                                >
                                    <Button
                                        variant='outlined'
                                        onClick={() => setActualStep(2)}
                                    >
                                        Atrás
                                    </Button>

                                    <Button
                                        variant='contained'
                                        disabled={holderName === '' || cardNumber === '' || cardNumber.length !== 16 || cvv2 === '' || cvv2.length !== 3 || !cardRightFacts || !agreedWithThePolitic}
                                        type='submit'
                                    >
                                        Abonar Saldo(${amount})
                                    </Button>
                                </Stack>

                                <Collapse in={openPayChargeMessageError !== ''}>
                                    <Alert 
                                        severity='error'
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: '400'
                                        }}
                                    >
                                        <AlertTitle sx={{ fontWeight: '700' }}>Algo ocurrió durante en la verificación de los datos de la tarjeta</AlertTitle>
                                        {openPayChargeMessageError}
                                    </Alert>
                                </Collapse>
                            </Stack>
                        </form>
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}