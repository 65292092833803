import { MainContainer, SnackBarAlert, Selector, SkeletonLoad, Message, Table } from '../../Shared'
import { Stack, TableCell, Typography, TableRow, Button, IconButton, Switch } from '@mui/material'
import { PersonOffOutlined, List, Edit } from '@mui/icons-material'
import { useState, useEffect, useCallback, useRef } from 'react'
import { PERMISSIONS } from '../../../utils/constants'
import { getCompanies, getUsers, updateUser } from './Services'
import { useNavigate } from 'react-router-dom'
import UserForm from './UserForm'

export default function Index() {
  // Behavior values
  const [loading, setLoading] = useState(false)
  const [loadAllowed, setLoadAllowed] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showUserForm, setShowUserForm] = useState(false)
  const navigate = useNavigate()

  // Data values
  const errorMessage = useRef('')
  const [reFetch, setReFetch] = useState(false)
  const currentUser = useRef({})
  const [company, setCompany] = useState('Todas')
  const [companies, setCompanies] = useState(/**@type {object[]|undefined} */ (undefined))
  const [users, setUsers] = useState(/**@type {object[]|undefined} */ (undefined))

  const handleAllowed = async (es_activo, id) => {
    try {
      currentUser.current = { id }
      setShowError(false)
      setLoadAllowed(true)
      const { error } = await updateUser({ user_id: id, es_activo })
      if (error) throw 'Error'
      const copy = users
      const index = copy.findIndex(u => u.id === id)
      copy[index].es_activo = es_activo
      setUsers(copy)
    } catch (error) {
      errorMessage.current = 'Ocurrió un error al habilitar el usuario, inténtelo de nuevo más tarde.'
      setShowError(true)
    } finally {
      setLoadAllowed(false)
    }
  }

  // Fetch Companies Uef
  useEffect(() => {
    const handleGetCompanies = async () => {
      try {
        const { error, companies } = await getCompanies()
        if (error) {
          errorMessage.current = 'Ocurrió un error al recuperar las empresas, intente de nuevo en unos momentos.'
          setShowError(true)
          return
        }
        const mappedCompanies = companies.map(item => item.nombre_comercial)
        setCompanies(mappedCompanies)
      } catch (error) {
        errorMessage.current = 'Ocurrió un error al recuperar las empresas, intente de nuevo en unos momentos.'
        setShowError(true)
      }
    }
    if (PERMISSIONS['role'] === 'Admin') handleGetCompanies()
  }, [])

  // Fetch Users Uef
  useEffect(() => {
    const handleGetUsers = async () => {
      try {
        setLoading(true)
        const { error, users } = await getUsers(company)
        if (error) {
          errorMessage.current = 'Ocurrió un error al recuperar la información, intente de nuevo en unos momentos.'
          setShowError(true)
          return
        }
        setUsers(users)
      } catch (error) {
        errorMessage.current = 'Ocurrió un error al recuperar la información, intente de nuevo en unos momentos.'
        setShowError(true)
      } finally {
        setLoading(false)
      }
    }
    handleGetUsers()
  }, [company, reFetch])

  const UserRow = useCallback(
    ({ item }) => {
      const { email, name, phone, empresa, id, es_activo } = item
      const disabled = currentUser.current?.id === id && loadAllowed
      return (
        <TableRow>
          <TableCell>{name}</TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>{phone ?? '-'}</TableCell>
          <TableCell>{empresa?.nombre_comercial}</TableCell>
          <TableCell>
            <Switch
              disabled={disabled}
              checked={es_activo}
              onChange={() => handleAllowed(!es_activo, id)}
            />
          </TableCell>
          <TableCell width={150}>
            <Stack
              direction='row'
              spacing={2}
              justifyContent='flex-end'
            >
              <IconButton
                title='Ver servicios'
                onClick={() => {
                  navigate('/users/services', { state: { user_id: id, name } })
                }}
              >
                <List />
              </IconButton>
              <IconButton
                title='Editar'
                onClick={() => {
                  currentUser.current = { email, name, phone, es_activo, action: 'UPDATE', user_id: id }
                  setShowUserForm(true)
                }}
              >
                <Edit />
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
      )
    },
    [users]
  )

  return (
    <MainContainer>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography
          variant='h1'
          sx={{ paddingY: 2 }}
        >
          Usuarios
        </Typography>
        {!loading && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-around'
            spacing={1}
          >
            {PERMISSIONS['users-create'] !== undefined && (
              <Button
                onClick={() => {
                  currentUser.current = { es_activo: true, action: 'CREATE' }
                  setShowUserForm(true)
                }}
              >
                Añadir
              </Button>
            )}
            {PERMISSIONS['role'] === 'Admin' && companies !== undefined && companies.length > 0 && (
              <Selector
                state={[company, setCompany]}
                label='Compañía'
                options={['Todas', ...companies]}
              />
            )}
          </Stack>
        )}
      </Stack>

      {loading && (
        <SkeletonLoad
          types={['rectangular', 'rounded']}
          numberOfItems={5}
          randomHeight
          heightRange={[60, 100]}
        />
      )}

      {!loading && (users === undefined || users.length === 0) && (
        <Message
          title='Sin usuarios'
          message='Para poder visualizar información registra algún usuario bajo tu empresa.'
          icon={<PersonOffOutlined sx={{ fontSize: 50 }} />}
        />
      )}

      {!loading && users !== undefined && users.length > 0 && (
        <Table
          data={users}
          headers={['Nombre', 'Email', 'Teléfono', 'Empresa', 'Habilitado', 'Acciones']}
          RowComponent={UserRow}
          pagination
        />
      )}

      <UserForm
        displayState={[showUserForm, setShowUserForm]}
        user={currentUser.current}
        reFetch={setReFetch}
      />

      <SnackBarAlert
        displayState={[showError, setShowError]}
        message={errorMessage.current}
        severity={'error'}
      />
    </MainContainer>
  )
}
