import { useTheme, Card, Stack, CardContent, Avatar, Typography } from '@mui/material'

export default function SectionCard({ icon, title, children, sx }) {
  const theme = useTheme()

  return (
    <Card
      variant='outlined'
      sx={{ height: '100%', ...(sx ?? {}) }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Stack
            direction='row'
            spacing={1}
            alignContent='center'
          >
            <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
            <Typography variant='subtitle1'>{title}</Typography>
          </Stack>

          {children}
        </Stack>
      </CardContent>
    </Card>
  )
}
