import { Table, TableRow, TableCell, Stack, Typography, TableHead, Button, Divider } from '@mui/material'
import { ModalView, SkeletonLoad, Message } from '../../Shared'
import { fechaConHora } from '../../../utils/FormatoDeFecha'
import { useState, useEffect, useCallback } from 'react'
import { getHistory } from './Services'
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined'

const token = sessionStorage.getItem('token')
const host = sessionStorage.getItem('localhost')

export default function ShowHistory({ cutOff, displayState }) {
  const [show, setShow] = displayState
  const { cutOffId } = cutOff
  const [users, setUsers] = useState()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (show) {
      setLoading(true)
      getHistory({ token, host, id: cutOffId })
        .then(({ users, error }) => {
          if (error) setShowError(true)
          else setUsers(users)
        })
        .catch(() => setShowError(true))
        .finally(() => setLoading(false))
    }

    return () => {
      setUsers()
    }
  }, [show])

  const UserTableRow = useCallback(({ user }) => {
    const [name, creation] = user
    const date = fechaConHora(new Date(creation))
    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell>{date}</TableCell>
      </TableRow>
    )
  }, [])

  return (
    <ModalView
      displayState={displayState}
      width={'40%'}
      maxHeight={'70%'}
    >
      <Typography
        variant='h1'
        paddingY={1}
      >
        Historial de usuarios
      </Typography>
      <Divider sx={{ marginBottom: 1 }} />
      {loading ? (
        <SkeletonLoad
          types={['rectangular, rounded']}
          randomHeight
          heightRange={[20, 50]}
          numberOfItems={4}
        />
      ) : showError ? (
        <Message
          icon={<PersonOffOutlinedIcon sx={{ fontSize: 40 }} />}
          message='No pudimos recuperar el historial de usuarios, inténtalo en unos momentos.'
        />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Fecha de recepción</TableCell>
            </TableRow>
          </TableHead>
          {users?.map(item => (
            <UserTableRow
              user={item}
              key={item[0]}
            />
          ))}
        </Table>
      )}
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
        marginTop={1}
      >
        <Button onClick={() => setShow(false)}>Cerrar</Button>
      </Stack>
    </ModalView>
  )
}
