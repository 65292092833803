import { useCallback, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, TableRow, TableCell, Stack, IconButton } from '@mui/material'
import { MONTHS_OBJ, CUT_OFF_STATUSES } from '../../../utils/constants'
import { Table, Selector, Message } from '../../Shared'
import { fechaLarga, yearRange } from '../../../utils/FormatoDeFecha'
import { getCutOffs, endCutOff } from './Services'
import { PeopleOutline, List, Cancel, PaidOutlined, QrCode, Visibility } from '@mui/icons-material'
import SnackBarAlert from '../../Shared/SnackBarAlert'
import ShowGiveCutOff from './ShowGiveCutOff'
import SkeletonLoad from '../../Shared/SkeletonLoad'
import ShowHistory from './ShowHistory'
import ExpenseForm from './ExpenseForm'
import ShowFlow from './ShowFlow'
import MainContainer from '../../Shared/MainContainer'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const current = new Date()
const YEARS = yearRange(2022, current.getFullYear())
const token = sessionStorage.getItem('token')
const host = sessionStorage.getItem('localhost')
const user = sessionStorage.getItem('user')

export default function ListCutOffs() {
const isAdmin=JSON.parse(user).role_id==1?true:false;
  const envConfig = {
    url: process.env.REACT_APP_ENVIROMENT === 'prod' ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
  }
  const [cutOffs, setCutOffs] = useState()
  const currentCutOff = useRef({})
  const navigate = useNavigate()

  // Behaviour values
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState({id:0});
  const [ticket, setTicket] = useState(0);
  const [companies, setCompanies] = useState([{ A: 'a' }])
  const [companieId, setCompanieId] = useState({ companieId: 0 })
  const companieOptions = (companiesArray) => {
    return companiesArray.map((companie) => {
      return {
        label: companie.nombre_comercial,
        value: companie.id,
      };
    });
  };

  // Filter values
  const [month, setMonth] = useState(current.toDateString().split(' ')[1])
  const [year, setYear] = useState(current.getFullYear())

  // Modals display state
  const [showFlow, setShowFlow] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [showGiveModal, setShowGiveModal] = useState(false)
  const [showExpenseForm, setShowExpenseForm] = useState(false)

  useEffect(() => {
    if (month||year||users) {
      handleGetCutOffs();
    }
  }, [selectedUser, month, year]);
  useEffect(() => {
    getUsers();
    getCompanies()
  }, []);
  async function handleGetCutOffs() {
    try {
      setLoading(true);
      const { cutOffs, error } = await getCutOffs({
        host,
        token,
        month,
        year,
        userId: selectedUser,
      });
  
      if (error) {
        setErrorMessage('Ocurrió un error al recuperar sus cortes, intente de nuevo en unos momentos.');
        setShowError(true);
      } else {
        setCutOffs(cutOffs);
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al recuperar sus cortes, intente de nuevo en unos momentos.');
      setShowError(true);
    } finally {
      setLoading(false);
    }
  }
  async function handleCloseCutOff(id) {
    try {
      const {ticket, error } = await endCutOff({ host, token, id });
        if (error) {
        setErrorMessage('No se pudo cerrar el corte, inténtelo más tarde.')
        setShowError(true)
      } else{
        handleGetCutOffs()
      }
    } catch (error) {
      setErrorMessage('No se pudo cerrar el corte, inténtelo más tarde.')
      setShowError(true)
    }
  }
  async function getUsers() {
    try {
      const response = await fetch(`${envConfig.url}/api/v2/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        let filteredUsers = data.users;
        if (companieId.companieId && companieId.companieId !== 0) {
          filteredUsers = data.users.filter(user => user.empresa_id === companieId.companieId);
        }
        filteredUsers.unshift({ id: 0, name: 'Todos' });
        setSelectedUser({id:0})
        setUsers(filteredUsers);
      } else {
        console.error("Error en la respuesta", response);
        setUsers(null);
      }
    } catch (error) {

      console.error("Error en la solicitud", error);
      setUsers(null);
    }
  }
  async function getCompanies() {
    try {
      const response = await fetch(`${envConfig.url}/api/v2/companies`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        data.companies.unshift({ id: 0, nombre_comercial: 'Todos' });
        setCompanies(data.companies);
      } else {
        console.error("Error en la respuesta", response);
        setCompanies(null);
      }
    } catch (error) {

      console.error("Error en la solicitud", error);
      setCompanies(null);
    }
  }
  const CutOffRow = useCallback(({ item }) => {
    const { total, status, created_at, id,user_name,ticket } = item
    const date = fechaLarga(new Date(created_at))
    return (
      <TableRow>
        <TableCell>{date}</TableCell>
        {isAdmin && <TableCell>{user_name}</TableCell>}
        <TableCell>$ {total}</TableCell>
        <TableCell>{status}</TableCell>
        <TableCell width={150}>
          <Stack
            direction='row'
            spacing={2}
            justifyContent='flex-end'
          >
            <IconButton
              title='Listar tickets'
              onClick={() => navigate('/cut-offs/items', { state: { cutOffId: id } })}
            >
              <List sx={{ color: '#ff4500' }}/>
            </IconButton>
            <IconButton
              title='Ver resumen'
              onClick={() => {
                currentCutOff.current = { cutOffId: id, status }
                setShowFlow(true)
              }}
            >
              <Visibility sx={{ color: '#1976d2' }}/>
            </IconButton>
            {user?.role_id == 1 && (
              <IconButton
                title='Historial de usuarios'
                onClick={() => {
                  currentCutOff.current = { cutOffId: id, status }
                  setShowHistory(true)
                }}
              >
                <PeopleOutline sx={{ color: '#9c27b0' }} />
              </IconButton>
            )}
            {status == CUT_OFF_STATUSES.ENDED && (
              <IconButton
                title='Entregar corte'
                onClick={() => {
                  {ticket!=null?setTicket(ticket):currentCutOff.current = { cutOffId: id, status }}
                  currentCutOff.current = { cutOffId: id }
                  setShowGiveModal(true);
                }}
              >
                <QrCode sx={{ color: '#2e7d32' }} />
              </IconButton>            
            )}
            {status == CUT_OFF_STATUSES.STARTED && (
              <IconButton
                title='Cerrar corte'
                onClick={() => handleCloseCutOff(id)}
              >
                <Cancel sx={{ color: '#d32f2f' }} />
              </IconButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    )
  }, [])
  function userOptions(users) {
    return users.map(user => ({
        label: user.name,
        value: user.id
    }));
}
  return (
    <MainContainer>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack
          direction='row'
          spacing={1}
        >
         {isAdmin && users && (<>
        <Autocomplete
          disablePortal
          id="companie-autocomplete"
          options={companieOptions(companies)}
          sx={{ width: 300 }}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => {
            setCompanieId({ companieId: newValue ? newValue.value : 0});
          }}
          renderInput={(params) => <TextField {...params} label="Compañía" />}
          isOptionEqualToValue={(option, value) => option.value === value}
          value={companieOptions(companies).find(c => c.value === companieId.companieId) || null}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={userOptions(users)}
          sx={{ width: 300 }}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => {
            setSelectedUser(newValue ? newValue.value : 0);
          }}
          renderInput={(params) => <TextField {...params} label="Usuario" />}
          isOptionEqualToValue={(option, value) => option.value === value}
          value={userOptions(users).find(u => u.value === selectedUser) || null}
        />
         
         </>

      )}
      
          <Selector
            state={[month, setMonth]}
            label='Mes'
            options={MONTHS_OBJ}
            isObject
          />
          <Selector
            state={[year, setYear]}
            label='Año'
            options={YEARS}
          />
        </Stack>
      </Stack>
      {loading && (
        <SkeletonLoad
          types={['rectangular', 'rounded']}
          numberOfItems={5}
          randomHeight
          heightRange={[50, 120]}
        />
      )}

      {!loading && (cutOffs === undefined || cutOffs?.length == 0) && (
        <Message
          title='Sin cortes'
          message='Para poder visualizar los cortes realiza alguna compra de producto o guía.'
          icon={<PaidOutlined sx={{ fontSize: 50 }} />}
        />
      )}

      {!loading && cutOffs?.length > 0 && (  
        <Table
          headers={isAdmin==true?['Fecha','Usuario', 'Monto','Status', 'Acciones']:['Fecha', 'Monto','Status', 'Acciones']}
          data={cutOffs}
          pagination
          RowComponent={CutOffRow}
        />
      )}

      <ShowFlow
        displayState={[showFlow, setShowFlow]}
        cutOff={currentCutOff.current}
        setShowExpense={setShowExpenseForm}
      />
      <ShowGiveCutOff
        displayState={[showGiveModal, setShowGiveModal]}
        cutOff={currentCutOff.current}
        ticket={ticket}
      />
      <ShowHistory
        displayState={[showHistory, setShowHistory]}
        cutOff={currentCutOff.current}
      />
      <ExpenseForm
        displayState={[showExpenseForm, setShowExpenseForm]}
        callback={handleGetCutOffs}
        cutOff={currentCutOff.current}
      />

      <SnackBarAlert
        displayState={[showError, setShowError]}
        severity='error'
        message={errorMessage}
      />
    </MainContainer>
  )
}
