const TOKEN = sessionStorage.getItem('token')
const HOST = sessionStorage.getItem('localhost')

/**
 *
 * @param {string} [path = '/' ]
 * @param {object} [params = {}]
 * @returns
 */

export async function get(path = '/', params = {}) {
  const url = new URL(HOST)
  url.pathname = path
  if (Object.keys(params)) {
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, String(value))
    }
  }

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'application/json'
    }
  })

  return response
}

/**
 * @param {object} body
 * @param {string} [path = '/' ]
 * @param {object} [params = {}]
 * @param {('POST'|'PATCH')} method
 * @returns
 */

export async function post(body, path = '/', method = 'POST', params = {}) {
  const url = new URL(HOST)
  url.pathname = path
  if (Object.keys(params)) {
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, String(value))
    }
  }

  const response = await fetch(url.toString(), {
    method,
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  return response
}
