import React , { useState , useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

import {
    Grid,
    Button,
    Box,
    Typography,
    // TextField,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    InputAdornment
} from '@mui/material';
import {
    // Scale,
    DeliveryDining
} from '@mui/icons-material';

import '../shippingStyles.css';
import FormModel from './FormModel';
import WhoIsWillPay from './WhoIsWillPay';
import { objectJSON } from './FormModel';

export default function GridListOriginOrDestination(props){
    const {
            arrayObjects,
            arrayButtonsCSS,
            arrayIcons
        } = props;
    const [ open , setOpen ] = useState(false);
    const [ place , setPlace ] = useState('');
    const [ serviceID , setServiceID ] = useState(0);
    const [ services , setServices ] = useState([]);
    const [ kilos , setKilos ] = useState('');

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==='prod') ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '80%',
        overflowY: 'scroll',
        bgcolor: 'background.paper',
        border: '2px solid #efeff0',
        borderRadius: '6px',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3
    };

    const textFieldCSS = {
        backgroundColor : '#ffffff',
        borderTopLeftRadius : '5px',
        borderTopRightRadius : '5px',
    };

    const handleOpen = () => {
        setOpen( true );
    }

    const handleClose = () => {
        setOpen( false );
    }

    const catchService = ( event ) => {
        setServiceID( event.target.value );
        objectJSON.data.kilosServiceAndNumber.tipo_servicio_id = Number( event.target.value );
    }

    // const catchKilos = ( event ) => {
    //     if( isNaN( event.target.value ) ||
    //         event.target.value === '' ){
    //         setKilos("1.00");
    //         objectJSON.data.kilosServiceAndNumber.kg = 1.0;
    //         return;
    //     }
    //     if( Number( event.target.value ) < 0 ){
    //         setKilos("1.00");
    //         objectJSON.data.kilosServiceAndNumber.kg = 1.0;
    //         return;
    //     }

    //     setKilos( event.target.value );
    //     objectJSON.data.kilosServiceAndNumber.kg = parseFloat( event.target.value );
    // }

    // function goTo(){
    //     if( objectJSON.data.origin.origenPersona.nombre            !== '' &&
    //         objectJSON.data.origin.origenPersona.apellidoPaterno   !== '' &&
    //         objectJSON.data.origin.origenCalle                     !== '' &&
    //         objectJSON.data.origin.origenNumExt                    !== '' &&
    //         objectJSON.data.origin.origenColonia                   !== '' &&
    //         objectJSON.data.origin.origenMunicipio                 !== '' &&
    //         objectJSON.data.origin.origenEstado                    !== '' &&
    //         objectJSON.data.origin.origenPais                      !== '' &&
    //         objectJSON.data.origin.origenCP                        !== '' &&
    //         objectJSON.data.origin.origenTelefono1                 !== '' &&
    //         objectJSON.data.destination.destinoPersona.nombre      !== '' &&
    //         objectJSON.data.destination.destinoPersona.apellidoPaterno !== '' &&
    //         objectJSON.data.destination.destinoCalle               !== '' &&
    //         objectJSON.data.destination.destinoNumExt              !== '' &&
    //         objectJSON.data.destination.destinoColonia             !== '' &&
    //         objectJSON.data.destination.destinoMunicipio           !== '' &&
    //         objectJSON.data.destination.destinoEstado              !== '' &&
    //         objectJSON.data.destination.destinoPais                !== '' &&
    //         objectJSON.data.destination.destinoCP                  !== '' &&
    //         objectJSON.data.destination.destinoTelefono1           !== '' &&
    //         objectJSON.data.kilosServiceAndNumber.tipo_servicio_id !== 0  &&
    //         objectJSON.data.kilosServiceAndNumber.kg !== 0.0){
            
    //         if( objectJSON.data.kilosServiceAndNumber.kg <= 80.0 ){
    //             setLoad( !load );
    //             navigate( objectJSON.data.isQuote ? '/paymentConfirmation' : '/methodsOfPayment' );
    //             return;
    //         }

    //         alert( 'El maximo de Kg amparados es de: 80.00' );
    //         return;
    //     }

    //     alert( 'Asegure en llenar todos los campos requeridos.' );
    // }

    function showData(){
        setKilos( parseFloat(objectJSON.data.kilosServiceAndNumber.kg) );
        setServiceID( objectJSON.data.kilosServiceAndNumber.tipo_servicio_id );
    }

    const getServiceTypes = async () => {
        await fetch(`${envConfig.url}/api/v2/services`, {
            method : 'GET',
            headers : {
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Cache-Control': 'no-cache'
            }
        })
            .then( (res) => {
                if(!res.ok){
                    alert( 'Algo ocurrio : ' + res.status );
                }

                return res.json();
            })
            .then( data => setServices( data.data ) )
            .catch( error => alert( 'Algo ocurrio :C ' + error ) );
    }

    // useEffect( () => { showData(); } , [ load ] );
    useEffect( () => { showData(); } , [] );
    useEffect( () => { getServiceTypes(); } , [] );

    const ListButtons = arrayObjects.map(
        ( item , index ) => {
            return(
                <Grid item xs={12} sm={6} md={2}>
                    <Button sx = { 
                        arrayButtonsCSS[ index ] }
                        onClick = { () => { 
                                        handleOpen();
                                        setPlace( item.option );
                                    } }
                        variant = "contained"
                        fullWidth={true}
                    >
                        { arrayIcons[ index ] }
                        { item.option }
                    </Button>
                </Grid>
            );
        }
    );

    const servicesOptions = services.map( ( item , index ) => (
        <MenuItem value={ item.id } > { item.name } </MenuItem>
    ) );

    return(
        <Box>
            {/* Campo que visualizara el servicio que se va a manejar */}
            <Grid container spacing = { 1 } alignItems = "center" marginBottom={3}>
                <Grid item xs={12} md={3.5}>
                    <Typography>
                        El servicio que se llevara a cabo sera:  
                    </Typography>
                </Grid>

                {/* <Grid item xs = {12} sm={3} md={3}>
                    <TextField 
                        id = "filled-number" 
                        label = "Kilos" 
                        disabled 
                        InputLabelProps = {{ shrink: true, }} 
                        variant = "outlined" 
                        sx = { textFieldCSS } 
                        onChange = { catchKilos } 
                        value = { kilos } 
                        fullWidth={true}
                        InputProps = {{
                            startAdornment : (
                                <InputAdornment position = "start">
                                    <Scale fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid> */}

                <Grid item xs = {12} md={5.5}>
                    <FormControl variant="outlined" sx = { textFieldCSS } fullWidth>
                        <InputLabel 
                            id="demo-simple-select-filled-label" 
                            disabled
                        >
                            Servicio
                        </InputLabel>
                        <Select 
                            labelId="demo-simple-select-filled-label" 
                            disabled 
                            id="demo-simple-select-filled" 
                            value={ serviceID } 
                            label="Servicio" 
                            onChange={ catchService }
                            startAdornment = {
                                <InputAdornment position = "start">
                                    <DeliveryDining fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            }
                        >
                            { servicesOptions }
                        </Select>
                    </FormControl>
                </Grid>

                {/* <Grid item>
                    <Button variant = "contained" onClick = { goTo }>
                        Siguiente
                    </Button>
                </Grid> */}
            </Grid>

            {/* Botones de selección de direcciones */}
            <Grid container spacing = { 1 } marginBottom={4}>
                <Grid item xs={12}>
                    <Typography>
                        Introduzca las direcciones de origen y destino
                    </Typography>
                </Grid>
                
                { ListButtons }

            </Grid>

            {/* ¿Quien pagara el envio? */}
            <WhoIsWillPay />

            {/* Formulario de peso y servicio
            <Grid container spacing = { 1 }>
                <Grid item xs={12}>
                    <Typography>
                        Introduzca el peso y el tipo de servicio
                    </Typography>
                </Grid>

                <Grid item xs = {12} sm={3} md={3}>
                    <TextField 
                        id = "filled-number" 
                        label = "Kilos" 
                        disabled 
                        InputLabelProps = {{ shrink: true, }} 
                        variant = "outlined" 
                        sx = { textFieldCSS } 
                        onChange = { catchKilos } 
                        value = { kilos } 
                        fullWidth={true}
                        InputProps = {{
                            startAdornment : (
                                <InputAdornment position = "start">
                                    <Scale fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs = {12} sm={9} md={6}>
                    <FormControl variant="outlined" sx = { textFieldCSS } fullWidth>
                        <InputLabel 
                            id="demo-simple-select-filled-label" 
                            disabled
                        >
                            Servicio
                        </InputLabel>
                        <Select 
                            labelId="demo-simple-select-filled-label" 
                            disabled 
                            id="demo-simple-select-filled" 
                            value={ serviceID } 
                            label="Servicio" 
                            onChange={ catchService }
                            startAdornment = {
                                <InputAdornment position = "start">
                                    <DeliveryDining fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            }
                        >
                            { servicesOptions }
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item>
                    <Button variant = "contained" onClick = { goTo }>
                        Siguiente
                    </Button>
                </Grid>
            </Grid> */}

            {/* Ventana modal para el formulario de direcciones */}
            <FormModel 
                open = { open }
                handleClose = { handleClose }
                style = { style }
                isOriginOrDestination = { place }
            />
        </Box>
    );
}


/*


            <div className = { divGridClasses }>
                <Grid container spacing = { arrayObjects.length + 2 }>

                    

                    <Grid item xs = { 12 } sx = {{ marginLeft : "-12px" }}>
                        <Box sx = {{ minWidth : 275 }}>
                            <Card variant = "outlined" sx = {{ backgroundColor : '#9f02aa', border : '2px solid #5b5b5b', height : 93, width : 345 }}>
                                <CardContent>
                                    <Grid container spacing = { 1 }>

                                        <Grid item xs = { 6 }>
                                            <TextField id = "filled-number" label = "Kilos" InputLabelProps = {{ shrink: true, }} variant = "filled" sx = { textFieldCSS } onChange = { catchKilos } value = { kilos }/>
                                        </Grid>

                                        <Grid item xs = { 6 }>
                                            <FormControl variant="filled" sx = { textFieldCSS } fullWidth>
                                                <InputLabel id="demo-simple-select-filled-label">Servicio</InputLabel>
                                                <Select labelId="demo-simple-select-filled-label" id="demo-simple-select-filled" value={ serviceID } label="Servicio" onChange={ catchService }>
                                                    { servicesOptions }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs = { 12 }
                    >
                        <div 
                            align = "right"
                        >
                            
                        </div>
                    </Grid>

                </Grid>
            </div>

            <FormModel 
                open = { open }
                handleClose = { handleClose }
                style = { style }
                isOriginOrDestination = { place }
            />

            */