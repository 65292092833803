import React from 'react';
import { useNavigate } from "react-router-dom";
import {LocalShipping,
        AccessTime,
        LocalAtm} from '@mui/icons-material';
import {Card,
        CardContent,
        CardHeader,
        Grid,
        Button,
        Avatar} from '@mui/material';

import '../shippingStyles.css';

export default function CardService(props){
    const {
        url,
        service
    } = props;

    const navigate = useNavigate();
    const cardCSS = {
        card :{ 
            packageSupplier : {
                backgroundColor : '#e80b0b',
                color: '#ffffff',
                border : '2px solid #fa4444',
            },
            deliveryTime : {
                backgroundColor : '#0754c9',
                color: '#ffffff',
                border : '2px solid #5696f7',
            },
            howMuchCost : {
                backgroundColor : '#028437',
                color: '#ffffff',
                border : '2px solid #1ccd65',
            }
        },
        avatar : { 
            backgroundColor : 'transparent',
            border: 'none',
            marginBottom: '-7px',
            marginTop: '-7px',
            marginLeft: '-30px',
        },
        cardHeader : {
            packageSupplier : {
                backgroundColor : '#fa4444',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '30px',
                paddingRight: '30px'
            },
            deliveryTime : {
                backgroundColor : '#5696f7',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '30px',
                paddingRight: '30px'
            },
            howMuchCost : {
                backgroundColor : '#1ccd65',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '30px',
                paddingRight: '30px'
            } 
        },
        cardContent : {
            marginTop : '-14px',
            marginBottom : '-24px'
        }
    };

    function next(){
        navigate( url );
    }

    return(
        <Button
            sx = {{textTransform : 'none'}}
            onClick = { next }
        >
            <Card
                sx={{ 
                    minWidth: 275,
                    paddingLeft : '35px',
                    paddingRight : '35px',
                }}>
                <CardContent>
                    <Grid 
                        container
                        spacing = { 1 }
                    >
                        <Grid
                            item
                            xs = { 4 }
                        >
                            <Card
                                sx = { cardCSS.card.packageSupplier }>
                                <CardHeader
                                    avatar = {
                                        <Avatar sx = { cardCSS.avatar }>
                                            <LocalShipping />
                                        </Avatar>
                                    }
                                    title = "Provedor"
                                    sx = { cardCSS.cardHeader.packageSupplier }
                                />
                                <CardContent
                                    sx = { cardCSS.cardContent }
                                >
                                    {service.PackageSupplier}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs = { 4 }
                        >
                            <Card
                                sx = { cardCSS.card.deliveryTime }>
                                <CardHeader
                                    avatar = {
                                        <Avatar sx = { cardCSS.avatar }>
                                            <AccessTime />
                                        </Avatar>
                                    }
                                    title = "Dias"
                                    sx = { cardCSS.cardHeader.deliveryTime }
                                />
                                <CardContent
                                    sx = { cardCSS.cardContent }
                                >
                                    {service.DeliveryTime}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs = { 4 }
                        >
                            <Card
                                sx = { cardCSS.card.howMuchCost }>
                                <CardHeader
                                    avatar = {
                                        <Avatar sx = { cardCSS.avatar }>
                                            <LocalAtm />
                                        </Avatar>
                                    }
                                    title = "Costo"
                                    sx = { cardCSS.cardHeader.howMuchCost }
                                />
                                <CardContent
                                    sx = { cardCSS.cardContent }
                                >
                                    {service.HowMuchCost}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Button>
    );
}