import React , { useState } from 'react';
import {Grid,
        Typography,
        IconButton,
        TextField,
        Container,
        Box,
        Paper,
        Button,
        CircularProgress} from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import {AttachFile,
        Download,
        Upload,
        FileOpen,
        Close,
        DoneAll,
        ThumbUp} from '@mui/icons-material';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import './shippingStyles.css';
import ButtonLink from './MarcoComponents/ButtonLink';
import guiaMasiva from '../../../../assets/XLSXTemplates/guiaMasiva.xlsx';

export default function DownloadAndUploadTemplate(props){
    /* Estados */
    const [ excelJSON , setExcelJSON ] = useState( null );
    const [ excelFileName , setExcelFileName ] = useState( 'Ejemplo.xlsx' );
    const [ disabledUploadButton , setDisabledUploadButton ] = useState( true );
    const [ disabledDownloadButton , setDisabledDownloadButton ] = useState( false );
    const [ helperMessage , setHelperMessage ] = useState( 'Seleccione un archivo excel(.xlsx)' );
    const [ loading , setLoading ] = useState(false);
    const [ icon , setIcon ] = useState( <FileOpen color = "info" fontSize = "large"/> );

    /* Cargamos nuestro objeto de usuario que se encuetra resguardado en el sessionStorage */
    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    /* Cargando URL atraves de enviroment */
    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==='prod') ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV 
    };

    /* Funcion para generar un archivo xlsx con un array JSON */
    const arrayJsonToXLSX = ( excelArray , fileName ) => {
        const workSheet = XLSX.utils.json_to_sheet( excelArray );
        const workBook = { Sheets : { data : workSheet } , SheetNames : ['data'] };
        const excelBuffer = XLSX.write( workBook , { bookType : 'xlsx' , type : 'array' } );
        const data = new Blob( [ excelBuffer ] , { type : 'application/vnd.ms-excel' } );
        FileSaver.saveAs( data , fileName );
    }

    /* Funcion para obtener la informcion de un archivo Excel */
    const handleFile = async ( event ) => {
        const excelFile = event.target.files[0];

        /* Comprobando si el archivo sea extención (.xlsx) */
        if( event.target.files[0].name.slice( -4 ) === 'xlsx' ){
            const data = await excelFile.arrayBuffer();
            const workBook = XLSX.read( data );
            const workSheet = workBook.Sheets[ workBook.SheetNames[0] ];
            const sheetToJSON = XLSX.utils.sheet_to_json( workSheet );
            sheetToJSON.forEach( object => {
                /* Agregando propiedades faltantes */
                object.numero = "";
                object.user_id = userData.id;
                object.generadoPorUsuario = 1;

                if( object.status !== undefined ){ delete object.status }
                object.id_dom_origen = null;
                object.id_dom_destino = null;
            } );
            sheetToJSON.forEach( object => {
                Object.keys( object ).forEach( keyName => {
                    if( keyName.slice( -1 ) === '*' ){
                        object[ keyName.slice( 0 , keyName.length - 1 ) ] = object[ keyName ];
                        delete object[ keyName ];
                    }
                } );
            } );
            setExcelFileName( event.target.files[0].name );
            setExcelJSON( sheetToJSON );
            //console.log( sheetToJSON );
            setIcon( <ThumbUp color = "success" fontSize = "large" /> );
            setHelperMessage( 'Listo' );
            setDisabledUploadButton( false );
            return;
        }

        /* En caso contrario */
        setIcon( <Close color= "error" fontSize = "large" /> );
        setHelperMessage( 'Asegure que el archivo sea (.xlsx)' );
        setExcelFileName( 'Ejemplo.xlsx' );
        setDisabledUploadButton( true );
        setExcelJSON( null );
    }

    /* Funcion para realizar una peticion POST, con el fin de descargar una guia masiva */
    const sendExcelDataAndDownloadWaybillMassive = async () => {
        setDisabledUploadButton( true );
        setLoading( true );
        setHelperMessage( 'Enviando informacion, espere un momento' );


        await fetch(`${envConfig.url}/api/v2/waybills`, {
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Cache-Control': 'no-cache'
            },
            body : JSON.stringify({
                waybills : excelJSON,
                isThereWaybillMassive : true
            })
        } )
            .then( res => { 
                if(!res.ok){
                    return res.json();
                }

                return res.blob();
            } )
            .then( jsonOrBlob => {
                if( jsonOrBlob.data !== undefined ){
                    setIcon( <Close color = "error" fontSize = "large" /> );
                    setHelperMessage( 'Hubo ciertos detalles en el template(.xlsx)' );
                    arrayJsonToXLSX( jsonOrBlob.data.waybills , excelFileName );
                }else{
                    setIcon( <DoneAll color = "success" fontSize = "large" /> );
                    setHelperMessage( 'Se ha generado sin problemas la guia masiva' );
                    const download = require('downloadjs');
                    download( jsonOrBlob , 'GuiaMasiva.pdf' , 'application/pdf' );
                }
            } )
            .catch( error => alert( 'Algo ocurrio :C ' + error ) );
        setLoading( false );
        setDisabledUploadButton( false );
    }

    /* Funcion para descargar el template de guia masiva, junto con un manual en pdf */
    const downloadWaybillMassiveTemplate = async () => {
        setDisabledDownloadButton( true );
        await fetch( guiaMasiva , {
            method : 'GET',
            mode : 'no-cors',
            referrerPolicy : 'no-referrer'
        } )
            .then( res => res.blob() )
            .then( blob => {
                const download = require('downloadjs');
                download( blob , 'guiaMasiva.xlsx' , 'application/vnd.ms-excel' );
            } )
            .catch( error => alert( 'Algo ocurrio :C ' + error ) );
        setDisabledDownloadButton( false );
    }

    /* Retornando interfaz */
    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ marginY: 5 , paddingX: 5 , paddingY: 5 }}>

                {/* Encabezado de la página */}
                <Container sx={{ marginBottom: 2 , textAlign: "left" }}>
                    <Grid container spacing={2}>

                        {/* Titulo */}
                        <Grid item xs={12} md={9}>
                            <Typography variant='h5'>
                                Guia masiva
                            </Typography>
                        </Grid>

                        {/* Boton */}
                        <Grid item xs={12} md={3}>
                            <div align="center">
                                <ButtonLink 
                                    url = "/waybillsTable"
                                    clases = ""
                                    texto = "Regresar"
                                />
                            </div>
                        </Grid>
                            
                    </Grid>
                </Container>

                <Container sx={{ textAlign: "left" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <div 
                                style = {{
                                    display : "flex",
                                    alignItems : "center",
                                    justifyContent : "left",
                                    gap : "3px"
                                }}
                            >
                                <IconButton
                                    sx = {{
                                        backgroundColor : "#03a7c1",
                                        color : "#ffffff",
                                        width : "43px",
                                        height : "43px",
                                        borderRadius : 2,
                                        border : "2px solid #03a7c1",
                                        "&:hover" : {
                                            backgroundColor : "#028ba1",
                                            color : "#dcdcdc",
                                            border : "2px solid #028ba1"
                                        }
                                    }}
                                    title = "Descargar template de guia masiva"
                                    onClick = { downloadWaybillMassiveTemplate }
                                    disabled = { disabledDownloadButton }
                                >
                                    <Download />
                                </IconButton>

                                <label
                                    for = "inputExcel"
                                    className = "labelExcel"
                                >
                                    <AttachFile titleAccess = "Cargar un template de Excel"/>
                                    <input
                                        id = "inputExcel"
                                        type="file"
                                        onChange = { handleFile }
                                        style = {{
                                            display : "none"
                                        }}
                                    />
                                </label>

                                <TextField
                                    // error = { excelError }
                                    id = "outlined-basic"
                                    variant = "outlined"
                                    value = { excelFileName }
                                    disabled
                                    inputProps = {{
                                        style : {
                                            fontSize : "14px",
                                            width : "273px",
                                            backgroundColor : "#ffffff",
                                            borderRadius : "4px"
                                        }
                                    
                                    }}
                                    size = "small"
                                    fullWidth={true}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <div
                                style={{
                                    marginTop:"3px"
                                }}
                            >        
                                <Button
                                    sx = {{
                                        backgroundColor : "#801dd7",
                                        color : "#ffffff",
                                        textTransform: "none",
                                        "&:disabled" : {
                                            backgroundColor : "#ebebe4",
                                            color: "#d3d3d3"
                                        },
                                        "&:hover" : {
                                            backgroundColor : "#610bad",
                                            color : "#dcdcdc",
                                        },
                                    }}
                                    onClick = { sendExcelDataAndDownloadWaybillMassive }
                                    disabled = { disabledUploadButton }
                                >
                                    Subir template
                                    <Upload />
                                </Button>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            {
                                ( loading ) ? <>
                                    <div 
                                        style={{
                                            display : "flex",
                                            alignItems : "center",
                                            gap: "10px",
                                            marginTop:"3px"
                                        }}
                                    >
                                        <Box>
                                            <CircularProgress
                                                variant = "indeterminate"
                                                disableShrink
                                                size = {40}
                                                sx = {{
                                                    animationDuration : "550ms",
                                                    [`& .${circularProgressClasses.circle}`] : {
                                                        strokeLinecap : 'round'
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Typography 
                                            variant='body2' 
                                            sx={{ 
                                                fontSize: "13px" ,
                                                fontWeight : "700" 
                                            }}
                                        >
                                            {helperMessage}
                                        </Typography>
                                    </div>
                                </> : <>
                                    <div 
                                        style={{
                                            display : "flex",
                                            alignItems : "center",
                                            gap: "10px",
                                            marginTop:"3px"
                                        }}
                                    >
                                        {icon}
                                        <Typography 
                                            variant='body2' 
                                            sx={{ 
                                                fontSize: "13px" ,
                                                fontWeight : "700" 
                                            }}
                                        >
                                            {helperMessage}
                                        </Typography>
                                    </div>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </Container>
    );
}