import React , { useState } from "react";

import {
    IconButton,
    Card,
    CardContent,
    Collapse,
    Typography,
    Grid,
    Box,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment
} from "@mui/material";

import { MdDelete } from 'react-icons/md';
import { BsFillFileTextFill } from 'react-icons/bs';
import { FaFileInvoice } from 'react-icons/fa';
import { TfiRuler } from "react-icons/tfi";
import { 
    GiCardboardBoxClosed,
    GiKitchenScale
} from 'react-icons/gi';
import { 
    TiArrowSortedDown,
    TiArrowSortedUp
} from 'react-icons/ti';
import { 
    TbArrowAutofitWidth,
    TbArrowAutofitHeight    
} from "react-icons/tb";

export default function WaybillCard ( props ) {
    const {
        waybill,
        removeItem,
    } = props;

    /* Estado para abrir y cerrar el contenido de la tarjeta de la guia */
    const [ expanding , setExpanding ] = useState( false );

    /* Función para abrir y cerrar el contenido de la tarjeta de la guia */
    const showingPackageDimensions = () => setExpanding( !expanding );

    return(
        <Card 
            key = { waybill.numero }
            variant = "outlined"
            sx = {{ width : "100%" }} 
        >
            <Box>
                <Grid container spacing = {1}>
                    <Grid item xs = {9}>
                        <div
                            style = {{
                                display : "flex",
                                alignItems : "center",
                                justifyContent : "start",
                                gap : "3px",
                                marginTop : "5px",
                                marginLeft : "7px"
                            }}
                        >
                            <FaFileInvoice 
                                color = "#ff4500"
                                size = "1.80rem"
                            />

                            <Typography 
                                sx = {{ 
                                    fontSize : "14.7px",
                                    fontWeight : "700"
                                }}
                            >
                                {waybill.numero}
                            </Typography> 
                        </div>
                    </Grid>

                    <Grid item xs = {3}>
                        <div
                            style = {{
                                display : "flex",
                                alignItems : "center",
                                justifyContent : "end",
                                gap : "2.5px"
                            }}
                        >
                            <IconButton
                                onClick = { removeItem }
                            >
                                <MdDelete
                                    color = "#e10000" 
                                    size = "1.45rem"
                                />
                            </IconButton>

                            <IconButton
                                onClick = { showingPackageDimensions }
                            >
                                {
                                    expanding ? 
                                        <TiArrowSortedDown 
                                            color = "#ff4500"
                                            size = "1.45rem"
                                        />
                                    :
                                        <TiArrowSortedUp 
                                            color = "#ff4500"
                                            size = "1.45rem"
                                        />
                                }
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
            </Box>

            <Collapse
                in = { expanding }
                timeout = "auto"
                unmountOnExit
            >
                <CardContent>
                    <div 
                        style={{ 
                            border : "2px solid #ff4500",
                            borderRadius : "5px" 
                        }}
                    >
                        <Grid container spacing = { 2 }>
                            <Grid 
                                item 
                                xs = { 12 } 
                                md = { 3 }
                                display = "flex"
                                alignItems = "center"
                                justifyContent = "center"
                            >
                                <GiCardboardBoxClosed 
                                    color = "#ff4500"
                                    fontWeight = "700"
                                    size = "9rem"
                                />
                            </Grid>

                            <Grid item xs = { 12 } md = { 9 }>
                                <Grid container spacing = { 1 } padding = { 1 }>
                                    <Grid item xs = { 12 }>
                                        <Typography
                                            sx = {{
                                                fontSize : "14px",
                                                fontWeight : "700"
                                            }}
                                        >
                                            Dimensiones del paquete.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs = { 6 }>
                                        <FormControl
                                            variant = "outlined"
                                            fullWidth = { true }
                                        >
                                            <InputLabel
                                                htmlFor = "KG"
                                            >
                                                KG
                                            </InputLabel>

                                            <OutlinedInput
                                                disabled
                                                id = "KG"
                                                label = "KG"
                                                value = { parseFloat( waybill.kg ).toFixed( 2 ) }
                                                inputProps = {{
                                                    style : {
                                                        fontSize : "14px"
                                                    }
                                                }}
                                                startAdornment = {
                                                    <InputAdornment position="start">
                                                        <GiKitchenScale 
                                                            color = "#ff4500" 
                                                            fontWeight = "700"
                                                            size = "1.21rem"
                                                        />
                                                    </InputAdornment>
                                                }
                                                sx = {{
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000"
                                                    }
                                                }}
                                                size = "small"
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs = { 6 }>
                                        <FormControl
                                            variant = "outlined"
                                            fullWidth = { true }
                                        >
                                            <InputLabel
                                                htmlFor = "Ancho"
                                            >
                                                Ancho
                                            </InputLabel>

                                            <OutlinedInput
                                                disabled
                                                id = "Ancho"
                                                label = "Ancho"
                                                value = { parseFloat( waybill.ancho ).toFixed( 2 ) }
                                                inputProps = {{
                                                    style : {
                                                        fontSize : "14px"
                                                    }
                                                }}
                                                startAdornment = {
                                                    <InputAdornment position="start">
                                                        <TbArrowAutofitWidth 
                                                            color = "#ff4500" 
                                                            fontWeight = "700"
                                                            size = "1.21rem"
                                                        />
                                                    </InputAdornment>
                                                }
                                                sx = {{
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000"
                                                    }
                                                }}
                                                size = "small"
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs = { 6 }>
                                        <FormControl
                                            variant = "outlined"
                                            fullWidth = { true }
                                        >
                                            <InputLabel
                                                htmlFor = "Altura"
                                            >
                                                Altura
                                            </InputLabel>

                                            <OutlinedInput
                                                disabled
                                                id = "Altura"
                                                label = "Altura"
                                                value = { parseFloat( waybill.alto ).toFixed( 2 ) }
                                                inputProps = {{
                                                    style : {
                                                        fontSize : "14px"
                                                    }
                                                }}
                                                startAdornment = {
                                                    <InputAdornment position="start">
                                                        <TbArrowAutofitHeight 
                                                            color = "#ff4500" 
                                                            fontWeight = "700"
                                                            size = "1.21rem"
                                                        />
                                                    </InputAdornment>
                                                }
                                                sx = {{
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000"
                                                    }
                                                }}
                                                size = "small"
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs = { 6 }>
                                        <FormControl
                                            variant = "outlined"
                                            fullWidth = { true }
                                        >
                                            <InputLabel
                                                htmlFor = "Largo"
                                            >
                                                Largo
                                            </InputLabel>

                                            <OutlinedInput
                                                disabled
                                                id = "Largo"
                                                label = "Largo"
                                                value = { parseFloat( waybill.largo ).toFixed( 2 ) }
                                                inputProps = {{
                                                    style : {
                                                        fontSize : "14px"
                                                    }
                                                }}
                                                startAdornment = {
                                                    <InputAdornment position="start">
                                                        <TfiRuler 
                                                            color = "#ff4500" 
                                                            fontWeight = "700"
                                                            size = "1.21rem"
                                                        />
                                                    </InputAdornment>
                                                }
                                                sx = {{
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000"
                                                    }
                                                }}
                                                size = "small"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {
                                    ( waybill.descripcion !== "" ) ?
                                        <Grid container spacing = { 1 } padding = { 1 }>
                                            <Grid item xs = { 12 }>
                                                <Typography
                                                    sx = {{
                                                        fontSize : "14px",
                                                        fontWeight : "700" 
                                                    }}
                                                >
                                                    Descripción.
                                                </Typography>
                                            </Grid>
                                                            
                                            <Grid item xs = { 12 }>
                                                <FormControl
                                                    variant = "outlined"
                                                    fullWidth = { true }
                                                >
                                                    <InputLabel 
                                                        htmlFor = "Descripcion"
                                                    >
                                                        Descripción
                                                    </InputLabel>
                                                
                                                    <OutlinedInput
                                                        disabled 
                                                        id = "Descripcion"
                                                        label = "Descripción"
                                                        multiline
                                                        minRows = { 3 }
                                                        maxRows = { 3 }
                                                        value = { waybill.descripcion }
                                                        inputProps = {{
                                                            style : {
                                                                fontSize : "14px"
                                                            }
                                                        }}
                                                        startAdornment = {
                                                            <InputAdornment position = "start">
                                                                <BsFillFileTextFill 
                                                                    color = "#ff4500" 
                                                                    fontWeight = "700"
                                                                    size = "1.21rem"
                                                                />
                                                            </InputAdornment>
                                                        }
                                                        sx = {{
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "#000000"
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    :   <></>
                                } 
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Collapse>
        </Card>
    );
}