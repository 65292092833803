import { ShoppingCartOutlined, Person, Numbers } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import PaymentMethods from './PaymentMethods'
import GeneratingWaybills from './GeneratingWaybills'
import AddItem from './AddItem'
import ShoppingCartContext from './ShoppingCartContext'
import CartDetails from './CartDetails'
import BuyResume from './BuyResume'
import CostumerInfo from './CostumerInfo'

export default function Session() {
  const { carts, removeCart, actualCart } = useContext(ShoppingCartContext)
  const cart = carts[actualCart]
  const hasPayment = cart?.payment
  const hasResponse = cart?.response

  const [actualStep, setActualStep] = useState(0)
  const [total, setTotal] = useState(0)
  const [balance, setBalance] = useState(undefined)

  const [showAddItem, setShowAddItem] = useState(false)
  const [showSessionDelete, setShowSessionDelete] = useState(false)
  const [showSessionEnd, setShowSessionEnd] = useState(false)

  const userPermissions = JSON.parse(sessionStorage.getItem('permissions'))

  const [paymentPDV, setPaymentPDV] = useState('');


  useEffect(() => {
    if (hasResponse !== undefined) {
      setActualStep(3)
      return
    }

    if (hasPayment !== undefined) {
      setActualStep(2)
      return
    }
  }, [])

  useEffect(() => {
    const waybills = cart.waybills?cart.waybills.reduce((sum, item) => sum + Number(item.precio_final), 0):0
    const products = cart.products?cart.products.reduce((sum, item) => sum + Number(item.total), 0):0
    const deliveries = cart.deliveries?cart.deliveries.reduce((sum, item) => sum + Number(item.costoDeEnvio), 0):0
    const pendingPays = cart.pendingPays?cart.pendingPays.reduce((sum, item) => sum + Number(item.movement.monto), 0):0
    setTotal(waybills + products + deliveries + pendingPays)
  }, [cart.waybills, cart.products, cart.deliveries, cart.pendingPays, showAddItem])

  const EmptySession = () => (
    <Stack
      spacing={2}
      sx={{ p: 2 }}
    >
      {['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) ? <CostumerInfo name={cart?.costumer?.name} DNI={cart?.costumer?.DNI} /> : <></>}
      <Stack
        direction='row'
        spacing={2}
      >
        <ShoppingCartOutlined sx={{ fontSize: 50 }} />
        <Stack>
          <Typography variant='subtitle1'>Tu carrito está vacío</Typography>
          <Typography variant='body1'>Para empezar, agrega un item</Typography>
        </Stack>
      </Stack>

      <Stack
        direction='row'
        spacing={1}
        justifyContent='space-between'
        useFlexGap
        flexWrap='wrap'
      >
        <Button
          variant='outlined'
          onClick={() => removeCart()}
          color='error'
        >
          Eliminar carrito
        </Button>
        <Button
          variant='contained'
          onClick={() => setShowAddItem(true)}
        >
          {userPermissions['role'] === 'Cliente' || userPermissions['role'] === 'SubCliente' ? 'Agregar cotización' : 'Agregar item'}
        </Button>
      </Stack>
    </Stack>
  )

  const ActualSession = () => (
    <Stepper
      activeStep={actualStep}
      orientation='vertical'
      sx={{ p: 1 }}
      role='tabpanel'
    >
      <Step>
        <StepContainer title='Items agregados'>
          {['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) ? 
            <Stack marginTop={-1} marginBottom={2}>
              <CostumerInfo name={cart?.costumer?.name} DNI={cart?.costumer?.DNI} />
            </Stack> 
          : <></>}
          
          <CartDetails />

          {/* <Typography
            variant='subtitle1'
            sx={{ textAlign: 'right', paddingTop: 2 }}
          >
            Total: ${total.toFixed(2)}
          </Typography> */}

          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{ paddingTop: 2 }}
            useFlexGap
            flexWrap='wrap'
            spacing={1}
          >
            <Stack
              direction='row'
              spacing={2}
              useFlexGap
              flexWrap='wrap'
            >
              <Button
                variant='outlined'
                onClick={() => setShowSessionDelete(true)}
                color='error'
              >
                Eliminar carrito
              </Button>
            </Stack>
            <Stack
              direction='row'
              spacing={2}
              useFlexGap
              flexWrap='wrap'
            >
              {userPermissions['role'] !== 'SubCliente' && (
                <Typography
                    variant='subtitle1'
                    sx={{ textAlign: 'right', paddingTop: 2 }}
                  >
                    Total: ${total.toFixed(2)}
                </Typography>)
              }
            </Stack>

            <Stack
              direction='row'
              spacing={2}
              useFlexGap
              flexWrap='wrap'
            >
              <Button
                variant='outlined'
                onClick={() => setActualStep(1)}
              >
                Realizar pago
              </Button>
              <Button
                variant='contained'
                onClick={() => setShowAddItem(true)}
              >
                {userPermissions['role'] === 'Cliente' || userPermissions['role'] === 'SubCliente' ? 'Agregar cotización' : 'Agregar item'}
              </Button>
            </Stack>
          </Stack>
        </StepContainer>
      </Step>

      <Step>
        <StepContainer title='Realizar pago'>
          {['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) ? 
            <Stack marginTop={-1} marginBottom={2}>
              <CostumerInfo name={cart?.costumer?.name} DNI={cart?.costumer?.DNI} />
            </Stack> 
          : <></>}

          <PaymentMethods
            setActualStep={setActualStep}
            method={paymentPDV} 
            setMethod={setPaymentPDV} 
            balance={balance}
            setBalance={setBalance}
            amount={parseFloat(total.toFixed(2))}
            setPaymentPDV={setPaymentPDV}
          />
        </StepContainer>
      </Step>
          
      <Step>
        <StepContainer title='Generación de guías'>
          {['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) ? 
            <Stack marginTop={-1} marginBottom={2}>
              <CostumerInfo name={cart?.costumer?.name} DNI={cart?.costumer?.DNI} />
            </Stack> 
          : <></>}

          <GeneratingWaybills paymentMethod={paymentPDV} setActualStep={setActualStep} removeCart={removeCart} />
        </StepContainer>
      </Step>

      <Step>
        <StepContainer title='Resumen de compra'>
          <>
            {/* <SuccessQuote
              waybillGenerated={cart.waybillsGenerated}
              waybillsWithErrors={cart.waybillsWithErrors}
            /> */}
            {['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) ? 
              <Stack marginTop={-1} marginBottom={2}>
                <CostumerInfo name={cart?.costumer?.name} DNI={cart?.costumer?.DNI} />
              </Stack> 
            : <></>}

            <BuyResume />

            <Stack
              direction='row'
              justifyContent='begin'
              sx={{ paddingTop: 2 }}
            >
              <Button
                variant='contained'
                onClick={() => setShowSessionEnd(true)}
              >
                Finalizar carrito
              </Button>
            </Stack>
          </>
        </StepContainer>
      </Step>
    </Stepper>
  )

  return (
    <>
      {cart?.waybills?.length === 0 && cart?.products?.length === 0 && cart?.deliveries?.length === 0 && cart?.pendingPays?.length === 0 ? <EmptySession /> : <ActualSession />}

      <Dialog
        open={showSessionDelete}
        onClose={() => setShowSessionDelete(false)}
      >
        <DialogTitle>Eliminar carrito</DialogTitle>
        <DialogContent>¿Seguro que desea eliminar este carrito? Se perderán todas las cotizaciones y esta acción no se puede revertir</DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => setShowSessionDelete(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              removeCart()
              setShowSessionDelete(false)
            }}
            color='error'
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSessionEnd}
        onClose={() => setShowSessionEnd(false)}
      >
        <DialogTitle>Terminar carrito</DialogTitle>
        <DialogContent>¿Seguro que desea terminar este carrito? Ya no podrás acceder a este carrito, esta acción no se puede revertir</DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => setShowSessionEnd(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              removeCart()
              setShowSessionEnd(false)
              setActualStep(0)
            }}
            color='error'
          >
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showAddItem}
        onClose={() => {
            if(window.confirm("¿Esta seguro(a) en salir?")){
              setShowAddItem(false)
            }
          }
        }
      >
        <DialogTitle>{userPermissions['role'] === 'Cliente' || userPermissions['role'] === 'SubCliente' ? 'Agregar cotización' : 'Agregar item'}</DialogTitle>
        <DialogContent>
          <AddItem close={() => setShowAddItem(false)} />
        </DialogContent>
      </Dialog>
    </>
  )
}

const StepContainer = ({ title, children }) => {
  return (
    <>
      <StepLabel>{title}</StepLabel>
      <StepContent>
        <Stack sx={{ paddingY: 2 }}>{children}</Stack>
      </StepContent>
    </>
  )
}
