import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

import '../shippingStyles.css';

export default function ButtonLink(props){

    const { 
        url,
        clases,
        texto
    } = props;
    const navigate = useNavigate();

    function goTo(){
        navigate( url );
    }

    return(
        <div className = { clases }>
            <Button
                variant = "contained"
                onClick = { goTo }
            >
                    { texto }
            </Button>
        </div>
    );
}