import { MainContainer, Message, SkeletonLoad, Table, SnackBarAlert, Selector } from '../../Shared'
import { TableCell, TableRow, IconButton, Stack, Typography, Button, Switch } from '@mui/material'
import { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PersonOffOutlined } from '@mui/icons-material'
import { changeServicesData, getServices } from './Services'
import { ArrowBack } from '@mui/icons-material'
import ServicesForm from './ServicesForm'

export default function Index() {
  const { state } = useLocation()
  const navigate = useNavigate()

  // State values
  const [loadAllowed, setLoadAllowed] = useState(false)

  const [loading, setLoading] = useState(false)
  const alert = useRef({ message: '', severity: 'error' })
  const [showError, setShowError] = useState(false)
  const [showServiceForm, setShowServiceForm] = useState(false)

  // Data values
  const [services, setServices] = useState(/**@type {object[]|undefined} */ (undefined))
  const [carrier, setCarrier] = useState('Todos')
  const currentService = useRef({})

  const carriers = useMemo(() => {
    if (services === undefined || services.length === 0) return []
    const carriers = new Set()
    for (const service of services) if (!carriers.has(service.carrier.name)) carriers.add(service.carrier.name)
    return ['Todos', ...carriers.values()]
  }, [services])

  const filteredServices = useMemo(() => {
    if (services === undefined) return []
    let data = services
    if (carrier === 'Todos') return services
    return data.filter(s => s.carrier.name == carrier)
  }, [services, carrier])

  const showAlert = (message, severity = 'error') => {
    alert.current.message = message
    alert.current.severity = severity
    setShowError(true)
  }

  const handleAllowed = async (allowed, service_id) => {
    try {
      setLoadAllowed(true)
      currentService.current = { id: service_id }
      const { error } = await changeServicesData({ allowed, service_id, user_id: state?.user_id })
      if (error) showAlert('Ocurrió un error al habilitar el servicio, inténtelo más tarde.')
      else {
        const copy = services
        const index = copy.findIndex(s => s.id === service_id)
        copy[index].allowed = allowed
        if (!allowed) {
          copy[index].tarifa_base = 0
          copy[index].kg_amparados = 0
          copy[index].sobrepeso = 0
        }
        setServices(copy)
      }
    } catch (error) {
      showAlert('Ocurrió un error al habilitar el servicio, inténtelo más tarde.')
    } finally {
      setLoadAllowed(false)
      currentService.current = { id: null }
    }
  }

  // Fetch Services and Providers
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const { error, services } = await getServices(state?.user_id)
        if (error) showAlert('Ocurrió un problema al recuperar los servicios, inténtelo de nuevo en unos momentos')
        else setServices(services)
      } catch (error) {
        showAlert('Ocurrió un error al recuperar los datos, intente de nuevo en unos momentos.', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const ServiceRow = useCallback(
    ({ item }) => {
      const { name, carrier, allowed, id, tarifa_base, kg_amparados, sobrepeso, kg_maximo } = item
      const disabled = loadAllowed && id === currentService.current.id
      return (
        <TableRow>
          <TableCell>{name}</TableCell>
          <TableCell>{carrier.name}</TableCell>
          <TableCell>{kg_maximo ? `${kg_maximo} Kg` : '-'}</TableCell>
          <TableCell>$ {tarifa_base}</TableCell>
          <TableCell>{sobrepeso} $</TableCell>
          <TableCell>{kg_amparados} Kg</TableCell>
          <TableCell>
            <Switch
              disabled={disabled}
              checked={allowed}
              onChange={() => handleAllowed(!allowed, id)}
            />
          </TableCell>
          <TableCell>
            <Button
              disabled={!allowed}
              variant='contained'
              onClick={() => {
                currentService.current = {
                  allowed,
                  tarifa_base: tarifa_base,
                  kg_amparados: kg_amparados,
                  sobrepeso: sobrepeso,
                  service_id: id,
                  user_id: state?.user_id
                }
                setShowServiceForm(true)
              }}
            >
              Editar
            </Button>
          </TableCell>
        </TableRow>
      )
    },
    [services, loadAllowed]
  )

  if (!state || !state?.user_id)
    return (
      <MainContainer>
        <Stack
          alignItems='center'
          justifyContent='center'
        >
          <Message
            title='¡Ocurrió un error!'
            message='Al parecer ocurrieon unos problemas con la página, por favor intenta acceder en unos momentos más adelante.'
          />
        </Stack>
      </MainContainer>
    )

  return (
    <MainContainer>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>

          <Typography
            variant='h1'
            sx={{ paddingY: 2 }}
          >
            Servicios de {state?.name}
          </Typography>
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-around'
          spacing={1}
        >
          <Selector
            state={[carrier, setCarrier]}
            options={carriers}
            label='Carriers'
            disabled={loading || carriers.length === 0}
          />
        </Stack>
      </Stack>

      {loading && (
        <SkeletonLoad
          types={['rectangular', 'rounded']}
          numberOfItems={5}
          randomHeight
          heightRange={[60, 100]}
        />
      )}

      {!loading && (services === undefined || services.length === 0) && (
        <Message
          title='Sin servicios'
          message='Para poder visualizar información registra algún servicio.'
          icon={<PersonOffOutlined sx={{ fontSize: 50 }} />}
        />
      )}

      {!loading && services !== undefined && services.length > 0 && (
        <Table
          data={filteredServices}
          headers={['Nombre', 'Carrier', 'KG máximos', 'Tarifa base', 'Sobre peso', 'KG amparados', 'Permitido', 'Acciones']}
          RowComponent={ServiceRow}
          pagination
        />
      )}

      <ServicesForm
        service={currentService.current}
        displayState={[showServiceForm, setShowServiceForm]}
        serviceSetter={setServices}
      />

      <SnackBarAlert
        displayState={[showError, setShowError]}
        message={alert.current.message}
        severity={alert.current.severity}
      />
    </MainContainer>
  )
}
