import { get, post } from '../../../utils/requests'
const PATH = 'api/v2'

/**
 *
 * @param {number} user_id
 * @returns
 */
export async function getServices(user_id) {
  try {
    const response = await get(PATH + '/services/allowed', { user_id })

    if (!response.ok) return { error: true }

    const { services } = await response.json()
    return {
      error: false,
      services
    }
  } catch (error) {
    return { error: true }
  }
}

/**
 * @typedef {object} Service
 * @property {number} tarifa_base
 * @property {number} kg_amparados
 * @property {number} sobrepeso
 * @property {boolean} allowed
 * @property {number} service_id
 * @property {number} user_id
 */

/**
 *
 * @param {Service} service
 */

export async function changeServicesData(service) {
  try {
    const response = await post(service, PATH + '/services')
    return { error: !response.ok }
  } catch (error) {
    return { error: true }
  }
}
