import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material'
import Message from './Message'
import { DataArrayOutlined } from '@mui/icons-material'
import { useState, useMemo, memo, useEffect } from 'react'

/**
 * @typedef {object} ComponentParams
 * @property {string[]} headers
 * @property {Array} data
 * @property {React.FC} RowComponent
 * @property {number[]} [rowsPerPageOptions=[10, 25, 50]]
 * @property {number} [rowsPerPage=10]
 * @property {boolean} pagination
 *
 */

/**
 * @param {ComponentParams} params
 * @returns
 */

function Customtable(params) {
  const { headers, RowComponent, pagination, rowsPerPageOptions, data } = params

  // Page states
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(params.rowsPerPage ?? 10)

  useEffect(() => {
    setPage(0)
  }, [data])

  // Data slice
  const slicedData = useMemo(() => {
    const { data } = params
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }, [page, rowsPerPage, data])

  return (
    <>
      {slicedData.length === 0 && (
        <Message
          title='Sin información'
          message='No hay información para mostrar.'
          icon={<DataArrayOutlined sx={{ fontSize: 50 }} />}
        />
      )}
      {slicedData.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(item => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedData.map((item, index) => (
              <RowComponent
                item={item}
                index={index}
              />
            ))}
          </TableBody>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions ?? [10, 25, 50]}
              count={data.length}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={e => {
                setRowsPerPage(e.target.value)
                setPage(0)
              }}
              page={page}
              onPageChange={(e, p) => setPage(p)}
            />
          )}
        </Table>
      )}
    </>
  )
}

export default memo(Customtable)
