import React, {useEffect} from 'react'
import {  Box, Button, Paper, Typography, useTheme,Grid } from '@mui/material'

import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Carousel(props) {
    const theme = useTheme()
    const images = props.images
    const carouselHeight = props.heigth?props.heigth:250

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const counter = async()=>{
        setTimeout(() => {
            if (activeStep<maxSteps-1) {
                handleNext()
            }else(
                setActiveStep(0)
            )
          }, props.timeout?props.timeout:"5000");          
    }
    useEffect(() => {
        counter()
      }, [activeStep])

    return(
        <Grid backgroundColor='default'
            item
            xs={12}
            md={3}
            sx={{
                pb:3
            }}
        >
            <Paper
            variant='outlined'
            sx={{ 
                p: 2, 
                height: 'auto',
                backgroundColor: 'default'}}
            >
                <Typography
                        //fontSize='100%'
                        variant='h1'
                        sx={{ paddingY: 2, textAlign: 'left' }}
                    >
                    {props.title}
                </Typography>
                <Box
                    sx={{ 
                        flexGrow: 1, 
                        backgroundColor: 'default', 
                        padding: 1,
                        alignItems: 'end',
                        height: 'auto',
                    }}
                >
                    <AutoPlaySwipeableViews
                        width='auto'
                        backgroundColor='red'
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        //onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {images.map((step, index) => (
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                                height:carouselHeight
                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                            ) : null}
                        </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <Paper
                        square
                        elevation={0}
                        display= 'flex'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            pl: 1, pr: 1
                        }}
                    >
                        <Typography sx={{height: 'auto', color:'default'}}>{images[activeStep].label}</Typography>
                    </Paper>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                Next
                                {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                                ) : (
                                <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                                ) : (
                                <KeyboardArrowLeft />
                                )}
                                Back
                            </Button>
                        }
                    />
                </Box>
            </Paper>
        </Grid>
    )
}