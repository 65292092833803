import React from 'react'
import { useCallback, useState, useEffect } from 'react'
import { Container, Grid, Paper, Stack, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Typography, Button } from '@mui/material'
import { PaidOutlined } from '@mui/icons-material'
import { MONTHS_OBJ} from '../../../utils/constants'
import { fechaLarga, yearRange } from '../../../utils/FormatoDeFecha'
import SnackBarAlert from '../../Shared/SnackBarAlert'
import SkeletonLoad from '../../Shared/SkeletonLoad'
//import Copyright from '../../General/Copyright'
import Selector from '../../Shared/Selector'
import Message from '../../Shared/Message'
import SaveIcon from '@mui/icons-material/Save'

const current = new Date()
const YEARS = yearRange(2022, current.getFullYear())
const host = sessionStorage.getItem('localhost')
const token = sessionStorage.getItem('token')

export default function BalanceMovement (props) {

    const [cutOffs, setCutOffs] = useState(null)
    const [loading, setLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const [showError, setShowError] = useState(false)
    const [month, setMonth] = useState(current.toDateString().split(' ')[1])
    const [year, setYear] = useState(current.getFullYear())

    const getMovements = async ({ host, token, month, year }) => {
        try {
          const url = new URL(`${host}/api/v2/payment/credit/movements`)
          if(month) url.searchParams.append('month', month)
          if(year) url.searchParams.append('year', year)
          
          const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          if (response.status !== 200) return {error: true}
          return await response.json()
        } catch (error) {
            return {error: true}
        }
      }
    const saveReports = async ({ host, token, month, year }) => {
        try {
          const url = new URL(`${host}/api/v2/payment/credit/movements/download`)
          if(month) url.searchParams.append('month', month)
          if(year) url.searchParams.append('year', year)
          
          const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `${year} ${month} Movimientos de saldo.xlsx`;
            document.body.appendChild(link);
            link.click();
            link.remove();
          }

        } catch (error) {
            return {error: true}
        }
      }
    const MovementRow = useCallback(({ cutOff }) => {
        
        const { status, created_at, descripcion, monto, type_movement, user_origin} = cutOff
        const date = fechaLarga(new Date(created_at))

        return(
            <TableRow>
                <TableCell>{date}</TableCell>
                <TableCell>{type_movement.nombre}</TableCell>
                <TableCell>{user_origin.name}</TableCell>
                <TableCell>{descripcion}</TableCell>
                <TableCell>$ {monto}</TableCell>
                <TableCell>{status}</TableCell>
            </TableRow>
        )
    }, [])

    async function handleGetMovements() {
        try {
            setLoading(true)
            const { movements, error } = await getMovements({ host, token, month, year })
            if (error) {
                setErrorMessage('Ocurrió un error al recuperar sus movimientos, intente de nuevo en unos momentos.')
                setShowError(true)
            } else setCutOffs(movements)
        } catch (error) {
            setErrorMessage('Ocurrió un error al recuperar sus movimientos, intente de nuevo en unos momentos.')
            setShowError(true)
            setCutOffs(null)
        } finally {
            setLoading(false)
        }
    }

    async function handleDownloadReport() {
        setLoading(true)
        await saveReports({ host, token, month, year })
        setLoading(false)
    }
    
    useEffect(() => {
        handleGetMovements()
      }, [month, year])

    useEffect(()=>{
        console.log(cutOffs);
    },[cutOffs])
    
    return(
        <Container
            sx={{ 
                mt: 4, 
                mb: 4, 
                textAlign: 'left'
            }}
        >
            <Grid
                item
                xs={12}
                md
            >
                <Paper
                    sx={{ 
                        p: 2
                    }}
                    variant='outlined'
                >
                        <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Typography
                                variant='h1'
                                sx={{ paddingY: 2 }}
                            >
                            Movimientos de saldo
                            </Typography>
                            <Stack
                                direction='row'
                                spacing={1}
                            >
                            <Selector
                                state={[year, setYear]}
                                label='Año'
                                options={YEARS}
                            />
                            <Selector
                                state={[month, setMonth]}
                                label='Mes'
                                options={MONTHS_OBJ}
                                isObject
                            />
                            <Button
                                variant='outlined'
                                color='info'
                                fullWidth={true}
                                type='reset'
                                disabled={(!(cutOffs?.length > 0) || loading)}
                                startIcon={<SaveIcon />}
                                onClick={handleDownloadReport}
                            >
                                Guardar como Excel
                            </Button>
                            
                        
                        </Stack>
                    </Stack>

                    {loading && (
                        <SkeletonLoad
                            types={['rectangular', 'rounded']}
                            numberOfItems={4}
                            randomHeigth
                            heigthRange={[50, 120]}
                        />
                    )}

                    {!loading && (cutOffs === undefined || cutOffs?.length === 0) && (
                        <Message
                            title='Sin movimientos'
                            message='Para poder visualizar los movimientos realiza alguna compra o abona saldo.'
                            icon={<PaidOutlined sx={{ fontSize: 50 }} />}
                        />
                    )}

                    {!loading && cutOffs?.length > 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '15%'}}>Fecha</TableCell>
                                    <TableCell style={{width: '15%'}}>TipoMovimiento</TableCell>
                                    <TableCell style={{width: '15%'}}>Usuario</TableCell>
                                    <TableCell style={{width: '15%'}}>Descripcion</TableCell>
                                    <TableCell style={{width: '10%'}}>Monto</TableCell>
                                    <TableCell style={{width: '10%'}}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cutOffs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(item => (
                                    <MovementRow cutOff={item} />
                                ))}
                            </TableBody>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50,100]}
                                count={cutOffs?.length}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={e => {
                                    setRowsPerPage(e.target.value)
                                    setPage(0)
                                }}
                                page={page}
                                onPageChange={(e, p) => setPage(p)}
                            />
                        </Table>
                    )}
                </Paper>

                <SnackBarAlert
                    displayState={[showError, setShowError]}
                    severity='error'
                    message={errorMessage}
                />
            </Grid>
        </Container>
    )
}