export const getCutOffs = async ({ host, token, month, year, userId }) => {
  try {
    const url = new URL(`${host}/api/v2/cut-offs`)
    if (month) url.searchParams.append('month', month)
    if (year) url.searchParams.append('year', year)
    if (userId) url.searchParams.append('userId', userId)

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    if (response.status !== 200) return { error: true }
    return await response.json()
  } catch (error) {
    return { error: true }
  }
}

export const getFlow = async ({ host, token, id }) => {
  try {
    const response = await fetch(`${host}/api/v2/cut-offs/${id}/flow`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    if (response.status !== 200) return { error: true }
    return await response.json()
  } catch (error) {
    return { error: true }
  }
}

export const getFlowTotal = async ({ host, token, id }) => {
  try {
    const response = await fetch(`${host}/api/v2/cut-offs/${id}/totals`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    if (response.status !== 200) return { error: true }
    return await response.json()
  } catch (error) {
    return { error: true }
  }
}

export const getHistory = async ({ host, token, id }) => {
  try {
    const response = await fetch(`${host}/api/v2/cut-offs/${id}/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    if (response.status !== 200) return { error: true }
    return await response.json()
  } catch (error) {
    return { error: true }
  }
}

export const endCutOff = async ({ host, token, id }) => {
  try {
    const response = await fetch(`${host}/api/v2/cut-offs/${id}/end`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    const data = await response.json()
    return { status: response.status, ...data }
  } catch (error) {
    return { error: true }
  }
}

export const createExpense = async ({ host, token, details, price }) => {
  try {
    const response = await fetch(`${host}/api/v2/cut-offs`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ details, amount: price })
    })

    const responseData = await response.json()
    return { error: response.status !== 201, message: responseData?.message }
  } catch (error) {
    return { error: true }
  }
}
