import React , { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

import {Grid,
        TextField,
        FormControl,
        FormControlLabel,
        RadioGroup,
        Radio,
        Typography,
        InputAdornment,
        Button,
        Alert,
        AlertTitle} from '@mui/material';
import {Home,
        Send,
        LocalShipping,
        AttachMoney,
        Diamond} from '@mui/icons-material';

import '../shippingStyles.css';
import { whoIsWillPaying } from './AmountReceivableSummary'; 
import { objectJSON } from './FormModel';

export default function WhoIsWillPay(props){
    
    /* Estados */
    const [ montoACobrar , setMontoACobrar ] = useState('');
    const [ valorDeMercancia , setValorDeMercancia ] = useState('');
    const [ costoDeEnvio , setCostoDeEnvio ] = useState('');
    const [ originOrDestination , setOriginOrDestination ] = useState('Origen');
    const [ IsMerchandiseValueOvershoot , setIsMerchandiseValueOvershoot ] = useState( false );

    /* Constante de navegación */
    const navigate = useNavigate();

    /* Funciones para cambiar el valor de los estados */
    const catchOriginOrDestination = ( event ) => {
        /* Actualizando el estado de originOrDestination */ 
        setOriginOrDestination( event.target.value );
        objectJSON.data.whoIsPaying.origenDestino = event.target.value;

        /* Reseteando el estado de valor de mercancia */
        setValorDeMercancia( '0.00' );
        objectJSON.data.whoIsPaying.valorDeMercancia = 0.0;

        /* Actualizando el estado de monto a cobrar */
        setMontoACobrar(( objectJSON.data.whoIsPaying.origenDestino === 'Origen' ) 
                        ? '0.00' 
                        :  objectJSON.data.whoIsPaying.costoDeEnvio );
        objectJSON.data.whoIsPaying.montoACobrar = ( objectJSON.data.whoIsPaying.origenDestino === 'Origen' )
                                                    ? 0
                                                    : objectJSON.data.whoIsPaying.costoDeEnvio; 

        /* Agregando elementos de ¿Quien va a pagar el envio? */
        whoIsWillPaying.willPay = event.target.value;
        whoIsWillPaying.icon = ( event.target.value === 'Origen' ) ? <Home fontSize = "small" sx = {{ color : "#ff4500" }} /> : <Send fontSize = "small" sx = {{ color : "#ff4500" }} />;

        /* Se resetea el estado de isMerchandiseValueOvershoot */
        setIsMerchandiseValueOvershoot( false );
    };

    const catchValorDeMercancia = ( event ) => {
        /* Se actualiza el estado de valor de mercancia */
        setValorDeMercancia( 
            event.target.value
                .replace( /[^0-9.]/g , "" )
                .replace( /^(\d*\.?)|(\d*)\.?/g , "$1$2" )
        );
        /* Se realiza la siguiente suma en el estado de monto a cobrar */
        setMontoACobrar(
            ( event.target.value !== "" ) 
            ? parseFloat( Number( costoDeEnvio ) + Number( event.target.value.replace( /[^0-9.]/g , "" ).replace( /^(\d*\.?)|(\d*)\.?/g , "$1$2" ) ) ).toFixed(2) 
            : parseFloat( Number( costoDeEnvio ) + 0 ).toFixed(2)
        );
        /* Se actualizan las propiedades de valor de mercancia y monto a cobrar */
        objectJSON.data.whoIsPaying.valorDeMercancia = ( event.target.value !== "" )
            ? Number( event.target.value.replace( /[^0-9.]/g , "" ).replace( /^(\d*\.?)|(\d*)\.?/g , "$1$2" ) )
            : 0;
        objectJSON.data.whoIsPaying.montoACobrar = ( event.target.value !== "" ) 
            ? Number( costoDeEnvio ) + Number( event.target.value.replace( /[^0-9.]/g , "" ).replace( /^(\d*\.?)|(\d*)\.?/g , "$1$2" ) )
            : Number( costoDeEnvio ) + 0;
        /* Se sobrepaso el preció de valor de mercancía */
        setIsMerchandiseValueOvershoot(
            ( Number( event.target.value.replace( /[^0-9.]/g , "" ).replace( /^(\d*\.?)|(\d*)\.?/g , "$1$2" ) ) > 3000 )
            ? true
            : false
        );
    }

    /* Funcion para cargar campos si es origen o destino */
    const isOriginOrDestination = () => {
        return ( originOrDestination === 'Destino' ) ?
            <>
                <Grid item xs = { 12 } md = { 2 }>
                    <TextField 
                        id = "outlined-number"
                        label = "Costo del envio"
                        InputLabelProps = {{ shrink : true, }}
                        variant = "outlined"
                        value = { costoDeEnvio }
                        fullWidth = { true }
                        disabled
                        InputProps = {{
                            startAdornment : (
                                <InputAdornment position = "start">
                                    <LocalShipping fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs = { 12 } md = { 2 }>
                    <TextField
                        id = "outlined-number"
                        label = "Valor de la mercancia"
                        InputLabelProps = {{ shrink : true, }}
                        variant = "outlined"
                        onChange = { catchValorDeMercancia }
                        value = { valorDeMercancia }
                        fullWidth = { true }
                        InputProps = {{
                            startAdornment : (
                                <InputAdornment position = "start">
                                    <Diamond fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs = { 12 } md = { 2 }>
                    <TextField
                        id = "outlined-number"
                        label = "Monto a cobrar a la entrega"
                        InputLabelProps = {{ shrink : true, }}
                        variant = "outlined"
                        value = { montoACobrar }
                        fullWidth = { true }
                        disabled
                        InputProps = {{
                            startAdornment : (
                                <InputAdornment position = "start">
                                    <AttachMoney fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs = { 12 } md = { 3 }>
                    <Button
                        disabled = { IsMerchandiseValueOvershoot } 
                        variant = "contained" 
                        onClick = { goTo }
                    >
                        Siguiente
                    </Button>
                </Grid>
            </>
        :
            <>
                <Grid item xs = { 12 } md = { 3 }>
                    <TextField 
                        id = "outlined-number"
                        label = "Costo del envio"
                        InputLabelProps = {{ shrink : true, }}
                        variant = "outlined"
                        value = { costoDeEnvio }
                        fullWidth = { true }
                        disabled
                        InputProps = {{
                            startAdornment : (
                                <InputAdornment position = "start">
                                    <LocalShipping fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs = { 12 } md = { 3 }>
                    <TextField
                        id = "outlined-number"
                        label = "Monto a cobrar a la entrega"
                        InputLabelProps = {{ shrink : true, }}
                        variant = "outlined"
                        value = { montoACobrar }
                        fullWidth = { true }
                        disabled
                        InputProps = {{
                            startAdornment : (
                                <InputAdornment position = "start">
                                    <AttachMoney fontSize = "small" sx = {{ color : "#ff4500" }} /> 
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs = { 12 } md = { 3 }>
                    <Button 
                        variant = "contained" 
                        onClick = { goTo }
                    >
                        Siguiente
                    </Button>
                </Grid>
            </>;
    }

    /* Función para validar si no hubo un dato faltante */
    const goTo = () => {
        if( objectJSON.data.origin.origenPersona.nombre            !== '' &&
            objectJSON.data.origin.origenPersona.apellidoPaterno   !== '' &&
            objectJSON.data.origin.origenCalle                     !== '' &&
            objectJSON.data.origin.origenNumExt                    !== '' &&
            objectJSON.data.origin.origenColonia                   !== '' &&
            objectJSON.data.origin.origenMunicipio                 !== '' &&
            objectJSON.data.origin.origenEstado                    !== '' &&
            objectJSON.data.origin.origenPais                      !== '' &&
            objectJSON.data.origin.origenCP                        !== '' &&
            objectJSON.data.origin.origenTelefono1                 !== '' &&
            objectJSON.data.destination.destinoPersona.nombre      !== '' &&
            objectJSON.data.destination.destinoPersona.apellidoPaterno !== '' &&
            objectJSON.data.destination.destinoCalle               !== '' &&
            objectJSON.data.destination.destinoNumExt              !== '' &&
            objectJSON.data.destination.destinoColonia             !== '' &&
            objectJSON.data.destination.destinoMunicipio           !== '' &&
            objectJSON.data.destination.destinoEstado              !== '' &&
            objectJSON.data.destination.destinoPais                !== '' &&
            objectJSON.data.destination.destinoCP                  !== '' &&
            objectJSON.data.destination.destinoTelefono1           !== '' &&
            objectJSON.data.kilosServiceAndNumber.tipo_servicio_id !== 0  &&
            objectJSON.data.kilosServiceAndNumber.kg !== 0.0){
            
            if( objectJSON.data.kilosServiceAndNumber.kg <= 80.0 ){
                // setLoad( !load );
                navigate( objectJSON.data.isQuote ? '/paymentConfirmation' : '/methodsOfPayment' );
                return;
            }

            alert( 'El maximo de Kg amparados es de: 80.00' );
            return;
        }

        alert( 'Asegure en llenar todos los campos requeridos.' );
    }

    /* Funciones ejecutados con useEffect */
    function showData(){
        setMontoACobrar( 
                            ( objectJSON.data.whoIsPaying.origenDestino === 'Origen' ) 
                            ? '0.00' 
                            : objectJSON.data.whoIsPaying.montoACobrar 
        );

        if( objectJSON.data.whoIsPaying.origenDestino === 'Origen' ) {
            objectJSON.data.whoIsPaying.montoACobrar = 0; 
        }

        setValorDeMercancia( 
                                ( objectJSON.data.whoIsPaying.valorDeMercancia === 0 )
                                ? '0.00' 
                                : objectJSON.data.whoIsPaying.valorDeMercancia 
        );

        setCostoDeEnvio( 
                            ( objectJSON.data.whoIsPaying.costoDeEnvio === 1 ) 
                            ? '1.00' 
                            : objectJSON.data.whoIsPaying.costoDeEnvio 
        );

        setOriginOrDestination( objectJSON.data.whoIsPaying.origenDestino );
    }

    useEffect( () => { showData(); } , [] );

    /* Retornando componente */
    return(
        // <Container sx={{ textAlign : "left" }}>
            <Grid container spacing={1} sx={{ display: "flex" , alignItems : "center" }}>

                <Grid item xs={12}>
                    <Typography lineHeight = {0}>
                        ¿Quien pagara el envio?
                    </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby = "demo-row-radio-buttons-group-label"
                            name = "row-radio-buttons-group"
                            value = { originOrDestination }
                            onChange = { catchOriginOrDestination }
                        >
                            <FormControlLabel 
                                value = "Origen" 
                                control = { <Radio /> }
                                label = "Origen"
                            />
                            <FormControlLabel 
                                value = "Destino" 
                                control = { <Radio /> }
                                label = "Destino"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                { isOriginOrDestination() }

                {
                    IsMerchandiseValueOvershoot ?
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <Alert severity="error">
                                <AlertTitle>El valor de mercancía a rebasado el limite</AlertTitle>
                                <div 
                                    style = {{
                                        display : "flex",
                                        alignItems : "center",
                                        justifyContent : "center"
                                    }}
                                >
                                    <Typography>
                                        El valor de mercancía ${ valorDeMercancia } debe ser menor a $3000.00
                                    </Typography>
                                </div>
                            </Alert>
                        </Grid> : <></>
                }
            </Grid>
        // </Container>
    );
}

// <div
        //     className = { divGridClasses }
        // >
        //     <Grid
        //         container 
        //         spacing = { 5 }
        //     >
        //         <Grid
        //             item
        //             xs = { 12 }
        //         >
        //             <FormControl
        //                 sx = {{
        //                     backgroundColor : "#ffffff",
        //                     padding : "5px",
        //                     borderRadius : "5px",
        //                     border : "2px solid #ff4500"
        //                 }}
        //             >
        //                 <FormLabel 
        //                     id = "demo-row-radio-buttons-group-label"
        //                 >
        //                     ¿Quien paga el envio?
        //                 </FormLabel>

        //                 <RadioGroup
        //                     row
        //                     aria-labelledby = "demo-row-radio-buttons-group-label"
        //                     name = "row-radio-buttons-group"
        //                     value = { originOrDestination }
        //                     onChange = { catchOriginOrDestination }
        //                 >
        //                     <FormControlLabel 
        //                         value = "Origen" 
        //                         control = { <Radio /> }
        //                         label = "Origen"
        //                     />
        //                     <FormControlLabel 
        //                         value = "Destino" 
        //                         control = { <Radio /> }
        //                         label = "Destino"
        //                     />
        //                 </RadioGroup>
        //             </FormControl>
        //         </Grid>

        //         { isOriginOrDestination() }

        //         {/* {ListButtons} */}

        //     </Grid>
        // </div>