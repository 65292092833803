import {Box, Button, Container, Grid, Paper, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography, useTheme, Alert, Skeleton, CircularProgress, Input, Collapse, AlertTitle} from '@mui/material'
import {memo, useEffect, useState} from 'react'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useNavigate } from 'react-router-dom'
//import { CheckCircleOutline, UploadFileOutlined, AutorenewOutlined } from '@mui/icons-material'
//import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AmountSelection from './AmountSelection'
import ListPrePayments from './ListPrePayments'
import PaymentMethods from './PaymentMethods'
import PaymentResume from './PaymentResume'
import TransferPayment from './TransferPayment'
import CardPayment from './CardPayment'
import FinishedPayment from './FinishedPayment'

export default function Index() {
  const navigation = useNavigate()
  const theme = useTheme()

  const [actualStep, setActualStep] = useState(0)
  const [amountSelected, setAmountSelected] = useState('')
  const [methodSelected, setMethodSelected] = useState(0)
  const [amount, setAmount] = useState(0)
  const [reference, setReference] = useState(undefined)

  /* Estados relacionados a los prepagos */
  const [prePayments, setPrePayments] = useState([])
  const [prePayment, setPrePayment] = useState(null)
  const [prePaymentSelected, setPrePaymentSelected] = useState(0)
  const [costumerPrePayment, setCostumerPrePayment] = useState(null)

  /* Estados relacionados con el pago con tarjeta */
  const [holderName, setHolderName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [expirationYear, setExpirationYear] = useState('23')
  const [expirationMonth, setExpirationMonth] = useState('1')
  const [cvv2, setCvv2] = useState('')
  const [cardType, setCardType] = useState('')

  // const [operationSheetLoading, setOperationSheetLoading] = useState(false)
  // const [operationSheetError, setOperationSheetError] = useState(false)
  // const [operationSheetTitleError, setOperationSheetTitleError] = useState('')
  // const [operationSheetMSGError, setOperationSheetMSGError] = useState('')

  // async function getOperationSheet(){
  //   try {
  //     setOperationSheetLoading(true)

  //     const request = await fetch(`${sessionStorage.getItem('localhost')}/api/v2/`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
  //         'Cache-Control': 'no-cache'
  //       } 
  //     })

  //     if(request.ok){
  //       const response = await request.blob()
  //       const download = require('downloadjs')
  //       download(response, 'Folio de operación.pdf', 'application/pdf')
  //       return
  //     }

  //     throw new Error(`Hubo, un problema al momento de descargar el folio de operación, vuelva a intentarlo. Sí el problema aun persite contacta con soporte tecnico a ${process.env.REACT_APP_SUPPORT_EMAIL} ó ${process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER}`)
  //   } catch (error) {
  //     setOperationSheetError(true)
  //     setOperationSheetTitleError('Algo ocurrio')
  //     setOperationSheetMSGError(error.message)
  //   } finally {
  //     setOperationSheetLoading(false)
  //   }
  // }

  return (
    <Container
      maxWidth='lg'
      sx={{ mt: 4, mb: 4, textAlign: 'left' }}
    >
      <Paper
        variant='outlined'
        sx={{ p: 2 }}
      >
        <Stack spacing={2}>
          <Typography variant='h1'>Contratar un paquete / Agregar saldo</Typography>

          <Stepper
            activeStep={actualStep}
            orientation='vertical'
          >
            <Step key='Elige un paquete / Monto a cobrar'>
              <StepContainer
                title='Elige un paquete / Monto a cobrar'
                first={true}
                disabled={prePayment === null && amount < 500}
                actualStep={actualStep}
                setActualStep={setActualStep}
              >
                <Grid 
                  container
                  columnSpacing={2}
                  rowSpacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    display='flex'
                    alignItems='center'
                    sx={theme => ({
                      justifyContent: 'center',
                      [theme.breakpoints.between('xs', 'sm')]: {
                        justifyContent: 'left'
                      }
                    })}
                  >
                    <ListPrePayments 
                      prePayments={prePayments}
                      setPrePayments={setPrePayments}
                      prePaymentSelected={prePaymentSelected}
                      setPrePaymentSelected={setPrePaymentSelected}
                      setPrePayment={setPrePayment}
                      setAmountSelected={setAmountSelected}
                      setAmount={setAmount}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    sx={theme => ({
                      [theme.breakpoints.between('md', 'xl')]: {
                        borderLeft: '5px solid #000000',
                      },
                      [theme.breakpoints.between('xs', 'sm')]: {
                        borderTop: '5px solid #000000'
                      }
                    })}
                  >
                    <AmountSelection
                      amountSelected={amountSelected}
                      setAmountSelected={setAmountSelected}
                      amount={amount}
                      setAmount={setAmount}
                      setPrePaymentSelected={setPrePaymentSelected}
                      setPrePayment={setPrePayment}
                    />
                  </Grid>
                </Grid>

                <Stack marginTop={1}>
                  <Alert severity='info'>
                    <AlertTitle>Acerca de la vigencia del paquete</AlertTitle>
                    {`La vigencia del paquete es sobre el precio especial por envío. Después de la fecha de vigencia no perderás el saldo.`}
                  </Alert>
                </Stack>

                <ButtonArea>
                  <Button
                    variant='contained'
                    disabled={prePayment === null && amount < 100}
                    onClick={() => setActualStep(1)}
                  >
                    Siguiente
                  </Button>
                </ButtonArea>
              </StepContainer>
            </Step>

            <Step key='Método de pago'>
              <StepContainer
                title='Método de pago'
                actualStep={actualStep}
                setActualStep={setActualStep}
                disabled={methodSelected === 0}
              >
                <PaymentMethods
                  methodSelected={methodSelected}
                  setMethodSelected={setMethodSelected}
                />

                <Collapse in={methodSelected === '1'}>
                  <Alert
                    severity='info'
                    sx={{ marginTop: 2 }}
                  >
                    <AlertTitle>Hasta 72 horas</AlertTitle>
                    El abono de saldo por medio de transferencia bancaria puede tomar hasta 72 horas en verse reflejado en la cuenta
                  </Alert>
                </Collapse>

                <ButtonArea>
                  <Button
                    variant='outlined'
                    onClick={() => setActualStep(0)}
                  >
                    Atrás
                  </Button>

                  <Button
                    variant='contained'
                    disabled={methodSelected === 0}
                    onClick={() => setActualStep(2)}
                  >
                    Siguiente
                  </Button>
                </ButtonArea>
              </StepContainer>
            </Step>

            <Step key='Resumen'>
              <StepContainer
                title='Resumen'
                actualStep={actualStep}
                setActualStep={setActualStep}
              >
                <PaymentResume
                  prePayment={prePayment}
                  amount={amount}
                  methodSelected={methodSelected}
                />

                <ButtonArea>
                  <Button
                    variant='outlined'
                    onClick={() => setActualStep(1)}
                  >
                    Atrás
                  </Button>

                  <Button
                    variant='contained'
                    onClick={() => setActualStep(3)}
                  >
                    Siguiente
                  </Button>
                </ButtonArea>
              </StepContainer>
            </Step>

            <Step key='Realizar pago'>
              <StepContainer
                title='Realizar pago'
                actualStep={actualStep}
                setActualStep={setActualStep}
              >
                {
                  {
                    1: (
                      <TransferPayment
                        amount={amount}
                        prePayment={prePayment}
                        setCostumerPrePayment={setCostumerPrePayment}
                        reference={reference}
                        setReference={setReference}
                        actualStep={actualStep}
                        setActualStep={setActualStep}
                      />
                    ),
                    2: (
                      <CardPayment 
                        amount={amount}
                        prePayment={prePayment}
                        setCostumerPrePayment={setCostumerPrePayment}
                        holderName={holderName}
                        cardNumber={cardNumber}
                        expirationMonth={expirationMonth}
                        expirationYear={expirationYear}
                        cvv2={cvv2}
                        setHolderName={setHolderName}
                        setCardNumber={setCardNumber}
                        setExpirationMonth={setExpirationMonth}
                        setExpirationYear={setExpirationYear}
                        setCvv2={setCvv2}
                        setCardType={setCardType}
                        setActualStep={setActualStep}
                      />
                    )
                  }[methodSelected]
                }
              </StepContainer>
            </Step>

            <Step key='Listo'>
              <StepContainer
                title='Listo'
                actualStep={actualStep}
                setActualStep={setActualStep}
              >
                <FinishedPayment
                  prePayment={prePayment}
                  costumerPrePayment={costumerPrePayment}
                  amount={amount}
                  holderName={holderName}
                  cardNumber={cardNumber}
                  expirationMonth={expirationMonth}
                  expirationYear={expirationYear}
                  cvv2={cvv2} 
                  cardType={cardType}
                  isWithTransfer={methodSelected === '1'}
                  isWithCard={methodSelected === '2'} 
                />
                {/* <CheckCircleOutline
                  sx={{ fontSize: 75 }}
                  color='success'
                />
                <Typography variant='body1'>¡Listo! Ya has terminado</Typography>

                {methodSelected === '1' && (
                  <Alert severity='info'>
                    <Typography variant='body1'>Tu transferencia está siendo procesada y será validada en un tiempo de máximo 72 horas</Typography>
                    <Typography variant='body1'>Si tienes alguna duda o necesitas asistencia puedes comunicarte con nosotros al 33 3803 2268, o mediante el correo ventas@entregable.com</Typography>
                  </Alert>
                )} */}

                <ButtonArea>
                  {/* <Button
                    variant='outlined'
                    onClick={getOperationSheet}
                  >
                    Descargar folio de operación
                  </Button> */}

                  <Button
                    variant='contained'
                    onClick={() => {
                      switch (methodSelected) {
                        case '2': 
                          const openPayInstance = document.querySelector('#openPayInstance')
                          const openPayAntiFraud = document.querySelector('#openPayAntiFraud')
                          const _op_data_r = document.querySelector('#_op_data_r')
                          const _op_data_antifraud = document.querySelector('#_op_data_antifraud')

                          openPayInstance.remove()
                          openPayAntiFraud.remove()
                          _op_data_r.remove()
                          _op_data_antifraud.remove()
                        break;
                      }

                      navigation(-1)
                    }}
                  >
                    Terminar
                  </Button>
                </ButtonArea>
              </StepContainer>
            </Step>
          </Stepper>
        </Stack>
      </Paper>
    </Container>
  )
}

const ButtonArea = ({ children }) => {
  return (
    <Stack
      direction='row'
      justifyContent='end'
      spacing={2}
      sx={{ marginTop: 2 }}
    >
      {children}
    </Stack>
  )
}

const StepContainer = memo(({ title, children }) => {
  return (
    <>
      <StepLabel>{title}</StepLabel>
      <StepContent>{children}</StepContent>
    </>
  )
})
