import React, { useState, useEffect } from 'react'
import Carousel from '../../Shared/Carousel'

export default function MyShoppingCart(props) {
  const [notices, setNotices] = useState([])
    const localhost = sessionStorage.getItem('localhost')
    const token = sessionStorage.getItem('token')

    const [loading, setLoading] = useState(false)

    async function getNotices() {
        try {
            setLoading(true)
            const request = await fetch(`${localhost}/api/v2/cart/notices`, {
                method: 'GET',
                headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
                }
            })
        
            if (request.ok) {
                const response = await request.json()
                setNotices(response)
            }
            setLoading(false)

        } catch (error) {
          
          console.error('Get profile:', error)
        }
      }
    useEffect(() => {
      getNotices()
    }, [])
    /*
    const notices = [
        {
          label: 'San Frncisco – Oakland Bay Bridge, United States',
          imgPath:
            'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
        },
        {
          label: 'Bird',
          imgPath:
            'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
        },
        {
          label: 'Bali, Indonesia',
          imgPath:
            'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
        },
        {
          label: 'Goč, Serbia',
          imgPath:
            'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
        },
      ];*/
    return(
      ((notices.length>0 && !loading)?
        <Carousel 
            title="Actualizaciones"
            images={notices}
            timeout="5000"
            />:<></>
        )
    )
}