/* Importaciones de elementos de React */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* Importaciones de elementos de MUI */
import {
    Grid,
    Container,
    Paper,
    Typography,
    CircularProgress,
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment
} from '@mui/material';
import { 
    circularProgressClasses
} from '@mui/material/CircularProgress';
import {
    Home,
    Send
} from '@mui/icons-material';

/* Importaciones de iconos de React-Icons */
import { 
    BsWindow 
} from "react-icons/bs";

/* Importaciones de componentes o elementos personalizados */
import './shippingStyles.css';
import ButtonLink from './MarcoComponents/ButtonLink';
import CardInfo from './MarcoComponents/CardInfo';
import AmountReceivableSummary from './MarcoComponents/AmountReceivableSummary';
import { whoIsWillPaying } from './MarcoComponents/AmountReceivableSummary';
import { objectJSON } from './MarcoComponents/FormModel';

function LoadingCatchedInformation(props){

    /* Estados */
    const [ waitAnResult , setWaitAnResult ] = useState( false );
    const [ loading , setLoading ] = useState(false);
    const [ sessionNumber , setSessionNumber ] = useState("Carrito_1");

    /* useNavigate */
    const navigate = useNavigate();
    
    /* Cargando elementos de SessionStorage y LocalStorage */
    const userData = JSON.parse( sessionStorage.getItem( "user" ) );
    const permissions = JSON.parse( sessionStorage.getItem( "permissions" ) );
    const sessions = JSON.parse( localStorage.getItem( "sessions" ) );

    /* Cargando URL según la modalidad de React Enviroment */
    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==='prod') ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV 
    }

    /* Objetos */
    /*const insuranceInfo = {
        subtotal : 1750.75,
        total : 2000.50,
        iva : 250.75
    };*/

    const cardInfoOrigin = {
        origenEmpresa : objectJSON.data.origin.origenEmpresa,
        origenPersona : `${ objectJSON.data.origin.origenPersona.nombre } ${ objectJSON.data.origin.origenPersona.apellidoPaterno } ${ objectJSON.data.origin.origenPersona.apellidoMaterno }`,
        origenCalle : objectJSON.data.origin.origenCalle,
        origenNumExt : objectJSON.data.origin.origenNumExt,
        origenNumInt : objectJSON.data.origin.origenNumInt,
        origenColonia : objectJSON.data.origin.origenColonia,
        origenMunicipio : objectJSON.data.origin.origenMunicipio,
        origenEstado : objectJSON.data.origin.origenEstado,
        origenPais : objectJSON.data.origin.origenPais,
        origenRef : objectJSON.data.origin.origenRef,
        origenCP : objectJSON.data.origin.origenCP,
        origenTelefono1 : objectJSON.data.origin.origenTelefono1,
        origenTelefono2 : objectJSON.data.origin.origenTelefono2
    };

    const cardInfoDestination = {
        destinoEmpresa : objectJSON.data.destination.destinoEmpresa,
        destinoPersona : `${ objectJSON.data.destination.destinoPersona.nombre } ${ objectJSON.data.destination.destinoPersona.apellidoPaterno } ${ objectJSON.data.destination.destinoPersona.apellidoMaterno }`,
        destinoCalle : objectJSON.data.destination.destinoCalle,
        destinoNumExt : objectJSON.data.destination.destinoNumExt,
        destinoNumInt : objectJSON.data.destination.destinoNumInt,
        destinoColonia : objectJSON.data.destination.destinoColonia,
        destinoMunicipio : objectJSON.data.destination.destinoMunicipio,
        destinoEstado : objectJSON.data.destination.destinoEstado,
        destinoPais : objectJSON.data.destination.destinoPais,
        destinoRef : objectJSON.data.destination.destinoRef,
        destinoCP : objectJSON.data.destination.destinoCP,
        destinoTelefono1 : objectJSON.data.destination.destinoTelefono1,
        destinoTelefono2 : objectJSON.data.destination.destinoTelefono2
    };

    /* Funciones para la interfaz de confirmación de datos */
    const showingTheCirculeProgress = () => {
        setLoading( !loading );
    };

    const catchSessionNumber = event => setSessionNumber( event.target.value );
    
    const resetQuoteObjectData = () => {
        objectJSON.data.origin.origenEmpresa = "";
        objectJSON.data.origin.origenPersona.nombre = "";
        objectJSON.data.origin.origenPersona.apellidoPaterno = "";
        objectJSON.data.origin.origenPersona.apellidoMaterno = "";
        objectJSON.data.origin.id_dom_origen = null;
        objectJSON.data.origin.origenCalle = "";
        objectJSON.data.origin.origenNumExt = "";
        objectJSON.data.origin.origenNumInt = "";
        objectJSON.data.origin.origenColonia = "";
        objectJSON.data.origin.origenMunicipio = "";
        objectJSON.data.origin.origenEstado = "";
        objectJSON.data.origin.origenPais = "";
        objectJSON.data.origin.origenRef = "";
        objectJSON.data.origin.origenCP = "";
        objectJSON.data.origin.origenTelefono1 = "";
        objectJSON.data.origin.origenTelefono2 = "";
        objectJSON.data.origin.isLoading = false;

        objectJSON.data.destination.destinoEmpresa = "";
        objectJSON.data.destination.destinoPersona.nombre = "";
        objectJSON.data.destination.destinoPersona.apellidoPaterno = "";
        objectJSON.data.destination.destinoPersona.apellidoMaterno = "";
        objectJSON.data.destination.id_dom_destino = null;
        objectJSON.data.destination.destinoCalle = "";
        objectJSON.data.destination.destinoNumExt = "";
        objectJSON.data.destination.destinoNumInt = "";
        objectJSON.data.destination.destinoColonia = "";
        objectJSON.data.destination.destinoMunicipio = "";
        objectJSON.data.destination.destinoEstado = "";
        objectJSON.data.destination.destinoPais = "";
        objectJSON.data.destination.destinoRef = "";
        objectJSON.data.destination.destinoCP = "";
        objectJSON.data.destination.destinoTelefono1 = "";
        objectJSON.data.destination.destinoTelefono2 = "";
        objectJSON.data.destination.isLoading = false;

        objectJSON.data.kilosServiceAndNumber.numero = "";
        objectJSON.data.kilosServiceAndNumber.tipo_servicio_id = 0;
        objectJSON.data.kilosServiceAndNumber.kg = 1.0;
        objectJSON.data.kilosServiceAndNumber.alto = 1;
        objectJSON.data.kilosServiceAndNumber.ancho = 1;
        objectJSON.data.kilosServiceAndNumber.largo = 1;

        objectJSON.data.whoIsPaying.montoACobrar = 0;
        objectJSON.data.whoIsPaying.costoDeEnvio = 1.0;
        objectJSON.data.whoIsPaying.valorDeMercancia = 0.0;
        objectJSON.data.whoIsPaying.origenDestino = "Origen";

        objectJSON.data.isQuote = false;

        whoIsWillPaying.willPay = "Origen";
        whoIsWillPaying.icon = <Home fontSize = "small" sx = {{ color : "#ff4500" }} />;
    };

    const addNewQuoteToASession = () => {
        /* Asignando valores a ciertas propiedades del objeto principal */
        objectJSON.data.user.user_id = userData.id;
        objectJSON.data.waybillGeneratedByUser.generadoPorUsuario = 1;

        /* Extrayendo las propiedades de cada objeto, para que sea un solo objeto */
        const quote = {
            ...objectJSON.data.kilosServiceAndNumber,
            ...objectJSON.data.origin,
            ...objectJSON.data.destination,
            ...objectJSON.data.whoIsPaying,
            ...objectJSON.data.user,
            ...objectJSON.data.waybillGeneratedByUser
        };

        /* Reacomodando el nombre de persona de origen y destino */
        quote.origenPersona = ( ! (  objectJSON.data.origin.origenPersona.apellidoMaterno === '' ) ) 
        ? ( `${ objectJSON.data.origin.origenPersona.nombre } ${ objectJSON.data.origin.origenPersona.apellidoPaterno } ${ objectJSON.data.origin.origenPersona.apellidoMaterno }` ) 
        : ( `${ objectJSON.data.origin.origenPersona.nombre } ${ objectJSON.data.origin.origenPersona.apellidoPaterno }`);
        quote.destinoPersona = ( ! ( objectJSON.data.destination.destinoPersona.apellidoMaterno === '' ) )
        ? ( `${ objectJSON.data.destination.destinoPersona.nombre } ${ objectJSON.data.destination.destinoPersona.apellidoPaterno } ${ objectJSON.data.destination.destinoPersona.apellidoMaterno }` )
        : ( `${ objectJSON.data.destination.destinoPersona.nombre } ${ objectJSON.data.destination.destinoPersona.apellidoPaterno }` );

        /* Si no existe aun el elemento de sessions o se encuentra vacío */
        if( sessions === null || sessions.length === 0 || sessions === "" ){
            localStorage.setItem( "sessions" , JSON.stringify([
                {
                    Sesion_1 : {
                        waybills : [ quote ]
                    }
                }
            ]) );

            /* Se resetea el objeto principal de cotizaciones */
            resetQuoteObjectData();

            /* Redireccionando al modulo de mi carrito */
            navigate("/MyShoppingCart");
            return;
        }

        /* Sí existe y tiene elementos el arreglo de sessions de localStorage */
        sessions.forEach( session => {
            if( session[ sessionNumber ] ){
                session[ sessionNumber ]["waybills"].push( quote );
            }
        } );

        /* Se actualiza nuestro elemento de sessions en localStorage,
           se reseteara el objeto principal de cotización y se redireccionara al modulo de mi carrito */
        localStorage.setItem( "sessions" , JSON.stringify( sessions ) );
        resetQuoteObjectData();
        navigate("/MyShoppingCart");
    };

    const sendInformationToDB = async () => {
        setWaitAnResult( true );
        showingTheCirculeProgress();
        objectJSON.data.user.user_id = userData.id;
        objectJSON.data.waybillGeneratedByUser.generadoPorUsuario = 1;

        const newObjectJSON = {
            data : {
                    waybills : [{
                                ...objectJSON.data.kilosServiceAndNumber,
                                ...objectJSON.data.origin,
                                ...objectJSON.data.destination,
                                ...objectJSON.data.whoIsPaying,
                                ...objectJSON.data.user,
                                ...objectJSON.data.waybillGeneratedByUser
                    }],
                    isThereWaybillMassive : false
            }
        };

        delete newObjectJSON.data.waybills[0].id;
        newObjectJSON.data.waybills[0].origenPersona = ( ! (  objectJSON.data.origin.origenPersona.apellidoMaterno === '' ) ) 
        ? ( `${ objectJSON.data.origin.origenPersona.nombre } ${ objectJSON.data.origin.origenPersona.apellidoPaterno } ${ objectJSON.data.origin.origenPersona.apellidoMaterno }` ) 
        : ( `${ objectJSON.data.origin.origenPersona.nombre } ${ objectJSON.data.origin.origenPersona.apellidoPaterno }`);
        newObjectJSON.data.waybills[0].destinoPersona = ( ! ( objectJSON.data.destination.destinoPersona.apellidoMaterno === '' ) )
        ? ( `${ objectJSON.data.destination.destinoPersona.nombre } ${ objectJSON.data.destination.destinoPersona.apellidoPaterno } ${ objectJSON.data.destination.destinoPersona.apellidoMaterno }` )
        : ( `${ objectJSON.data.destination.destinoPersona.nombre } ${ objectJSON.data.destination.destinoPersona.apellidoPaterno }` );

        await fetch(`${envConfig.url}/api/v2/waybills` , {
            method : "POST",
            headers : {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                'Cache-Control': 'no-cache'
            },
            body : JSON.stringify( newObjectJSON )
        } )
            .then((respuesta)=>{
                if(respuesta.ok){
                    return respuesta.json();
                }else{
                    alert("Hubo un problema con la petición, intente de nuevo en unos momentos");
                }
            })
            .then( data => { 
                resetQuoteObjectData();
                alert(`El registro de la guia ha sido guardado con exito.\n Numero de Guia: ${ JSON.stringify( data.numero ) }`);
                navigate('/waybillsTable');
            } )
            .catch( error => alert('Algo ocurrio :C ' + error ) );
        
        showingTheCirculeProgress();
        setWaitAnResult( false );
    };

    return(
        <>
            <Container sx={{ marginBottom: 2 , textAlign: "center" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CardInfo 
                            icon = { <Home fontSize = "large"/> }
                            title = "Origen"
                            information = { cardInfoOrigin }
                            url = "/originOrDestination"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CardInfo 
                            icon = { <Send fontSize = "large"/> }
                            title = "Destino"
                            information = { cardInfoDestination }
                            url = "/originOrDestination"
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ marginBottom: 2 , textAlign: "center" }}>
                <Grid container spacing={3}>
                    <Grid item xs = {12} md = {5}>
                        <AmountReceivableSummary />
                    </Grid>

                    {
                        permissions["role"] === "PDV" && 
                        (   
                            sessions !== null &&
                            sessions.length !== 0 &&
                            sessions !== ""
                        )  ?
                            <Grid 
                                item 
                                xs = {12} 
                                md = {2} 
                                display = "flex" 
                                alignItems = "center" 
                                justifyContent = "center"
                            >
                                <FormControl 
                                    variant = "outlined" 
                                >
                                    <InputLabel
                                        htmlFor = "Carritos"
                                    >
                                        Carritos
                                    </InputLabel>

                                    <Select
                                        autoFocus
                                        label = "Carritos"
                                        value = { sessionNumber }
                                        onChange = { catchSessionNumber }
                                        size = "small"
                                        sx = {{ 
                                            fontSize : "14px",
                                            fontWeight : "700"
                                        }}
                                        startAdornment = {
                                            <InputAdornment position = "start">
                                                <BsWindow 
                                                    color = "#ff4500"
                                                    fontWeight= "700"
                                                    size = "1.40rem"
                                                />
                                            </InputAdornment>
                                        }
                                    >
                                        {
                                            sessions.map( ( session , index ) => (
                                                <MenuItem 
                                                    value = {`Carrito_${ index + 1 }`}
                                                    sx = {{
                                                        fontSize : "14px",
                                                        fontWeight : "700"
                                                    }}
                                                >
                                                    Carrito { index + 1 }
                                                </MenuItem>
                                            ) )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        : <></>
                    }

                    <Grid 
                        item 
                        xs={12} 
                        md={1.2} 
                        display="flex" 
                        alignItems="center" 
                        justifyContent = "center"
                    > 
                        <Button
                            sx = {{ textTransform : "none" }}
                            variant = "contained"
                            onClick = { permissions["role"] === "PDV" ? addNewQuoteToASession : sendInformationToDB }
                            disabled = { waitAnResult }
                        >
                            Confirmar
                        </Button>
                    </Grid> 

                    {
                        permissions["role"] !== "PDV" ?
                            <Grid item xs={12} md={5}>
                                {
                                    ( loading ) ? <>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px"
                                            }}
                                        >
                                            <Box>
                                                <CircularProgress 
                                                    variant = "indeterminate"
                                                    disableShrink
                                                    size = {40}
                                                    sx = {{
                                                        animationDuration : "550ms",
                                                        [`& .${circularProgressClasses.circle}`] : {
                                                            strokeLinecap : "round" 
                                                        }
                                                    }}
                                                />
                                            </Box>
                                            <Typography
                                                variant = "body2"
                                                fontWeight = "bold"
                                            >
                                                Enviando información, espere un momento...
                                            </Typography>
                                        </div>
                                    </> : <></>
                                }
                            </Grid>
                        : <></>
                    }
                </Grid>
            </Container>
        </>
    );
}

export default function PaymentConfirmation(){

    return(
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ marginY: 5 , paddingX: 5 , paddingY: 5 }}>
                {/* Encabezado */}
                <Container sx={{ marginBottom: 2, textAlign: "left" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Typography variant="h5">
                                Confirmación de pago y de datos
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <div align="right">
                                <ButtonLink 
                                    url = { objectJSON.data.isQuote ? "/originOrDestination" : "/methodsOfPayment"}
                                    clases = ""
                                    texto = "Regresar"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <LoadingCatchedInformation />

            </Paper>
        </Container>
    );
}
