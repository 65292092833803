import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material'

/**
 * @typedef {object} Options
 * @property {string} label
 * @property {string} value
 */

/**
 * @typedef {object} ComponentParams
 * @property {[any, React.SetStateAction<undefined>]} state
 * @property {string} label
 * @property {Options[]|string[]} options
 * @property {boolean} [disabled = false]
 */

/**
 *
 * @param {ComponentParams} params
 * @returns
 */

export default function Selector(params) {
  const { state, label, options, sx, callback, disabled } = params
  const [getter, setter] = state
  const isObject = typeof options[0] === 'object'

  const handleChange = event => {
    setter(event.target.value)
    if (typeof callback === 'function') callback()
  }

  return (
    <Box sx={{ minWidth: 120, ...sx }}>
      <FormControl
        fullWidth
        variant='outlined'
      >
        <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={getter}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {isObject ? options.map(item => <MenuItem value={item?.value}>{item?.label}</MenuItem>) : options.map(item => <MenuItem value={item}>{item}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  )
}
