import { Collapse, Grid, Stack, TextField, Typography } from '@mui/material'
import { SelectionButton } from './CreditMovementsSharedComponents'


export default function AmountSelection({ amountSelected, setAmountSelected, amount, setAmount, setPrePaymentSelected, setPrePayment }) {
  function optionSelected(value) {
    setPrePaymentSelected(0)
    setPrePayment(null)

    if (value === amountSelected) return

    setAmountSelected(value)

    switch (value) {
      case 'A':
        setAmount(500)
        break

      case 'B':
        setAmount(800)
        break

      case 'C':
        setAmount(1000)
        break

      case 'D':
        setAmount(2000)
        break

      case 'E':
        setAmount(3000)
        break

      default:
        break
    }
  }

  return (
    <Stack
      direction='column'
      useFlexGap
      spacing={2}
    >
      <Typography variant='body1'>Primero, selecciona el monto que desees abonar a tu cuenta</Typography>

      <SelectionButton
        title='$500'
        value='A'
        selected={amountSelected}
        onSelect={optionSelected}
      />

      <SelectionButton
        title='$800'
        value='B'
        selected={amountSelected}
        onSelect={optionSelected}
      />
  
      <SelectionButton
        title='$1000'
        value='C'
        selected={amountSelected}
        onSelect={optionSelected}
      />

      <SelectionButton
        title='$2000'
        value='D'
        selected={amountSelected}
        onSelect={optionSelected}
      />

      <SelectionButton
        title='Otra cantidad'
        value='E'
        selected={amountSelected}
        onSelect={optionSelected}
      />
      
      <Collapse in={amountSelected === 'E'}>
        <TextField
          sx={{ marginTop: 2 }}
          label='Monto a abonar'
          variant='outlined'
          value={amount}
          onChange={event => {
            const regex = /^\d{0,6}(\.\d{0,2})?$/

            if (regex.test(event.target.value)) {
              setAmount(event.target.value)
            }
          }}
          // onChange={event => setAmount(Number(event.target.value))}
        />
      </Collapse>
    </Stack>
  )
}
