import React, { useState, useEffect } from 'react';

import {
    List,
    ListItem
} from '@mui/material';

import WaybillCard from './WaybillCard';

export default function GuiasFormAddGroup(props) {
    const {
            listItems,
            setListItems,
        } = props;

    /* Estados */
    const [ itemsToShow , setItemsToShow ] = useState([]);
    
    /* Funciones para el componente */
    const removeItem = (value)=>{
        let items = listItems
        items = items.filter((item)=>item.numero!==value)
        setListItems(items);
    }

    /* useEffect para actualizar nuestro componente */
    useEffect(()=>{
        setItemsToShow(listItems.slice(listItems.length-10).reverse())
    },[listItems])

    return(
        <List 
            sx={{ 
                width: '100%', 
                bgcolor: 'background.paper' ,
                position: 'relative',
                overflow: 'auto',
                height: "100%",
            }}
        >
            Ultimas 10
            {itemsToShow.map((value) => (
                <ListItem
                    key = { value.numero }
                    disableGutters
                >
                    <WaybillCard 
                        waybill = { value }
                        removeItem = { () => removeItem( value.numero ) }
                    />
                </ListItem>
            ))}
        </List>
    )
}