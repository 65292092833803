import { get, post } from '../../../utils/requests'

export async function getUsers(company) {
  try {
    const response = await get('/api/v2/users', { company })

    if (!response.ok) return { error: true }

    const { users } = await response.json()
    return {
      error: false,
      users
    }
  } catch (error) {
    return { error: true }
  }
}

export async function getCompanies() {
  try {
    const response = await get('api/v2/companies')
    if (!response.ok) return { error: true }
    const { companies } = await response.json()
    return {
      error: false,
      companies
    }
  } catch (error) {
    return { error: true }
  }
}
/**
 *
 * @param {import('./UserForm').User} user
 * @returns
 */
export async function updateUser(user) {
  try {
    const name = [user.firstName, user.lastName, user.secondLastName].join(' ')
    const param = { ...user, name, enabled: user.es_activo }
    const response = await post(param, '/api/v2/users', 'PATCH')
    return { error: !response.ok }
  } catch (error) {
    return { error: true }
  }
}

/**
 *
 * @param {import('./UserForm').User} user
 * @returns
 */
export async function createUser(user) {
  try {
    const name = [user.firstName, user.lastName, user.secondLastName].join(' ')
    const response = await post({ ...user, name }, '/api/v2/users', 'POST')
    return { error: !response.ok }
  } catch (error) {
    return { error: true }
  }
}
