import { useTheme } from '@emotion/react'
import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import ShoppingCartContext from './ShoppingCartContext'
import { ItemCollapse, PendingPay } from './ShoppingCartSharedComponents'
import { CategoryOutlined, LocalShippingOutlined, ReceiptLongOutlined, PendingActionsOutlined } from '@mui/icons-material'

export default function CartDetails() {
  const { carts, actualCart } = useContext(ShoppingCartContext)
  const cart = carts[actualCart]

  return (
    <Stack spacing={2}>
      {cart.waybills && cart.waybills.length > 0 && (
        <Section
          title='Cotizaciones'
          icon={<ReceiptLongOutlined sx={{ fontSize: 18 }} />}
        >
          {cart.waybills.map((item, index) => (
            <>
              <ItemCollapse
                data={item}
                index={index}
                key={index}
                type='waybills'
              />
            </>
          ))}
        </Section>
      )}

      {cart.products && cart.products.length > 0 && (
        <Section
          title='Productos'
          icon={<CategoryOutlined sx={{ fontSize: 18 }} />}
        >
          {cart.products.map((item, index) => (
            <>
              <ItemCollapse
                data={item}
                index={index}
                key={index}
                type='products'
              />
            </>
          ))}
        </Section>
      )}

      {cart.deliveries && cart.deliveries.length > 0 && (
        <Section
          title='Entregas'
          icon={<LocalShippingOutlined sx={{ fontSize: 18 }} />}
        >
          {cart.deliveries.map((item, index) => (
            <>
              <ItemCollapse
                data={item}
                index={index}
                key={index}
                type='deliveries'
              />
            </>
          ))}
        </Section>
      )}

      {cart.pendingPays && cart.pendingPays.length > 0 && (
        <Section
          title='Pagos pendientes'
          icon={<PendingActionsOutlined sx={{ fontSize: 18 }} />}
        >
          {cart.pendingPays.map((pendingPay, index) => (
            <>
              <PendingPay 
                pendingPay={pendingPay}
                index={index}
                type='pendingPays'
                isDeletable={true}
                isDownloadable={false}
              />
            </>
          ))}
        </Section>
      )}
    </Stack>
  )
}

const Section = ({ icon, title, children }) => {
  const theme = useTheme()

  return (
    <Card
      variant='outlined'
      sx={{ height: '100%' }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Stack
            direction='row'
            spacing={1}
            alignContent='center'
          >
            <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
            <Typography variant='subtitle1'>{title}</Typography>
          </Stack>
          <Stack>{children}</Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
