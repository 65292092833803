import { Container, Paper } from '@mui/material'
import Copyright from '../General/Copyright'
import ErrorBoundary from '../General/ErrorBoundary'

export default function MainContainer({ children }) {
  return (
    <Container
      maxWidth='lg'
      sx={{ mt: 4, mb: 4, textAlign: 'left' }}
    >
      <Paper
        sx={{ p: 2 }}
        variant='outlined'
      >
        {children}
      </Paper>

      <Paper sx={{ p: 2, mt: 2 }}>
        <Copyright />
      </Paper>
    </Container>
  )
}
