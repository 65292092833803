import React, {useState, useEffect} from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, CardContent, Grid, Stack, Typography, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function QAFrequents() {
    const theme = useTheme()

    const [questions, setQuestions] = useState([])
    const localhost = sessionStorage.getItem('localhost')
    const token = sessionStorage.getItem('token')

    const [loading, setLoading] = useState(false)

    async function getQuestions() {
        try {
            setLoading(true)
            const request = await fetch(`${localhost}/api/v2/support/qa`, {
                method: 'GET',
                headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
                }
            })
        
            if (request.ok) {
                const response = await request.json()
                setQuestions(response)
            }
            setLoading(false)
            
        } catch (error) {
          
          console.error('Get profile:', error)
        }
      }
    useEffect(() => {
        getQuestions()
    }, [])
    /*
    const accordionData = [
        {
          id: 1,
          title: 'Accordion 1',
          content: 'Contenido 1',
        },
        {
          id: 2,
          title: 'Accordion 2',
          content: 'Contenido 2',
        },
        {
            id: 3,
            title: 'Accordion 3',
            content: 'Contenido 3',
          },
          {
            id: 4,
            title: 'Accordion 4',
            content: 'Contenido 4',
          },
          {
            id: 5,
            title: 'Accordion 5',
            content: 'Contenido 5',
          }
        
    ];*/

    return(
        <Grid marginTop={2}>
            {questions.map((section) => (
                <Card
                    key={section.id}
                    variant='outlined'
                    sx={{ height: '100%', marginBottom: 2 }}
                >
                    <CardContent>
                        <Stack 
                            spacing={1}
                            direction='row'
                            alignContent='center'
                        >
                            <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>
                                {/*section.icon*/}
                            </Avatar>
                            <Typography variant='subtitle1'>{section.title}</Typography>
                            {/*<Typography>Accordion 1</Typography>*/}
                        </Stack>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${section.id}-content`}
                                id={`panel${section.id}-header`}
                            >
                            <Typography>{section.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                {section.content}
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>
                </Card>
            ))}
        </Grid>
    )
    
}