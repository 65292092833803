import React, { useState, useEffect } from 'react'

import { Grid, ButtonBase, List, ListItem, Stack } from '@mui/material'

import { PrePaymentCard } from '../../Shared/index'

export default function ListPrePayments({ prePayments, setPrePayments, prePaymentSelected, setPrePaymentSelected, setPrePayment, setAmountSelected, setAmount }) {
  const envConfig = {
    url: process.env.REACT_APP_ENVIROMENT === 'prod' ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
  }

  async function getPrePayments() {
    try {
      const request = await fetch(`${envConfig.url}/api/v2/prePayments`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        }
      })

      if (request.ok) {
        const response = await request.json()
        setPrePayments(response.prePayments)
        return
      }

      switch (request.status) {
        case 500:
          throw new Error('Hubo un problema en obtener los paquetes, recargue nuevamente la página. Si el problema persiste, contacte con soporte técnico.')
        default:
          throw new Error('Hubo un problema en obtener los paquetes, recargue nuevamente la página. Si el problema persiste, contacte con soporte técnico.')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    if (prePayments.length === 0) getPrePayments()
  }, [])

  return (
    <Stack
      direction='row'
      overflow='auto'
      sx={theme => ({
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.between('xs', 'sm')]: {
          maxWidth: 305
        }
      })}
    >
      <List
        sx={{
          display: 'flex',
          gap: 0.2
        }}
      >
        {prePayments.map((prePayment, index) => (
          <ListItem>
            <ButtonBase
              sx={{
                padding: 1,
                border: prePaymentSelected === (index + 1) ? '5px solid #0068ff' : '',
                borderRadius: 1
              }}
              onClick={() => {
                setPrePaymentSelected(index + 1)
                setPrePayment(prePayment)
                setAmountSelected('')
                setAmount(prePayment.costo_del_paquete)
              }}
            >
              <PrePaymentCard
                data={prePayment}
              />
            </ButtonBase>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}
