import { CloseOutlined, NearMeOutlined, PlaceOutlined, SignalWifiConnectedNoInternet4Outlined } from '@mui/icons-material'
import { Alert, Avatar, Box, Button, ButtonBase, Card, CardContent, CircularProgress, ClickAwayListener, Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

export default function QuoteForm({ addresses, getAddresses, recipient, setRecipient, hasRecipientSelected, setHasRecipientSelected, sender, setSender, hasSenderSelected, setHasSenderSelected, packet, setPacket, shipment, setShipment, setValidatedForm }) {
  function validateNumber(value) {
    const regex = /^\d{0,8}(\.\d{0,2})?$/
    return regex.test(value)
  }

  useEffect(() => {
    if (packet.esSobre === 1) {
      // packet.peso = 1
      packet.alto = 1
      packet.ancho = 1
      packet.largo = 1
    }
  }, [packet.esSobre])

  useEffect(() => {
    let validated = true

    if (!/^\d{5}$/.test(sender.codigoPostal)) {
      validated = false
    }

    if (!/^\d{5}$/.test(recipient.codigoPostal)) {
      validated = false
    }

    if (/^\s*(\.\s*)?$/.test(shipment.mercancia)) {
      validated = false
    }

    if (Number(packet.peso) <= 0 || /^\s*(\.\s*)?$/.test(packet.peso)) {
      validated = false
    }

    if (packet.esSobre === 0) {
      if (Number(packet.alto) <= 0 || /^\s*(\.\s*)?$/.test(packet.alto)) {
        validated = false
      }

      if (Number(packet.ancho) <= 0 || /^\s*(\.\s*)?$/.test(packet.ancho)) {
        validated = false
      }

      if (Number(packet.largo) <= 0 || /^\s*(\.\s*)?$/.test(packet.largo)) {
        validated = false
      }
    }

    setValidatedForm(validated)
  }, [sender.codigoPostal, recipient.codigoPostal, shipment.mercancia, packet.esSobre, packet.alto, packet.ancho, packet.largo, packet.peso])

  return (
    <>
      <Stack
        spacing={2}
        useFlexGap
      >
        <Typography variant='body1'>Introduce el código postal de origen y destino</Typography>

        <AddressesSearch
          label='Código postal de origen'
          addresses={addresses}
          address={sender}
          setAddress={setSender}
          hasSelected={hasSenderSelected}
          setHasSelected={setHasSenderSelected}
          reloadAddresses={() => getAddresses()}
          avatar={<PlaceOutlined />}
        />

        <AddressesSearch
          label='Código postal de destino'
          addresses={addresses}
          address={recipient}
          setAddress={setRecipient}
          hasSelected={hasRecipientSelected}
          setHasSelected={setHasRecipientSelected}
          reloadAddresses={() => getAddresses()}
          avatar={<NearMeOutlined />}
        />

        <Typography variant='body1'>Danos más detalles sobre el paquete</Typography>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormControl fullWidth>
              <InputLabel id='packet-selector-label'>Tipo de envío</InputLabel>
              <Select
                value={packet.esSobre}
                label='Tipo de envío'
                onChange={event => setPacket({ ...packet, esSobre: event.target.value })}
              >
                <MenuItem value={1}>Sobre</MenuItem>
                <MenuItem value={0}>Paquete</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              variant='outlined'
              value={shipment.mercancia}
              onChange={event => {
                const value = event.target.value
                if (validateNumber(value)) {
                  setShipment({ ...shipment, mercancia: value })
                }
              }}
              label='Valor de la mercancía'
              type='text'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>
              }}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              variant='outlined'
              value={packet.peso}
              onChange={event => {
                const value = event.target.value
                if (validateNumber(value)) {
                  setPacket({ ...packet, peso: value })
                }
              }}
              label='Peso del paquete'
              InputProps={{
                endAdornment: <InputAdornment position='end'>kg</InputAdornment>
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Stack>

      <Collapse in={packet.esSobre === 0}>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: 1 }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              variant='outlined'
              value={packet.alto}
              onChange={event => {
                const value = event.target.value
                if (validateNumber(value)) {
                  setPacket({ ...packet, alto: value })
                }
              }}
              label='Altura del paquete'
              InputProps={{
                endAdornment: <InputAdornment position='end'>cm</InputAdornment>
              }}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              variant='outlined'
              value={packet.ancho}
              onChange={event => {
                const value = event.target.value
                if (validateNumber(value)) {
                  setPacket({ ...packet, ancho: value })
                }
              }}
              label='Ancho del paquete'
              InputProps={{
                endAdornment: <InputAdornment position='end'>cm</InputAdornment>
              }}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              variant='outlined'
              value={packet.largo}
              onChange={event => {
                const value = event.target.value
                if (validateNumber(value)) {
                  setPacket({ ...packet, largo: value })
                }
              }}
              label='Largo del paquete'
              InputProps={{
                endAdornment: <InputAdornment position='end'>cm</InputAdornment>
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Alert
          severity='info'
          sx={{ marginTop: 2 }}
        >
          Los valores decimales serán redondeados a su entero superior más próximo
        </Alert>
      </Collapse>
    </>
  )
}

const AddressesSearch = ({ label, addresses, address, setAddress, hasSelected, setHasSelected, reloadAddresses, avatar }) => {
  const [open, setOpen] = useState(false)
  const [filtered, setFiltered] = useState([])
  const theme = useTheme()

  useEffect(() => {
    setFiltered(addresses)
  }, [addresses])

  function filterAddresses(event) {
    setAddress({ ...address, codigoPostal: event.target.value })

    const adds = addresses?.filter(item => {
      const entry = event.target.value
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

      const fullName = `${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno}`
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

      const company =
        item.empresa !== null
          ? item.empresa
              .trim()
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          : ''

      const street = `${item.calle}`
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

      const postalZip = item.codigoPostal
      const phone = item.telefono1
      const altPhone = item.telefono2 ?? ''

      if (fullName.includes(entry) || company.includes(entry) || postalZip.includes(entry) || phone.includes(entry) || altPhone.includes(entry) || street.includes(entry)) {
        return true
      }
    })

    setFiltered(adds)
  }

  if (hasSelected === true) {
    return (
      <Stack
        direction='row'
        spacing={2}
      >
        <Avatar sx={{ mt: 1, bgcolor: theme.palette.primary.main }}>{avatar}</Avatar>

        <Card
          variant='outlined'
          sx={{ width: '100%' }}
        >
          <CardContent sx={{ textAlign: 'left' }}>
            <Grid container>
              <Grid
                item
                xs
              >
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 'bold' }}
                >
                  {address.empresa ? `${address.empresa} - ` : ''}
                  {`${address.nombre} ${address.apellidoPaterno} ${address.apellidoMaterno ?? ''}`.trim()}
                </Typography>

                <Typography variant='body1'>
                  {address.calle} {address.numeroExterior} {address.numeroInterior ? `int. ${address.numeroInterior}` : ''}
                </Typography>

                <Typography variant='body1'>
                  {address.colonia}, {address.municipio}. {address.codigoPostal}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  onClick={() => {
                    setAddress({})
                    setHasSelected(false)
                  }}
                >
                  <CloseOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Stack>
    )
  }

  const Viewport = () => {
    if (filtered?.length > 0) {
      return (
        <Stack spacing={1}>
          {filtered?.map(item => (
            <Card
              key={item.id.toString()}
              variant='outlined'
              onClick={() => {
                setAddress(item)
                setHasSelected(true)
                setOpen(false)
                setFiltered(addresses)
              }}
            >
              <ButtonBase sx={{ width: '100%' }}>
                <CardContent sx={{ width: '100%', textAlign: 'left' }}>
                  <Typography
                    variant='body1'
                    sx={{ fontWeight: 'bold' }}
                  >
                    {item.empresa ? `${item.empresa} - ` : ''}
                    {`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno ?? ''}`.trim()}
                  </Typography>
                  <Typography variant='body1'>
                    {item.calle} {item.numeroExterior} {item.numeroInterior ? `int. ${item.numeroInterior}` : ''}
                  </Typography>
                  <Typography variant='body1'>
                    {item.colonia}, {item.municipio}. {item.codigoPostal}
                  </Typography>
                </CardContent>
              </ButtonBase>
            </Card>
          ))}
        </Stack>
      )
    }

    if (addresses === null) {
      return (
        <Stack
          alignItems='center'
          spacing={1}
        >
          <SignalWifiConnectedNoInternet4Outlined sx={{ fontSize: 40 }} />
          <Typography>Tenemos problemas para recuperar tus domicilios guardados</Typography>
          <Button
            variant='outlined'
            onClick={() => reloadAddresses()}
          >
            Volver a intentar
          </Button>
        </Stack>
      )
    }

    return (
      <Stack
        alignItems='center'
        spacing={1}
      >
        <CircularProgress sx={{ fontSize: 40 }} />
        <Typography>Obteniendo tus domicilios guardados</Typography>
      </Stack>
    )
  }

  return (
    <Stack
      direction='row'
      spacing={2}
    >
      <Avatar sx={{ mt: 1, bgcolor: theme.palette.primary.main }}>{avatar}</Avatar>

      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box sx={{ position: 'relative', width: '100%' }}>
          <TextField
            variant='outlined'
            label={label}
            placeholder='También puedes buscar por nombre, empresa, teléfono o código postal'
            value={address?.codigoPostal}
            onChange={filterAddresses}
            onFocus={() => setOpen(true)}
            onKeyDown={e => {
              if (e.key === 'Tab') setOpen(false)
            }}
            sx={{ zIndex: 0 }}
            inputProps={{
              maxLength: 30
            }}
            fullWidth
          />
          {open && filtered?.length !== 0 && (
            <Paper
              elevation={10}
              sx={{ p: 2, position: 'absolute', zIndex: 10, maxHeight: 175, width: '100%', overflow: 'auto' }}
            >
              <Viewport />
            </Paper>
          )}
        </Box>
      </ClickAwayListener>
    </Stack>
  )
}
