import React , { useState , useEffect } from 'react';
import {
    Grid,
    Button,
    Modal,
    TextField,
    Container,
    Paper,
    Typography
} from '@mui/material';
/*import {
    HomeWork,
    Search
} from '@mui/icons-material';

import AddressModal from './AddressModal';*/

export const objectJSON = require( '../originAndDestinationData.json' );

export default function FormModel(props){
    const {
        open,
        handleClose,
        isOriginOrDestination,
    } = props;

    /* Estados para nuestro formulario */
    const [ nombre , setNombre ] = useState('');
    const [ apellidoPaterno , setApellidoPaterno ] = useState('');
    const [ apellidoMaterno , setApellidoMaterno ] = useState('');
    const [ empresa , setEmpresa ] = useState('');
    const [ calle , setCalle ] = useState('');
    const [ noExt , setNoExt ] = useState('');
    const [ noInt , setNoInt ] = useState('');
    const [ colonia , setColonia ] = useState('');
    const [ estado , setEstado ] = useState('');
    const [ municipio , setMunicipio ] = useState('');
    const [ pais , setPais ] = useState('');
    const [ cp , setCp ] = useState('');
    const [ referencia , setReferencia ] = useState('');
    const [ telefono1 , setTelefono1 ] = useState('');
    const [ telefono2 , setTelefono2 ] = useState('');
    const [ direccionID , setDireccionID ] = useState(null);
    const [ hayDatos, setHayDatos ] = useState(false);

    /* Estado para ejecutar la funcion de showData con useEffect */
    const [ access , setAccess ] = useState( true );

    /* Estado para abrir y cerrar el modal de direcciones */
    // const [ openAddressModal , setOpenAddressModal ] = useState( false );

    /* Objeto de estilos para agregar scroll al formulario */
    const styles = {
        marginY: 5, 
        paddingX: 5, 
        paddingY: 5,
        position: 'absolute',
        transform: 'translate(-50%,-5,0%)',
        overflowY: 'scroll',
        width: '80%',
        height: '89%',
        border: '2px solid #efeff0',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3
    };

    /* Funciones para cambiar el valor de nuestro estados */
    const catchNombre = ( event ) => setNombre( event.target.value );
    const catchApellidoPaterno = ( event ) => setApellidoPaterno( event.target.value );
    const catchApellidoMaterno = ( event ) => setApellidoMaterno( event.target.value );
    const catchEmpresa = ( event ) => setEmpresa( event.target.value );
    const catchCalle = ( event ) => setCalle( event.target.value );
    const catchNoExt = ( event ) => setNoExt( event.target.value );
    const catchNoInt = ( event ) => setNoInt( event.target.value );
    const catchColonia = ( event ) => setColonia( event.target.value );
    const catchEstado = ( event ) => setEstado( event.target.value );
    const catchMunicipio = ( event ) => setMunicipio( event.target.value );
    const catchPais = ( event ) => setPais( event.target.value );
    const catchCp = ( event ) => { 
        setCp( event.target.value ); 
        
        if( isNaN( event.target.value ) ){
            setCp('');
        }
    }
    const catchReferencia = ( event ) => setReferencia( event.target.value );

    const catchTelefono1 = ( event ) => setTelefono1( event.target.value.replace( /[^0-9]/g , "" ) );

    const catchTelefono2 = ( event ) => setTelefono2( event.target.value.replace( /[^0-9]/g , "" ) );

    /* Funciones para nuestro modal de direcciones */
    // const handleOpenAddressModal = () => {
    //     setOpenAddressModal( true );
    // }

    // const handleCloseAddressModal = () => {
    //     setOpenAddressModal( false );
    // }

    // const selectingAndLoadingAnAddress = ( address ) => {
    //     setDireccionID( address.id );
    //     setNombre( address.nombre );
    //     setApellidoPaterno( address.apellidoPaterno );
    //     setApellidoMaterno( ( address.apellidoMaterno !== null ) ? address.apellidoMaterno : '' );
    //     setEmpresa( ( address.empresa !== null ) ? address.empresa : '' );
    //     setCalle( address.calle );
    //     setNoExt( address.numeroExterior );
    //     setNoInt( ( address.numeroInterior !== null ) ? address.numeroInterior : '' );
    //     setColonia( address.colonia );
    //     setEstado( address.estado );
    //     setMunicipio( address.municipio );
    //     setPais( address.pais );
    //     setCp( address.codigoPostal );
    //     setReferencia(  ( address.referencia !== null ) ? address.referencia : ''  );
    //     setTelefono1( address.telefono1 );
    //     setTelefono2(  ( address.telefono2 !== null ) ? address.telefono2 : ''  );
    // }

    /* Funciones de las acciones del Modal */
    function cancel(){
        /* Se limpia los estados */
        setNombre('');
        setApellidoPaterno('');
        setApellidoMaterno('');
        setEmpresa('');
        setCalle('');
        setNoExt('');
        setNoInt('');
        setColonia('');
        setEstado('');
        setMunicipio('');
        setPais('');
        setCp('');
        setReferencia('');
        setTelefono1('');
        setTelefono2('');
        setDireccionID(null);

        //Cerrando vista del formulario
        if( isOriginOrDestination === 'Origen' ){
            setAccess( !access );
            handleClose();
            return;
        }
        setAccess( !access );
        handleClose();
    }

    function errorMSG(){
        if( cp.length < 5 && telefono1.length < 10 ) return 'El Codigo Postal debe ser de 5 digitos y el Telefono1 deben de ser de 10 digitos';
        if( cp.length < 5 ) return 'El Codigo Postal, debe de contener 5 digitos.';
        if( telefono1.length < 10 ) return 'El Telefono1, debe de contener 10 digitos.';
    }

    function save(){
        if( nombre          !== '' && apellidoPaterno !== '' && 
            telefono1       !== '' && calle           !== '' && 
            noExt           !== '' && colonia         !== '' && 
            estado          !== '' && municipio       !== '' && 
            pais            !== '' && cp              !== '' ){
            if( cp.length === 5 && telefono1.length === 10 ){
                //Guardando informacion de origen
                if( isOriginOrDestination === 'Origen' ){
                    objectJSON.data.origin.origenPersona.nombre = nombre;
                    objectJSON.data.origin.origenPersona.apellidoPaterno = apellidoPaterno;
                    objectJSON.data.origin.origenCalle = calle;
                    objectJSON.data.origin.origenNumExt = noExt;
                    objectJSON.data.origin.origenColonia = colonia;
                    objectJSON.data.origin.origenEstado = estado;
                    objectJSON.data.origin.origenMunicipio = municipio;
                    objectJSON.data.origin.origenPais = pais;
                    objectJSON.data.origin.origenCP = cp;
                    objectJSON.data.origin.origenTelefono1 = telefono1;
                    //Comprobando si tiene apellido Materno
                    if( !( apellidoMaterno === '' ) ){ objectJSON.data.origin.origenPersona.apellidoMaterno = apellidoMaterno; }
                    //Comprobando si tiene empresa
                    if( !( empresa === '' ) ){ objectJSON.data.origin.origenEmpresa = empresa; }
                    //Comprobando si dio Numero interior
                    if( !( noInt === '' ) ){ objectJSON.data.origin.origenNumInt = noInt; }
                    //Comprobando si dio un segundo numero telefonico
                    if( !( telefono2 === '' ) ){ objectJSON.data.origin.origenTelefono2 = telefono2; }
                    //Comprobando si dio una referencia
                    if( !( referencia === '' ) ){ objectJSON.data.origin.origenRef = referencia; }
                    //Comprobando si cargo alguna dirección
                    if( !( direccionID === null ) ){ objectJSON.data.origin.id_dom_origen = direccionID }
                    //Se cambia el estado de access y se cierra la vista del formulario
                    setAccess( !access );
                    handleClose();
                    return;
                }
                //Guardando informacion de destino
                objectJSON.data.destination.destinoPersona.nombre = nombre;
                objectJSON.data.destination.destinoPersona.apellidoPaterno = apellidoPaterno;
                objectJSON.data.destination.destinoCalle = calle;
                objectJSON.data.destination.destinoNumExt = noExt;
                objectJSON.data.destination.destinoColonia = colonia;
                objectJSON.data.destination.destinoEstado = estado;
                objectJSON.data.destination.destinoMunicipio = municipio;
                objectJSON.data.destination.destinoPais = pais;
                objectJSON.data.destination.destinoCP = cp;
                objectJSON.data.destination.destinoTelefono1 = telefono1;
                //Comprobando si tiene apellido Materno
                if( !( apellidoMaterno === '' ) ){ objectJSON.data.destination.destinoPersona.apellidoMaterno = apellidoMaterno; }
                //Comprobando si tiene empresa
                if( !( empresa === '' ) ){ objectJSON.data.destination.destinoEmpresa = empresa; }
                //Comprobando si dio Numero interior
                if( !( noInt === '' ) ){ objectJSON.data.destination.destinoNumInt = noInt; }
                //Comprobando si dio un segundo numero telefonico
                if( !( telefono2 === '' ) ){ objectJSON.data.destination.destinoTelefono2 = telefono2; }
                //Comprobando si dio una referencia
                if( !( referencia === '' ) ){ objectJSON.data.destination.destinoRef = referencia; }
                //Comprobando si cargo alguna dirección
                if( !( direccionID === null ) ){ objectJSON.data.destination.id_dom_destino = direccionID; }
                //Se cambia el estado de access y se cierra la vista del formulario
                setAccess( !access );
                handleClose();
                return;
            }

            alert( errorMSG() );
            return;
        }

        alert( 'Asegure en llenar todos los campos( a exepción de empresa, numero interior y telefono 2 ), antes de guardar.' );
    }

    // function splitingClientFullName(){
    //     const fullName = {
    //         nombres : "",
    //         primerApellido : "",
    //         segundoApellido : ""
    //     };

    //     if( isOriginOrDestination === 'Origen' ){

    //         switch( (objectJSON.data.origin.origenPersona).split(' ').length ){
    //             case 3:
    //                 const [ 
    //                         nombre ,
    //                         primerApellido , 
    //                         segundoApellido 
    //                     ] = (objectJSON.data.origin.origenPersona).split(' ');
                    
    //                 fullName['nombres'] = nombre;
    //                 fullName['primerApellido'] = primerApellido;
    //                 fullName['segundoApellido'] = segundoApellido;

    //                 break;
    //             case 4: 
    //                 const [ 
    //                         primerNombre ,
    //                         segundoNombre ,
    //                         primerApellido2 , 
    //                         segundoApellido2 
    //                     ] = (objectJSON.data.origin.origenPersona).split(' ');
                    
    //                 fullName['nombres'] = `${ primerNombre } ${ segundoNombre }`;
    //                 fullName['primerApellido'] = primerApellido2;
    //                 fullName['segundoApellido'] = segundoApellido2;
                    
    //                 break;
    //         };

    //         return fullName;
    //     }

    //     switch( (objectJSON.data.destination.destinoPersona).split(' ').length ){
    //         case 3:
    //             const [ 
    //                     nombre ,
    //                     primerApellido , 
    //                     segundoApellido 
    //                 ] = (objectJSON.data.destination.destinoPersona).split(' ');
                
    //             fullName['nombres'] = nombre;
    //             fullName['primerApellido'] = primerApellido;
    //             fullName['segundoApellido'] = segundoApellido;

    //             break;
    //         case 4: 
    //             const [ 
    //                     primerNombre ,
    //                     segundoNombre ,
    //                     primerApellido2 , 
    //                     segundoApellido2 
    //                 ] = (objectJSON.data.destination.destinoPersona).split(' ');
                
    //             fullName['nombres'] = `${ primerNombre } ${ segundoNombre }`;
    //             fullName['primerApellido'] = primerApellido2;
    //             fullName['segundoApellido'] = segundoApellido2;
                
    //             break;
    //     };

    //     return fullName;
    // }errorEnApellidoPaterno

    /* Funcion por si hay informacion para cargar en los formularios */
    function showData(){
        /* Cargando la informacion de origen */
        if( isOriginOrDestination === 'Origen' ){
            // const fullName = splitingClientFullName();

            setNombre( objectJSON.data.origin.origenPersona.nombre );
            setApellidoPaterno( objectJSON.data.origin.origenPersona.apellidoPaterno );
            setCalle( objectJSON.data.origin.origenCalle );
            setNoExt( objectJSON.data.origin.origenNumExt );
            setColonia( objectJSON.data.origin.origenColonia );
            setEstado( objectJSON.data.origin.origenEstado );
            setMunicipio( objectJSON.data.origin.origenMunicipio );
            setPais( objectJSON.data.origin.origenPais );
            setCp( objectJSON.data.origin.origenCP );
            setTelefono1( objectJSON.data.origin.origenTelefono1 );

            if( ! ( objectJSON.data.origin.origenPersona.apellidoMaterno === '' ) ){ 
                setApellidoMaterno( objectJSON.data.origin.origenPersona.apellidoMaterno );
            }else{
                setApellidoMaterno( '' );
            }

            if( ! ( objectJSON.data.origin.origenEmpresa === '' ) ){ 
                setEmpresa( objectJSON.data.origin.origenEmpresa );
            }else{
                setEmpresa( '' );
            }

            if( ! ( objectJSON.data.origin.origenTelefono2 === '' ) ){ 
                setTelefono2( objectJSON.data.origin.origenTelefono2 );
            }else{
                setTelefono2( '' );
            }

            if( ! ( objectJSON.data.origin.origenNumInt === '' ) ){ 
                setNoInt( objectJSON.data.origin.origenNumInt );
            }else{
                setNoInt( '' );
            }

            if( ! ( objectJSON.data.origin.origenRef === '' ) ){ 
                setReferencia( objectJSON.data.origin.origenRef );
            }else{
                setReferencia( '' );
            }

            if( ! ( objectJSON.data.origin.id_dom_origen === null ) ){ 
                setDireccionID( objectJSON.data.origin.id_dom_origen );
            }else{
                setDireccionID( null );
            }

            return;
        }

        // const fullName = splitingClientFullName();
        /* Cargando la informacion de destino */
        setNombre( objectJSON.data.destination.destinoPersona.nombre) ;
        setApellidoPaterno( objectJSON.data.destination.destinoPersona.apellidoPaterno );
        setCalle( objectJSON.data.destination.destinoCalle );
        setNoExt( objectJSON.data.destination.destinoNumExt );
        setNoInt( objectJSON.data.destination.destinoNumInt );
        setColonia( objectJSON.data.destination.destinoColonia );
        setEstado( objectJSON.data.destination.destinoEstado );
        setMunicipio( objectJSON.data.destination.destinoMunicipio );
        setPais( objectJSON.data.destination.destinoPais );
        setCp( objectJSON.data.destination.destinoCP );
        setTelefono1( objectJSON.data.destination.destinoTelefono1 );

        if( ! ( objectJSON.data.destination.destinoPersona.apellidoMaterno === '' ) ){ 
            setApellidoMaterno( objectJSON.data.destination.destinoPersona.apellidoMaterno );
        }else{
            setApellidoMaterno( '' );
        }

        if( ! ( objectJSON.data.destination.destinoEmpresa === '' ) ){ 
            setEmpresa( objectJSON.data.destination.destinoEmpresa );
        }else{
            setEmpresa( '' );
        }

        if( ! ( objectJSON.data.destination.destinoTelefono2 === '' ) ){ 
            setTelefono2( objectJSON.data.destination.destinoTelefono2 );
        }else{
            setTelefono2( '' );
        }

        if( ! ( objectJSON.data.destination.destinoNumInt === '' ) ){ 
            setNoInt( objectJSON.data.destination.destinoNumInt );
        }else{
            setNoInt( '' );
        }

        if( ! ( objectJSON.data.destination.destinoRef === '' ) ){ 
            setReferencia( objectJSON.data.destination.destinoRef );
        }else{
            setReferencia( '' );
        }

        if( ! ( objectJSON.data.destination.id_dom_destino === null ) ){ 
            setDireccionID( objectJSON.data.destination.id_dom_destino );
        }else{
            setDireccionID( null );
        }
    }

    /* Ejecutando la funcion de showData por medio de useEffect */
    useEffect( () => { showData(); } , [ isOriginOrDestination , access ] );
    
    // Revisamos si es que ya hay datos en el JSON
    useEffect( () => {
        // setHayDatos( objectJSON.data.origin.isLoading ? true : objectJSON.data.destination.isLoading ? true : false );
        if( isOriginOrDestination === 'Origen' ){
            setHayDatos( objectJSON.data.origin.isLoading );
        }

        if( isOriginOrDestination === 'Destino' ){
            setHayDatos( objectJSON.data.destination.isLoading );
        }
    }, [ isOriginOrDestination ]);


    /* Retornando componente */
    return(
        <>
            <Modal hideBackdrop open = { open } onClose = { handleClose } aria-labelledby="parent-modal-title">
                <Container maxWidth="lg">
                    <Paper elevation={10} sx={ styles }>

                        {/* <Container sx={ {marginBottom: 2, textAlign: "left"} }> */}
                            <Grid container justifyContent="space-between" spacing={2}>

                                {/* Titulo */}
                                <Grid item xs={12} md={12}>
                                    <Typography variant='h5'>
                                        Dirección de { isOriginOrDestination }
                                    </Typography>
                                </Grid>

                                {/* Boton para cargar domicilio */}
                                {/* <Grid item xs={12} md={3}>
                                    <div align="right">
                                        <IconButton
                                            sx={{
                                                backgroundColor : "#008e1e",
                                                color : "#ffffff",
                                                width : "35px",
                                                height : "35px",
                                                borderRadius : 2,
                                                border : "2px solid #008e1e",
                                                "&:hover" : {
                                                    backgroundColor : "#006b17",
                                                    color : "#dcdcdc",
                                                    border : "2px solid #006b17"
                                                }
                                            }}
                                            onClick={ handleOpenAddressModal }
                                            title="Cargar Dirección" 
                                        >
                                            <HomeWork />
                                        </IconButton>
                                    </div>
                                </Grid> */}
                            </Grid>
                        {/* </Container> */}

                        <Grid container spacing = {2} xs={{ maxHeight: 20 }}>
                            <Grid item xs={12}>
                                <Typography>
                                    Datos de contacto
                                </Typography>
                            </Grid>

                            <Grid item xs = {12} md={4}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Nombre(s)" 
                                    placeholder='Ana María' 
                                    variant = "outlined" 
                                    onChange = { catchNombre } 
                                    value = { nombre }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6} md={4}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Apellido paterno" 
                                    placeholder='Martínez' 
                                    variant = "outlined" 
                                    onChange = { catchApellidoPaterno } 
                                    value = { apellidoPaterno }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6} md={4}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Apellido materno"  
                                    placeholder='del Campo' 
                                    variant = "outlined" 
                                    onChange = { catchApellidoMaterno } 
                                    value = { apellidoMaterno } 
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={false}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Teléfono principal"
                                    inputProps={{
                                        maxLength : 10
                                    }} 
                                    placeholder='3319735246' 
                                    variant = "outlined" 
                                    onChange = { catchTelefono1 } 
                                    value = { telefono1 }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Teléfono alternativo"
                                    inputProps={{
                                        maxLength : 10
                                    }} 
                                    placeholder='5579137319' 
                                    variant = "outlined" 
                                    onChange = { catchTelefono2 } 
                                    value = { telefono2 }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={false}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography>
                                    Datos del domicilio
                                </Typography>
                            </Grid>

                            <Grid item xs = {12} md={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Calle" 
                                    placeholder='Independencia' 
                                    variant = "outlined" 
                                    onChange = { catchCalle } 
                                    value = { calle }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>
                                            
                            <Grid item xs = {12} sm={6} md={3}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Número exterior" 
                                    placeholder='334' 
                                    variant = "outlined" 
                                    onChange = { catchNoExt } 
                                    value = { noExt }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6} md={3}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Número interior" 
                                    placeholder='A' 
                                    variant = "outlined" 
                                    onChange = { catchNoInt } 
                                    value = { noInt } 
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={false}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Colonia" 
                                    placeholder='Lomas de San Agustín' 
                                    variant = "outlined" 
                                    onChange = { catchColonia } 
                                    value = { colonia }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Municipio" 
                                    placeholder='Guadalajara' 
                                    variant = "outlined" 
                                    onChange = { catchMunicipio } 
                                    value = { municipio } 
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>
                                            
                            <Grid item xs = {12} sm={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Estado" 
                                    placeholder='Jalisco' 
                                    variant = "outlined" 
                                    onChange = { catchEstado } 
                                    value = { estado }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Código postal" 
                                    placeholder='44360' 
                                    disabled 
                                    variant = "outlined" 
                                    onChange = { catchCp } 
                                    value = { cp } 
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "País" 
                                    placeholder='México' 
                                    variant = "outlined" 
                                    onChange = { catchPais } 
                                    value = { pais }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs = {12} sm={6}>
                                <TextField 
                                    id = "standard-number" 
                                    label = "Empresa" 
                                    placeholder='KL Entregables' 
                                    variant = "outlined" 
                                    onChange = { catchEmpresa } 
                                    value = { empresa }
                                    disabled = { hayDatos }
                                    fullWidth={true} 
                                    required={false}
                                />
                            </Grid>

                            <Grid item xs = {12}>
                                <TextField 
                                    multiline 
                                    maxRows = {6} 
                                    id = "standard-number" 
                                    placeholder = 'El domicilio tiene en la fachada un...' 
                                    label = "Referencia" 
                                    variant = "outlined" 
                                    onChange = { catchReferencia } 
                                    value = { referencia }
                                    disabled = { hayDatos }
                                    fullWidth = {true} 
                                    required = {false}
                                />
                            </Grid>
                        </Grid>

                        <Grid 
                            container 
                            justifyContent="flex-end" 
                            alignItems="flex-start" 
                            spacing={2}
                            sx={{ marginY: 2 }}
                        >
                            <Grid item>
                                <Button 
                                    variant="outlined" 
                                    onClick={cancel} 
                                    sx={{ fontWeight : '700' }}
                                >
                                    Cancelar
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    onClick={save} 
                                    sx={{ fontWeight : '700' }}
                                    disabled = { hayDatos }
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                                            
                    </Paper>
                </Container>
            
            </Modal>

            {/* <AddressModal 
                open={openAddressModal}
                handleClose={handleCloseAddressModal}
                selectingAndLoadingAnAddress={selectingAndLoadingAnAddress}
            /> */}
        </>
    );
}