import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function HorizontalLinearStepper(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const stepsTitle = props.titles
    const finalMessage = props.finalMessage
    const disableNextButton = props.disable ?? false
    /*
    const isStepOptional = (step) => {
        return step === 1;
    };*/

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    /*
    const handleSkip = () => {

        if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
        });
    };*/

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {stepsTitle.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                
                if (isStepSkipped(index)) {
                    stepProps.completed = false;
                }
                return (
                    <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>

        {activeStep === stepsTitle.length ? (
            <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                    {finalMessage}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reiniciar</Button>
                </Box>
            </React.Fragment>
        ) : (
            <React.Fragment>
                {/**
                <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                 * 
                 */}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    >
                    Volver
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {/*isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                    </Button>
                    )*/}
                    <Button onClick={handleNext} disabled={disableNextButton}>
                        {activeStep === stepsTitle.length - 1 ? 'Final' : 'Siguiente'}
                    </Button>
                </Box>
                {props.steps[activeStep]}
            </React.Fragment>
        )}
        </Box>
    );
}
