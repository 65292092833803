import { useContext, useState } from 'react'
import ShoppingCartContext from './ShoppingCartContext'
import { Avatar, Button, Card, CardContent, CircularProgress, IconButton, Stack, Typography, useTheme } from '@mui/material'
import { CategoryOutlined, DownloadOutlined, LocalShippingOutlined, ReceiptLongOutlined, PendingActionsOutlined } from '@mui/icons-material'
import { PendingPayItem, DeliveryItem, ProductItem, WaybillItem } from './ShoppingCartSharedComponents'
import { downloadTicket } from '../../Shared'

export default function BuyResume() {
  const { carts, actualCart } = useContext(ShoppingCartContext)
  const cart = carts[actualCart]
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')
  const userPermissions = JSON.parse( sessionStorage.getItem('permissions') );

  const [downloadingWaybills, setDownloadingWaybills] = useState(false)
  const [downloadingTicket, setDownloadingTicket] = useState(false)

  async function downloadWaybill() {
    try {
      setDownloadingWaybills(true)

      const waybills = cart.response.waybills.waybillsGenerated.map(waybill => waybill.numero)

      const request = await fetch(`${localhost}/api/v2/waybills/download?waybills=${JSON.stringify(waybills)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (request.ok === true) {
        const blob = await request.blob()
        const url = URL.createObjectURL(blob)
        const anchor = document.createElement('a')

        anchor.href = url
        anchor.download = `${cart.response.ticket}.zip`
        anchor.click()

        URL.revokeObjectURL(url)

        return
      }

      throw new Error('Ocurrió un error con la petición')
    } catch (error) {
      console.error(error.message)
    } finally {
      setDownloadingWaybills(false)
    }
  }

  return (
    <Stack spacing={2}>
      <Typography variant='body1'>Tu compra ha sido procesada con el número de ticket #{cart.response.ticket}</Typography>

      {cart.response.waybills.isNull === undefined && (
        <Section
          title='Guías'
          icon={<ReceiptLongOutlined sx={{ fontSize: 18 }} />}
          spacing={2}
        >
          <Stack>
            {cart.response.waybills.waybillsGenerated && (
              <Stack>
                <Typography variant='subtitle1'>Guías generadas</Typography>
                {cart.response.waybills.waybillsGenerated.map(waybill => (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <WaybillItem data={waybill} />
                    <DownloadButton
                      waybill={waybill.numero}
                      token={token}
                      localhost={localhost}
                    />
                  </Stack>
                ))}
              </Stack>
            )}

            {cart.response.waybills.waybillsWithErrors && (
              <Stack>
                <Typography variant='subtitle1'>Guías con errores</Typography>

                {cart.response.waybills.waybillsWithErrors.map(waybill => (
                  <DeliveryItem data={waybill.waybill} />
                ))}
              </Stack>
            )}
          </Stack>
        </Section>
      )}

      {cart.response.products.isNull === undefined && (
        <Section
          title='Productos'
          icon={<CategoryOutlined sx={{ fontSize: 18 }} />}
          spacing={2}
        >
          {cart.response.products.productsGenerated && (
            <Stack>
              <Typography variant='subtitle1'>Productos Generados</Typography>

              {cart.response.products?.productsGenerated?.map(product => (
                <ProductItem data={product} />
              ))}
            </Stack>
          )}

          {cart.response.products.productsWithErrors && (
            <Stack>
              <Typography variant='subtitle1'>Productos con errores</Typography>

              {cart.response.products?.productsWithErrors?.map(product => (
                <ProductItem data={product.product} />
              ))}
            </Stack>
          )}
        </Section>
      )}

      {cart.response.deliveries.isNull === undefined && (
        <Section
          title='Entregas'
          icon={<LocalShippingOutlined sx={{ fontSize: 18 }} />}
          spacing={2}
        >
          {cart.response.deliveries.deliveriesGenerated && (
            <Stack>
              <Typography variant='subtitle1'>Entregas generadas</Typography>

              {cart.response.deliveries.deliveriesGenerated.map(delivery => (
                <DeliveryItem data={delivery} />
              ))}
            </Stack>
          )}

          {cart.response.deliveries.deliveriesWithErrors && (
            <Stack>
              <Typography variant='subtitle1'>Entregas con errores</Typography>

              {cart.response.deliveries.deliveriesWithErrors.map(delivery => (
                <DeliveryItem data={delivery.delivery} />
              ))}
            </Stack>
          )}
        </Section>
      )}

      {cart.response.pendingPays?.isNull === undefined && (
        <Section
          title='Tickets pendientes de pago'
          icon={<PendingActionsOutlined sx={{ fontSize: 18 }} />}
          spacing={2}
        >
          {cart.response.pendingPays?.ticketsUpdated && (
            <Stack>
              <Typography variant='subtitle1'>Tickets actualizados</Typography>

              {cart.response.pendingPays.ticketsUpdated.map(pendingPay => (
                <PendingPayItem 
                  data={pendingPay}
                  isDownloadable={false} 
                />
              ))}
            </Stack>
          )}

          {cart.response.pendingPays?.ticketsWithErrors && (
            <Stack>
              <Typography variant='subtitle1'>Tickets con errores</Typography>

              {cart.response.pendingPays?.ticketsWithErrors.map(pendingPay => (
                <PendingPayItem 
                  data={pendingPay.pendingPay}
                  isDownloadable={false} 
                />
              ))}
            </Stack>
          )}
        </Section>
      )}

      {/* {cart.response.waybills.isNull === undefined && (
        <Section
          title='Pagos pendientes'
          icon={<ReceiptLongOutlined sx={{ fontSize: 18 }} />}
          spacing={2}
        >
          <Stack>
            {cart.response.waybills.waybillsGenerated && (
              <Stack>
                <Typography variant='subtitle1'>Guías generadas</Typography>
                {cart.response.waybills.waybillsGenerated.map(waybill => (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <WaybillItem data={waybill} />
                    <DownloadButton
                      waybill={waybill.numero}
                      token={token}
                      localhost={localhost}
                    />
                  </Stack>
                ))}
              </Stack>
            )}

            {cart.response.waybills.waybillsWithErrors && (
              <Stack>
                <Typography variant='subtitle1'>Guías con errores</Typography>

                {cart.response.waybills.waybillsWithErrors.map(waybill => (
                  <DeliveryItem data={waybill.waybill} />
                ))}
              </Stack>
            )}
          </Stack>
        </Section>
      )} */}

      <Stack
        useFlexGap
        direction='row'
        flexWrap='wrap'
        spacing={2}
      >

        {(cart.response.waybills.waybillsGenerated!== undefined && cart.response.waybills.waybillsGenerated>1)&&(

        <Button
          variant='outlined'
          disabled={downloadingWaybills === true}
          startIcon={downloadingWaybills === true ? <CircularProgress size={20} /> : null}
          onClick={() => downloadWaybill()}
        >
          Descargar guías generadas
        </Button>
        )}

        {(userPermissions['role'] !== 'SubCliente') && (
          <Button
            variant='outlined'
            disabled={downloadingTicket === true}
            startIcon={downloadingTicket === true ? <CircularProgress size={20} /> : null}
            onClick={() => downloadTicket(setDownloadingTicket, cart.response.ticket)}
          >
            Imprimir ticket
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

const DownloadButton = ({ waybill, localhost, token }) => {
  const [downloading, setDownloading] = useState(false)

  async function downloadWaybill() {
    try {
      setDownloading(true)

      const request = await fetch(`${localhost}/api/v2/waybills/download/${waybill}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (request.ok === true) {
        const blob = await request.blob()
        const url = URL.createObjectURL(blob)
        const anchor = document.createElement('a')

        anchor.href = url
        anchor.download = `${waybill}.pdf`
        anchor.click()

        URL.revokeObjectURL(url)

        return
      }

      throw new Error('Ocurrió un error con la petición')
    } catch (error) {
      console.error(error.message)
    } finally {
      setDownloading(false)
    }
  }

  if (downloading) {
    return <CircularProgress size={20} />
  } else {
    return (
      <IconButton onClick={() => downloadWaybill()}>
        <DownloadOutlined />
      </IconButton>
    )
  }
}

const Section = ({ icon, title, children, spacing }) => {
  const theme = useTheme()

  return (
    <Card
      variant='outlined'
      sx={{ height: '100%' }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Stack
            direction='row'
            spacing={1}
            alignContent='center'
          >
            <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
            <Typography variant='subtitle1'>{title}</Typography>
          </Stack>
          <Stack spacing={spacing ?? 0}>{children}</Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
