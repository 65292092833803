import { ButtonBase, Paper, Radio, Stack, Typography, useTheme } from '@mui/material'

export function SelectionButton({ title, subtitle, images, value, selected, onSelect }) {
  const theme = useTheme()

  const EnableButtonStyle = {
    width: '100%',
    height: '100%',
    textAlign: 'left'
  }

  const DisableButtonStyle = {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    opacity: 0.75,
    color: theme.palette.grey[400]
  }

  return (
    <Paper
      variant='outlined'
      sx={{ width: '100%', height: '100%', overflow: 'hidden', borderColor: value === selected ? theme.palette.primary.main : null }}
    >
      <ButtonBase
        sx={onSelect ? EnableButtonStyle : DisableButtonStyle}
        onClick={() => (onSelect ? onSelect(value) : null)}
        disabled={!onSelect}
      >
        <Stack
          direction='row'
          sx={{ width: '100%', height: '100%' }}
          alignItems='center'
        >
          <Radio
            checked={value === selected}
            value={value}
            onChange={() => (onSelect ? onSelect(value) : null)}
          />
          <Stack sx={{ paddingY: 1, paddingRight: 1 }}>
            <Typography variant='subtitle1'>{title}</Typography>
            {images && (
              <Stack
                direction='row'
                spacing={1}
              >
                {images?.map((image, index) => (
                  <img
                    key={`Method-${index}`}
                    src={image}
                    style={{ width: 'auto', height: 15 }}
                  />
                ))}
              </Stack>
            )}
            {subtitle && <Typography variant='body1'>{subtitle}</Typography>}
          </Stack>
        </Stack>
      </ButtonBase>
    </Paper>
  )
}
