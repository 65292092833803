import React , { useState } from 'react';
import {Grid ,
        TextField,
        FormControl,
        FormLabel,
        RadioGroup,
        FormControlLabel,
        Radio} from '@mui/material';

import './shippingStyles.css';
import ButtonLink from'./MarcoComponents/ButtonLink';

export default function ShippingIndex(){

    const [ option , setOption ] = useState("Carta");
    const textFieldCSS = {
        backgroundColor : '#ffffff',
        borderTopLeftRadius : '5px',
        borderTopRightRadius : '5px',
    };
    const radioGroupCSS = {
        marginLeft : '14px'
    };

    function letterOrPackage( event ){
        setOption( event.target.value );
        console.log( option );
    }

    function letterMenuOrPackageMenu(){
        const gridPackageMeasurements = {
            backgroundColor : '#aba8a8',
            borderRadius : '4px',
            marginTop: '0',
            marginLeft : '-11px',
            paddingBottom : '23px',
            border : '2px solid #5b5b5b'
        };

        const gridItemPackageMeasurements = {
            marginLeft : '-12px'
        }

        if(option === 'Carta'){
            return(
                <ButtonLink 
                    url = "/shippingInfoList" 
                    clases = "" 
                    texto = "Siguiente"
                />
            );
        }

        return(
            <Grid 
                container 
                spacing = {3}
                sx = { gridPackageMeasurements }
            >
                <Grid 
                    item 
                    xs = {6}
                    sx = { gridItemPackageMeasurements }
                >
                    <TextField 
                        id = "filled-number" 
                        label = "Largo" 
                        type = "number" 
                        InputLabelProps = {{ shrink: true, }}
                        variant = "filled"
                        sx = { textFieldCSS }
                    />
                </Grid>
                <Grid 
                    item 
                    xs = {6}
                >
                    <TextField 
                        id = "filled-number" 
                        label = "Alto" 
                        type = "number" 
                        InputLabelProps = {{ shrink: true, }}
                        variant = "filled"
                        sx = { textFieldCSS }
                    />
                </Grid>
                <Grid 
                    item 
                    xs = {6}
                    sx = { gridItemPackageMeasurements }
                >
                    <TextField 
                        id = "filled-number" 
                        label = "Ancho" 
                        type = "number" 
                        InputLabelProps = {{ shrink: true, }}
                        variant = "filled"
                        sx = { textFieldCSS }
                    />
                </Grid>
                <Grid 
                    item 
                    xs = {6}
                >
                    <TextField 
                        id = "filled-number" 
                        label = "Peso" 
                        type = "number" 
                        InputLabelProps = {{ shrink: true, }}
                        variant = "filled"
                        sx = { textFieldCSS }
                    />
                </Grid>
                <Grid 
                    item 
                    xs = {12}
                    sx = { gridItemPackageMeasurements }
                >
                    <ButtonLink 
                        url = "/shippingInfoList" 
                        clases = "" 
                        texto = "Siguiente"
                    />
                </Grid>
            </Grid>
        );
    }
    
    return(
        <div className = "container">
            <form className = "formulario">
                <fieldset className = "fieldsetMain">
                    <legend>Formulario de envio</legend>

                    <Grid container spacing = {3}>

                        <Grid 
                            item 
                            xs = {6}>
                            <TextField 
                                id = "filled-basic" 
                                label = "C.P origen" 
                                variant = "filled"
                                sx = { textFieldCSS }
                            />
                        </Grid>

                        <Grid 
                            item
                            xs = {6}>
                            <TextField 
                                id = "filled-basic" 
                                label = "C.P destino" 
                                variant = "filled"
                                sx = { textFieldCSS }
                            />
                        </Grid>

                        <Grid
                            item
                            xs = {12}>
                            <FormControl
                                sx = {{
                                    backgroundColor : '#ffffff',
                                    padding : '5px',
                                    borderRadius : '5px',
                                    border: '2px solid #ff4500'
                                }}>
                                <FormLabel id="demo-row-radio-buttons-group-label">El objeto es:</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby = "demo-row-radio-buttons-group-label"
                                    name = "row-radio-buttons-group"
                                    value = { option }
                                    onChange = { letterOrPackage }
                                    sx = { radioGroupCSS }
                                >
                                    <FormControlLabel value="Carta" control={<Radio />} label="Carta" />
                                    <FormControlLabel value="Paquete" control={<Radio />} label="Paquete" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            xs = {12}
                        >
                            {
                                letterMenuOrPackageMenu()
                            }
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </div>
    );
}