import { Grid, Stack, Typography } from '@mui/material'
import { SelectionButton } from './CreditMovementsSharedComponents'

export default function PaymentMethods({ methodSelected, setMethodSelected }) {
  return (
    <Stack
      useFlexGap
      spacing={2}
    >
      <Typography variant='body1'>Ahora, selecciona el método de pago que utilizarás</Typography>

      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <SelectionButton
            title='Trasferencia bancaria'
            value='1'
            images={[require('../../../assets/paymentBrands/spei.webp')]}
            selected={methodSelected}
            onSelect={setMethodSelected}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <SelectionButton
            title='Tarjeta de crédito o débito'
            value='2'
            images={[require('../../../assets/paymentBrands/visa.webp'), require('../../../assets/paymentBrands/mastercard.png')]}
            selected={methodSelected}
            //onSelect={setMethodSelected}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <SelectionButton
            title='Mercado Pago'
            subtitle='Próximamente'
            images={[require('../../../assets/paymentBrands/mercadopago.webp')]}
            value='3'
            selected={methodSelected}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <SelectionButton
            title='PayPal'
            subtitle='Próximamente'
            images={[require('../../../assets/paymentBrands/paypal.webp')]}
            value='4'
            selected={methodSelected}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}
