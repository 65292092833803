import { Stack, TextField, Switch, FormGroup, FormControlLabel, Typography, Button, InputAdornment, Alert } from '@mui/material'
import { changeServicesData } from './Services'
import { useState, useEffect } from 'react'
import { ModalView } from '../../Shared'

/**
 * @typedef {object} Service
 * @property {string|number} tarifa_base
 * @property {string|number} kg_amparados
 * @property {string|number} sobrepeso
 * @property {boolean} allowed
 * @property {number} service_id
 * @property {number} user_id
 */

/**
 * @typedef {object} ComponentParams
 * @property {[any, React.SetStateAction<undefined>]} displayState
 * @property {Service} service
 * @property {React.SetStateAction<undefined>} serviceSetter
 */

/**
 *
 * @param {ComponentParams} params
 * @returns
 */
export default function ServicesForm({ displayState, service, serviceSetter }) {
  const [show, setShow] = displayState
  const [tarifaBase, setTarifaBase] = useState()
  const [kgAmparados, setKgAmparados] = useState()
  const [sobrepeso, setSobrepeso] = useState()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const handleChangeServiceData = async () => {
    try {
      setLoading(true)
      const param = {
        tarifa_base: tarifaBase,
        kg_amparados: kgAmparados,
        sobrepeso: sobrepeso
      }
      const { error } = await changeServicesData({ ...service, ...param })

      if (error) {
        setShowError(true)
        return
      }
      serviceSetter(prev => {
        const copy = [...prev]
        const index = prev.findIndex(i => i.id === service.service_id)
        const allowed = service.allowed
        copy[index].allowed = allowed
        copy[index].tarifa_base = allowed ? tarifaBase : 0
        copy[index].kg_amparados = allowed ? kgAmparados : 0
        copy[index].sobrepeso = allowed ? sobrepeso : 0
        return copy
      })
      setShow(false)
    } catch (error) {
      setShowError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (show) {
      setTarifaBase(service.tarifa_base)
      setKgAmparados(service.kg_amparados)
      setSobrepeso(service.sobrepeso)
      setShowError(false)
    }
  }, [show])

  const setDecimal = (setter, e) => setter(e.target.value.replace(/[^0-9.]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2'))

  return (
    <ModalView
      displayState={displayState}
      avoidClose={loading}
    >
      <Stack spacing={2}>
        <Typography>Edite los datos necesarios.</Typography>
        <TextField
          label='Tarifa base'
          value={tarifaBase}
          InputProps={{ endAdornment: <InputAdornment position='end'>$</InputAdornment> }}
          onChange={e => setDecimal(setTarifaBase, e)}
        />
        <TextField
          label='KG amparados'
          value={kgAmparados}
          inputMode='numeric'
          InputProps={{ endAdornment: <InputAdornment position='end'>Kg</InputAdornment> }}
          onChange={e => setDecimal(setKgAmparados, e)}
        />
        <TextField
          label='Sobrepeso'
          value={sobrepeso}
          inputMode='numeric'
          InputProps={{ endAdornment: <InputAdornment position='end'>$</InputAdornment> }}
          onChange={e => setDecimal(setSobrepeso, e)}
        />
        {showError && (
          <Alert
            title='¡Ocurrió un error!'
            severity='error'
          >
            Inténtelo de nuevo más tarde.
          </Alert>
        )}
        <Stack
          spacing={2}
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Button
            onClick={() => setShow(false)}
            disabled={loading}
          >
            Cerrar
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleChangeServiceData()}
          >
            Cambiar
          </Button>
        </Stack>
      </Stack>
    </ModalView>
  )
}
