
import React, {useState, useEffect} from 'react';

import {
    Grid,
    Paper,
    Container,
    TextareaAutosize,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText

} from '@mui/material';

import {
    LocalShippingOutlined,
    Close
} from "@mui/icons-material"

import Copyright from '../../../General/Copyright';
import TrackingItem from './trackingItem';

function TrackingView(){
    
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState({});
    const [waybillsToSearch, setWaybillsToSearch] = useState([]);
    const [waybillResult, setwaybillResult] = useState([]);
    const [waitForAResult, setWaitForAResult] = useState(false);
    const [providerID, setProviderID] = useState(0);
    const [providerSelectorDisabled, setProviderSelectorDisabled] = useState(true);
    const [providers, setProviders] = useState([{
        id : 0,
        descripcion : "Obteniendo proveedores, espere..."
    }]);

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };
    
    const handleClickOpen = (waybill) => {
        setOpen(true);
        setItems(waybill);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    //const selectingProvider = event => setProviderID(Number(event.target.value));

    const handleChange = (e)=>{
        e.preventDefault();

        /* Se recibe los numeros de guías */
        let waybills = e.target.value.replace(/[^a-zA-Z0-9\n]/g, " ");
        waybills = waybills.replace(/ /g,"\n").split("\n");
        /* Se actualiza o se conserva el valor del estado de waybillsToSearch */
        setWaybillsToSearch(
            waybills.length <= 100
            ? waybills
            : waybillsToSearch
        );
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();

        if( waybillsToSearch.length != 0 ){
            setWaitForAResult(true);

            const waybills = waybillsToSearch.filter( waybill => waybill !== "" );

            try{
                const response = await fetch(
                    `${envConfig.url}/api/v2/tracking?waybills=${JSON.stringify(waybills)}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                            "Cache-Control": "no-cache"
                        }
                    });
                const data = await response.json();
                //console.log(data);
                
                const updateToWaybillsToSearch = [];
                data["data"].forEach( tracking => updateToWaybillsToSearch.push(tracking["waybill"]["numero"]));

                setWaybillsToSearch(updateToWaybillsToSearch);
                setwaybillResult(data["data"]);
            }
            catch(error){
                alert("Error de conexion, intente mas tarde")
                console.log(error);
            }

            setWaitForAResult(false);
            return;
        }

        alert(`El campo de guías de rastreo, esta vacio\n\n-Asegure en agregar uno o varios números de guías para realizar el rastreo.`);
    }

    const getProviders = async () => {
        await fetch(`${envConfig.url}/api/v2/provider/`, {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : `Bearer ${sessionStorage.getItem("token")}`,
                "Cache-Control" : "no-cache"
            }
        })
            .then( res => {
                if( res.ok ){
                    return res.json();
                }

                setProviders([{
                    id : 0,
                    descripcion : "Hubo problemas en nuestro sevidor, intentelo más tarde"
                }]);
                return;
            } )
            .then( json => { 
                setProviders(json["providers"]);
                setProviderSelectorDisabled(false);
            json["providers"].forEach( provider =>{ if(provider["descripcion"] === "Logistica Reyes") setProviderID(provider["id"]) });
            })
            .catch( error => {
                setProviders([{
                    id : 0,
                    descripcion : "No fue posible establecer conexion al servidor, intentelo más tarde"
                }]);
            } );
    };

    useEffect( () => getProviders(), [] );
    
    return(
        <Grid container spacing={3}
                    sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        }}
            >
            <Grid item xs={12}>
                <Paper sx={{ 
                    p: 2, 
                    display: 'flex', 
                    flexDirection: 'column',
                    height: 100,
                    }}>
                    Rastreo
                </Paper>
            </Grid>
            <Grid item xs={12} 
                sx={{  
                    display: 'flex', 
                    flexDirection: 'row',
                    justifyContent:"space-around"
                    }}>
                    <Paper
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 400,
                        width:500,
                        p:2
                        }}
                    >
                        {/* <FormControl 
                            sx = {{
                                marginBottom : "5px"
                            }}
                            disabled = { providerSelectorDisabled }
                            fullWidth
                        >
                            <InputLabel
                                id = "provider-selector-label"
                            >
                                Elige un proveedor
                            </InputLabel>
                            <Select
                                labelId = "provider-selector-label"
                                id = "provider-selector"
                                value = { providerID }
                                label = "Elige un proveedor"
                                onChange = { selectingProvider }
                            >   
                                {
                                    providers.map( provider => (
                                        <MenuItem value = { provider.id }>{ provider.descripcion }</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl> */}

                        <TextareaAutosize
                            minRows={10}
                            maxRows={10}
                            aria-label="maximum height"
                            placeholder="Inserte guias rastreo"
                            value={waybillsToSearch.toString().replace(/,/g,"\n")}
                            onChange={handleChange}
                            sx={{
                                width: "100%",
                            }}
                        />
                        <Button 
                                variant="contained" 
                                type="submit"
                                onClick={handleSubmit}
                                sx={{
                                    mt:2
                                }}
                                disabled = { waitForAResult }
                                >Rastrear</Button>
                        
                    </Paper>
                {/* Recent Deposits */}
                    <Paper
                        sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 400,
                        width:500,
                        overflow: 'auto',
                        }}
                    >
                        <List>
                            {
                                waybillResult.map((item,id)=>(
                                    <ListItem 
                                        key={id} 
                                        button 
                                        variant="outlined" 
                                        onClick={()=>handleClickOpen(item)} 
                                        sx={{
                                            mt : 1,
                                            borderRadius : "5px",
                                            backgroundColor : item["tracking"].length !== 0 ? "#6ccf69" : "#e36060",
                                            "&:hover" : {
                                                backgroundColor : item["tracking"].length !== 0 ? "#53a051" : "#b04c4c"
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            {
                                                item["tracking"].length !== 0
                                                ? <LocalShippingOutlined sx = {{ color : "#2b5429"}}/>
                                                : <Close sx = {{ color : "#5a1818"}}/>
                                            }
                                        </ListItemIcon>
                                        <ListItemText
                                            primary = {`Guia: ${item["waybill"]["numero"]}`}
                                            sx = {{
                                                color : item["tracking"].length !== 0 ? "#2b5429" : "#5a1818"
                                            }}
                                            primaryTypographyProps = {{
                                                fontWeight : "700"
                                            }}
                                        />
                                    </ListItem>
                                ))
                            }
                        </List>
                        <TrackingItem
                            open={open}
                            onClose={handleClose}
                            items={items}
                            title={"Rastreo "}
                        />
                    </Paper>
            </Grid>
        </Grid>
    )
}

export default function TrackingIndex(props) {

    return(
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <TrackingView/>,
            <Grid container paddingTop={2}>
                <Grid item xs={12}>
                    <Paper sx={{ 
                            p: 1,
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems:'center',
                            justifyContent:'center',
                            height: 50,
                        }}>
                        <Copyright sx={{ pt: 4 }} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}
