export const MONTHS_NAMES = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const MONTHS_OBJ = [
  {
    label: 'Enero',
    value: 'Jan'
  },
  {
    label: 'Febrero',
    value: 'Feb'
  },
  {
    label: 'Marzo',
    value: 'Mar'
  },
  {
    label: 'Abril',
    value: 'Apr'
  },
  {
    label: 'Mayo',
    value: 'May'
  },
  {
    label: 'Junio',
    value: 'Jun'
  },
  {
    label: 'Julio',
    value: 'Jul'
  },
  {
    label: 'Agosto',
    value: 'Aug'
  },
  {
    label: 'Septiembre',
    value: 'Sep'
  },
  {
    label: 'Octubre',
    value: 'Oct'
  },
  {
    label: 'Noviembre',
    value: 'Nov'
  },
  {
    label: 'Diciembre',
    value: 'Dec'
  }
]

export const CUT_OFF_STATUSES = { ENDED: 'Finalizado', PENDING: 'Pendiente', STARTED: 'Iniciado' }

export const PERMISSIONS = JSON.parse(sessionStorage.getItem('permissions'))
