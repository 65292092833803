/* Importaciones de React */
import React, { useState, useEffect } from 'react'

/* Importaciones de MUI */
import { Stack, Typography, TextField, Button } from '@mui/material'

/* Importaciones del modulo de Shared */
import { ModalView } from '../../Shared'

export default function CancelTicketForm({ displayState, ticket }){
    const [show, setShow] = displayState

    return (
        <ModalView
            displayState={displayState}
            width='32%'
            maxHeight='85%'
        >
            <Stack
                direction='column'
                spacing={2}
            >
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        textAlign: 'center'
                    }}
                >
                    {`¿Esta seguro(a) en cancelar este ticket: ${ticket?.uuid}?`}
                </Typography>
            </Stack>

            <Stack
                direction='row'
                alignItems='center'
                justifyContent='right'
            >
                <Button
                    onClick={() => {}}
                    sx={{ fontSize: '12.4px' }}
                >
                    Cancelar ticket
                </Button>

                <Button
                    onClick={() => setShow(false)}
                    sx={{ fontSize: '12.4px' }}
                >
                    Cerrar
                </Button>
            </Stack>
        </ModalView>
    )
}