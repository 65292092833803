import { NearMeOutlined, PlaceOutlined } from '@mui/icons-material'
import { Avatar, Card, CardContent, Grid, Stack, TextField, Typography, useTheme, Autocomplete } from '@mui/material'
import React, { useState, useEffect } from 'react'


export default function AddressesDetails({ sender, setSender, recipient, setRecipient, hasSenderSelected, hasRecipientSelected, setValidatedAddresses }) {
  useEffect(() => {
    let validated = true
    const textRegex = /^\s*$/

    if (hasSenderSelected === false) {
      if (textRegex.test(sender?.nombre) || sender?.nombre === undefined) {
        validated = false
      }

      if (textRegex.test(sender?.apellidoPaterno) || sender?.apellidoPaterno === undefined) {
        validated = false
      }

      if (textRegex.test(sender?.telefono1) || sender?.telefono1 === undefined) {
        validated = false
      }

      if (textRegex.test(sender?.calle) || sender?.calle === undefined) {
        validated = false
      }

      if (textRegex.test(sender?.numeroExterior) || sender?.numeroExterior === undefined) {
        validated = false
      }

      if (textRegex.test(sender?.colonia) || sender?.colonia === undefined) {
        validated = false
      }

      if (textRegex.test(sender?.municipio) || sender?.municipio === undefined) {
        validated = false
      }

      if (textRegex.test(sender?.estado) || sender?.estado === undefined) {
        validated = false
      }

      if (textRegex.test(sender?.pais) || sender?.pais === undefined) {
        validated = false
      }
    }

    if (hasRecipientSelected === false) {
      if (textRegex.test(recipient?.nombre) || recipient?.nombre === undefined) {
        validated = false
      }

      if (textRegex.test(recipient?.apellidoPaterno) || recipient?.apellidoPaterno === undefined) {
        validated = false
      }

      if (textRegex.test(recipient?.telefono1) || recipient?.telefono1 === undefined) {
        validated = false
      }

      if (textRegex.test(recipient?.calle) || recipient?.calle === undefined) {
        validated = false
      }

      if (textRegex.test(recipient?.numeroExterior) || recipient?.numeroExterior === undefined) {
        validated = false
      }

      if (textRegex.test(recipient?.colonia) || recipient?.colonia === undefined) {
        validated = false
      }

      if (textRegex.test(recipient?.municipio) || recipient?.municipio === undefined) {
        validated = false
      }

      if (textRegex.test(recipient?.estado) || recipient?.estado === undefined) {
        validated = false
      }

      if (textRegex.test(recipient?.pais) || recipient?.pais === undefined) {
        validated = false
      }
    }
    
    setValidatedAddresses(validated)
  }, [sender, recipient])

  return (
    <Stack spacing={2}>
      <Typography variant='body1'>A continuación, ingresa los detalles de los domicilios que hacen falta</Typography>

      {hasSenderSelected === false && (
        <AddressForm
          getter={sender}
          setter={setSender}
          icon={<PlaceOutlined sx={{ fontSize: 18 }} />}
          title='Detalles del origen'
        />
      )}

      {hasRecipientSelected === false && (
        <AddressForm
          getter={recipient}
          setter={setRecipient}
          icon={<NearMeOutlined sx={{ fontSize: 18 }} />}
          title='Detalles del destino'
        />
      )}
    </Stack>
  )
}

const AddressForm = ({ getter, setter, icon, title }) => {
  const theme = useTheme()
  const envConfig = {
    url: process.env.REACT_APP_ENVIROMENT === 'prod' ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
  }
  const [coloniasDisponibles, setColoniasDisponibles] = useState([])
  const [municipio, setMunicipio] = useState('')
  const [estado, setEstado] = useState('')
  const [pais, setPais] = useState('')
  const [colonia, setColonia] = useState('')
  const [codigoPostal, setCodigoPostal] = useState('')

  const catchCodigoPostal = async (codigoPostalEntrante) => {
    setCodigoPostal(codigoPostalEntrante);
    if (codigoPostalEntrante.length === 5) {
      try {
        setter({ ...getter, codigoPostal: codigoPostalEntrante });
        const respuesta = await fetch(`${envConfig.url}/api/v2/addresses/zip/${codigoPostalEntrante}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            'Cache-Control': 'no-cache',
          },
        });  
        if (respuesta.ok) {
          const datos = await respuesta.json();
          setPais(filtrarCaracteres(datos.pais));
          setEstado(filtrarCaracteres(datos.estado));
          setMunicipio(filtrarCaracteres(datos.municipio));

          setColoniasDisponibles(datos.colonias.map(colonia => filtrarCaracteres(colonia)));
          setter(prevState => ({
            ...prevState,
            pais: datos.pais,
            estado: datos.estado,
            municipio: datos.municipio,
          }));

        } else {
          console.error('Respuesta no exitosa del servidor');
        }
      } catch (error) {
        console.error('Error al hacer la solicitud al servidor:', error);
      }
    }
  };

  useEffect(() => {
    if (getter.codigoPostal) {
      catchCodigoPostal(getter.codigoPostal);
    }
  }, []);
  
  return (
    <Card
      variant='outlined'
      sx={{ height: '100%' }}
    >
      <CardContent>
        <Stack
          spacing={1}
          useFlexGap
        >
          <Stack
            direction='row'
            spacing={1}
            alignContent='center'
          >
            <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
            <Typography variant='subtitle1'>{title}</Typography>
          </Stack>

          <Typography variant='subtitle2'>Datos del contacto</Typography>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                variant='outlined'
                label='Empresa'
                value={getter.empresa}
                onChange={event => {
                  const filteredValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');                  setter({ ...getter, empresa: filteredValue });
                }}
                  inputProps={{
                  maxlength: 30
                }}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <TextField
                variant='outlined'
                placeholder='Sin acentos'
                label='Nombre *'
                value={getter.nombre}
                onChange={event => {
                  const filteredValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');                  setter({ ...getter, empresa: filteredValue });
                  setter({ ...getter, nombre: filteredValue });
                }}
                inputProps={{
                  maxLength: 15
                }}
                fullWidth
/>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <TextField
                variant='outlined'
                placeholder='Sin acentos'
                label='Apellido paterno *'
                value={getter.apellidoPaterno}
                onChange={event => {
                  const filteredValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
                  setter({ ...getter, apellidoPaterno: filteredValue });
                }}
                inputProps={{
                  maxLength: 15
                }}
                fullWidth
              />

            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <TextField
                variant='outlined'
                placeholder='Sin acentos'
                label='Apellido materno'
                value={getter.apellidoMaterno}
                onChange={event => {
                  const filteredValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
                  setter({ ...getter, apellidoMaterno: filteredValue });
                }}
                  inputProps={{
                  maxlength: 15
                }}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
             <TextField
                variant='outlined'
                placeholder='A 10 digitos'
                label='Teléfono *'
                value={getter.telefono1}
                onChange={event => {
                  if (/^\d*$/.test(event.target.value)) {
                    setter({ ...getter, telefono1: event.target.value })
                  }
                }}
                inputProps={{
                  maxlength: 10
                }}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                variant='outlined'
                placeholder='A 10 digitos'
                label='Teléfono alterno'
                value={getter.telefono2}
                onChange={event => {
                  if (/^\d*$/.test(event.target.value)) {
                    setter({ ...getter, telefono2: event.target.value })
                  }
                }}
                inputProps={{
                  maxlength: 10
                }}
                fullWidth
              />
            </Grid>
          </Grid>

          <Typography variant='subtitle2'>Datos del domicilio</Typography>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={8}
            >
              <TextField
                variant='outlined'
                placeholder='Clavel'
                label='Calle *'
                value={getter.calle}
                onChange={event => {
                  const filteredValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
                  setter({ ...getter, calle: filteredValue });
                }}
                inputProps={{
                  maxlength: 30
                }}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
            >
              <TextField
                variant='outlined'
                label='Número exterior *'
                value={getter.numeroExterior}
                placeholder='123'
                onChange={event => {
                  if (/^\d*$/.test(event.target.value)) {
                    setter({ ...getter, numeroExterior: event.target.value })
                  }
                }}
                inputProps={{
                  maxlength: 10
                }}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
            >
              <TextField
                variant='outlined'
                label='Número interior '
                value={getter.numeroInterior}
                placeholder='123'
                onChange={event => {
                  if (/^\d*$/.test(event.target.value)) {
                    setter({ ...getter, numeroInterior: event.target.value })
                  }
                }}
                inputProps={{
                  maxlength: 10
                }}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
            >
              <Autocomplete
                id="combo-box-colonia"
                options={coloniasDisponibles}
                value={getter.colonia}
                onChange={(event, newValue) => {
                  setter({ ...getter, colonia: newValue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Colonia"
                    placeholder="Elige una colonia"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
              id='inputCodigoPostal'
              onChange={catchCodigoPostal}
              value={getter.codigoPostal}
              required={true}
              label='Código postal'
              placeholder='45620'
              fullWidth={true}
              margin='none'
              inputProps={{ maxlength: '5', readOnly: true}}
            />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
              id='inputMunicipio'
              value={municipio}
              required={true}
              label='Municipio'
              onChange={event => setter({ ...getter, municipio: event.target.value })}
              fullWidth={true}
              margin='none'
              InputProps={{
                readOnly: true,
              }}
            />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
              id='inputEstado'
              value={estado}
              required={true}
              label='Estado'
              fullWidth={true}
              onChange={event => setter({ ...getter, estado: event.target.value })}
              margin='none'
              InputProps={{
                readOnly: true,
              }}
            />
            </Grid>
            <Grid
              item
              xs={12}
            >
             <TextField
              id='inputPais'
              value={pais}
              required={true}
              label='País'
              onChange={event => setter({ ...getter, pais: event.target.value })}
              fullWidth={true}
              margin='none'
              InputProps={{
                readOnly: true,
              }}
            />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                variant='outlined'
                label='Referencia'
                placeholder='Maximo 30 caracteres'
                value={getter.referencia}
                onChange={event => {
                  const filteredValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
                  setter({ ...getter, referencia: filteredValue });
                }}
                inputProps={{
                  maxlength: 30
                }}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  )
}

function filtrarCaracteres(texto) {
  let textoNormalizado = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return textoNormalizado.replace(/[^a-zA-Z0-9 ]/g, '');
}
