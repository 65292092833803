import React from "react";
import { Stack, Typography } from '@mui/material'
import { Person, Numbers } from '@mui/icons-material'

export default function CostumerInfo({ name, DNI }){
    return(
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='center'
          border='2px solid #ff4500'
          borderRadius='16px'
          height='30px'
          padding='0 8px'
          width='35%'
        >
          <Typography 
            display='flex'
            alignItems='center'
            justifyContent='center'
            fontSize='14.5px' 
            fontWeight='700'
          >
            <Person 
              sx={{ 
                color: '#ff4500',
                fontSize: '21px'
              }} 
            />
            {name}
          </Typography>
          {!['', null].includes(DNI) && <>
              <Typography
                fontSize='14.5px'
                fontWeight='700'
              >
                -
              </Typography>

              <Typography
                display='flex'
                alignItems='center'
                justifyContent='center'
                fontSize='14.5px' 
                fontWeight='700'
              >
                <Numbers 
                  sx={{ 
                    color: '#ff4500',
                    fontSize: '21px' 
                  }} 
                />
                {DNI}
              </Typography>
            </>
          }
        </Stack>
    )
}