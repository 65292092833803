import React, {useEffect, useState} from 'react'
import {Button, Card, CardMedia, CardContent, CardActions,Grid, Typography,} from '@mui/material'



export default function VideoTutorials() { 
    const [videos, setVideos] = useState([])
    const localhost = sessionStorage.getItem('localhost')
    const token = sessionStorage.getItem('token')

    const [loading, setLoading] = useState(false)

    async function getVideos() {
        try {
            setLoading(true)
            const request = await fetch(`${localhost}/api/v2/support/tutorials`, {
                method: 'GET',
                headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
                }
            })
        
            if (request.ok) {
                const response = await request.json()
                setVideos(response)
            }
            setLoading(false)

        } catch (error) {
          
          console.error('Get profile:', error)
        }
      }
    useEffect(() => {
      getVideos()
    }, [])
    /*    
    const videos = [
        {
            title: 'Tutorial 1',
            src: 'https://www.youtube.com/embed/EKzYIcsSkLw?si=xbIC1P8_qJrcZaGy',
            description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        },
        {
            title: 'Tutorial 2',
            src: 'https://www.youtube.com/embed/EKzYIcsSkLw?si=xbIC1P8_qJrcZaGy',
            description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        },
        {
            title: 'Tutorial 3',
            src: 'https://www.youtube.com/embed/EKzYIcsSkLw?si=xbIC1P8_qJrcZaGy',
            description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        }
      ];*/

    return(
        <Grid
                backgroundColor='default'
                container
                marginTop={2}
            >
                {videos.map((video, index) => (
                    <Grid
                        backgroundColor='default'
                        item 
                        xs={12}
                        md={6}
                        key={index}
                    >
                        <Card
                            xs={6} 
                            key={index}
                            sx={{ maxWidth: '100%', margin: 1, backgroundColor:'default' }}
                        >
                            <CardMedia sx={{ height: 8, backgroundColor:'black' }} />
                            <iframe
                                width='100%'
                                //minHeight='500 em'
                                src={video.src}
                                title='YouTube video player'
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                allowFullScreen
                            >
                            </iframe>
                            <CardContent 
                                sx={{
                                    backgroundColor:'default',
                                    //maxHeight: 900, 
                                    margin: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 'auto',
                                    pl: 1, pr: 1
                                }}
                            >
                                <Typography 
                                    gutterBottom 
                                    variant='h5' 
                                    component='div'
                                >
                                    {video.title}
                                </Typography>
                                <Typography variant='body2' color='text.secondary'>
                                    {video.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size='small'>Share</Button>
                                <Button size='small'>Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
    )
}

