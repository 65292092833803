/* eslint-disable no-undef */
import loadScript, { loadAndSetScript } from './scriptLoader';

const head = typeof document !== 'undefined' ? document.querySelector('head') : '';

export const loadOpenPay = setOpenPayLoaded => {
  if (!document.querySelector('#openPayInstance')) {
    try {
      loadScript('https://resources.openpay.mx/lib/openpay-js/1.2.38/openpay.v1.min.js', head, 'openPayInstance');
      document.querySelector('#openPayInstance').addEventListener('load', () => {
        const openpay = window.OpenPay;
        openpay.setId(process.env.REACT_APP_OPENPAY_MERCHANT_ID);
        openpay.setApiKey(process.env.REACT_APP_OPENPAY_API_KEY);
        openpay.setSandboxMode(process.env.REACT_APP_OPENPAY_SANDBOX_MODE === 'dev' ? true : false);
        setOpenPayLoaded(true);
      });
    } catch (error) {
      setOpenPayLoaded(false)
    }
  }
};

export const loadAntiFraud = setAntiFraudLoaded => {
  if (!document.querySelector('#openPayAntiFraud')) {
    try {
      loadScript('https://resources.openpay.mx/lib/openpay-data-js/1.2.38/openpay-data.v1.min.js', head, 'openPayAntiFraud');
      setAntiFraudLoaded(true);
    } catch (error) {
      setAntiFraudLoaded(false)
    }
  }
};
