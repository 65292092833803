import { createContext, useEffect, useState } from 'react'

const ShoppingCartContext = createContext(1)

const ShoppingCartProvider = ({ children }) => {
  const user = JSON.parse(sessionStorage.getItem('user'))
  const permissions = JSON.parse(sessionStorage.getItem('permissions'))
  const [sessions] = useState(JSON.parse(localStorage.getItem('sessions')))
  const [carts, setCarts] = useState([])
  const [actualCart, setActualCart] = useState(0)

  useEffect(() => {
    if(sessions !== null){
      const carts = sessions[user.id].cart
      if (!carts) {
        if(!['Admin', 'PDV', 'Supervisor'].includes(permissions['role'])){
          addCart()
        }
      } else {
        setCarts(carts)
      }
    }
  }, [sessions])

  function updateSession(removingACart = null) {
    sessions[user.id].cart = removingACart !== null ? removingACart : carts
    localStorage.setItem('sessions', JSON.stringify(sessions))
  }

  function addCart(costumer = {}) {
    costumer = costumer['name'] ? { costumer: { ...costumer }} : {}

    if (carts.length >= 5) return
    carts.push({ waybills: [], products: [], deliveries: [], pendingPays: [], ...costumer })
    setActualCart(carts.length - 1)
    updateSession()
    setCarts([...carts])
  }

  function removeCart() {
    const cartsFiltered = carts.filter((cart, i) => i !== actualCart)
    setActualCart(actualCart === 0 ? 0 : actualCart - 1)
    updateSession(cartsFiltered)
    setCarts([...cartsFiltered])
  }

  function addItem(type, data) {
    if (carts[actualCart][type] === undefined) carts[actualCart][type] = []
    carts[actualCart][type].push({ ...data, stamp: new Date().valueOf(), type: type })
    updateSession()
    setCarts([...carts])
  }

  function removeItem(type, index) {
    carts[actualCart][type] = carts[actualCart][type].filter((item, i) => i !== index)
    updateSession()
    setCarts([...carts])
  }

  function addObject(type, data) {
    carts[actualCart][type] = data
    updateSession()
    setCarts([...carts])
  }

  function removeObject(type) {
    carts[actualCart][type] = undefined
    updateSession()
    setCarts([...carts])
  }

  function reset() {
    sessions[user.id] = { cart: [] }
    localStorage.setItem('sessions', JSON.stringify(sessions))
  }

  function print() {
    console.debug(carts)
  }

  const params = {
    carts,
    setCarts,
    addCart,
    removeCart,
    actualCart,
    setActualCart,
    addItem,
    removeItem,
    addObject,
    removeObject,
    print,
    reset
  }

  return <ShoppingCartContext.Provider value={params}>{children}</ShoppingCartContext.Provider>
}

export { ShoppingCartProvider }
export default ShoppingCartContext
