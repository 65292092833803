import { Grid, Stack, Container, Typography, useTheme } from '@mui/material'
import { DescriptionOutlined } from '@mui/icons-material'

import { PrePaymentCard } from '../../Shared'

export default function PaymentResume({ prePayment, amount, methodSelected }) {
  const theme = useTheme()
  return (
    <Stack
      spacing={1}
    >
      <Typography 
        variant='body1'
        fontWeight='700'
        textAlign='center'
      >
        Antes de <span style={{ color: '#ff4500' }}>continuar</span>, verifica que la información sea <span style={{ color: '#ff4500' }}>correcta:</span>
      </Typography>
  
      <Grid 
        container
        justifyContent='center'
      >
        {
          prePayment !== null ?
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                display='flex'
                flexDirection='column'
                textAlign='center'
                alignItems='center'
                justifyContent='center'
                gap={1}
              >
                <Typography 
                  variant='body1'
                  fontWeight='700'
                >
                  El paquete que contratara sera:
                </Typography>

                <PrePaymentCard
                  data={prePayment}
                  showPrePaymentCost={false}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography
                  fontSize={70}
                  fontWeight='700'
                >
                  +
                </Typography>
              </Grid>
            </>
          :
            <></>
        }

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          display='flex'
          alignItems='center'
        >
          <Stack
            sx={theme => ({
              border: prePayment === null ? '2px solid #ff4500' : '',
              borderRadius: prePayment === null ? 1 : 0,
              padding: prePayment === null ? 1 : 0,
              textAlign: prePayment === null ? 'center' : 'left',
              [theme.breakpoints.between('xs', 'sm')]: {
                textAlign: 'center'
              }
            })}
          >
            <Typography 
              variant='body1'
              fontWeight='700'
            >
              La cantidad de saldo, que se agregara sera de:
            </Typography>

            <Typography
              variant='body1'
              sx={{ fontSize: 50, fontWeight: 'bold', color: theme.palette.primary.main }}
            >
              ${amount}
            </Typography>

            <Typography 
              variant='body1'
              fontWeight='700'
            >
              Por vía <span style={{ color: '#ff4500' }}>{{ 1: 'transferencia bancaria', 2: 'tarjeta de crédito o débito', 3: 'Mercado Pago', 4: 'PayPal' }[methodSelected]}</span>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}
