export const fechaLarga = fecha => {
    const fechaEnTexto = fecha.toDateString()
    const [diaSemanaOriginal, mesOriginal, diaOriginal, annoOriginal] = fechaEnTexto.split(' ')
  
    let diaSemana = ''
    let mes = ''
    let anno = ''
  
    switch (diaSemanaOriginal) {
      case 'Mon':
        diaSemana = 'Lunes'
        break
  
      case 'Tue':
        diaSemana = 'Martes'
        break
  
      case 'Wed':
        diaSemana = 'Miércoles'
        break
  
      case 'Thu':
        diaSemana = 'Jueves'
        break
  
      case 'Fri':
        diaSemana = 'Viernes'
        break
  
      case 'Sat':
        diaSemana = 'Sábado'
        break
  
      case 'Sun':
        diaSemana = 'Domingo'
        break

      default:
        break
    }
  
    switch (mesOriginal) {
      case 'Jan':
        mes = 'enero'
        break
  
      case 'Feb':
        mes = 'febrero'
        break
  
      case 'Mar':
        mes = 'marzo'
        break
  
      case 'Apr':
        mes = 'abril'
        break
  
      case 'May':
        mes = 'mayo'
        break
  
      case 'Jun':
        mes = 'junio'
        break
  
      case 'Jul':
        mes = 'julio'
        break
  
      case 'Aug':
        mes = 'agosto'
        break
  
      case 'Sep':
        mes = 'septiembre'
        break
  
      case 'Oct':
        mes = 'octubre'
        break
  
      case 'Nov':
        mes = 'noviembre'
        break
  
      case 'Dec':
        mes = 'diciembre'
        break
  
      default:
        break
    }
  
    anno = annoOriginal
  
    return `${diaSemana} ${diaOriginal} de ${mes}, ${anno}`
  }
  
  // export const fechaCorta = fecha => {
  //   const fechaEnTexto = fecha.toDateString()
  //   const [diaSemanaOriginal, mesOriginal, diaOriginal, annoOriginal] = fechaEnTexto.split(' ')
  
  //   // let diaSemana = ''
  //   let mes = ''
  //   let dia = ''
  //   let anno = ''
  
  //   // switch (diaSemanaOriginal) {
  //   //   case 'Mon':
  //   //     diaSemana = 'Lun'
  //   //     break
  
  //   //   case 'Tue':
  //   //     diaSemana = 'Mar'
  //   //     break
  
  //   //   case 'Wed':
  //   //     diaSemana = 'Miér'
  //   //     break
  
  //   //   case 'Thu':
  //   //     diaSemana = 'Jue'
  //   //     break
  
  //   //   case 'Fri':
  //   //     diaSemana = 'Vie'
  //   //     break
  
  //   //   case 'Sat':
  //   //     diaSemana = 'Sáb'
  //   //     break
  
  //   //   case 'Sun':
  //   //     diaSemana = 'Dom'
  //   //     break
  //   // }
  
  //   if (diaOriginal < 9) {
  //     dia = `0${diaOriginal}`
  //   } else {
  //     dia = `${diaOriginal}`
  //   }
  
  //   switch (mesOriginal) {
  //     case 'Jan':
  //       mes = 'Ene'
  //       break
  
  //     case 'Feb':
  //       mes = 'Feb'
  //       break
  
  //     case 'Mar':
  //       mes = 'Mar'
  //       break
  
  //     case 'Apr':
  //       mes = 'Abr'
  //       break
  
  //     case 'May':
  //       mes = 'May'
  //       break
  
  //     case 'Jun':
  //       mes = 'Jun'
  //       break
  
  //     case 'Jul':
  //       mes = 'Jul'
  //       break
  
  //     case 'Aug':
  //       mes = 'Ago'
  //       break
  
  //     case 'Sep':
  //       mes = 'Sep'
  //       break
  
  //     case 'Oct':
  //       mes = 'Oct'
  //       break
  
  //     case 'Nov':
  //       mes = 'Nov'
  //       break
  
  //     case 'Dec':
  //       mes = 'Dic'
  //       break
  
  //     default:
  //       break
  //   }
  
  //   anno = annoOriginal.substring(2)
  
  //   return `${dia}/${mes}/${anno}`
  // }
  
  export const hora24 = tiempo => {
    let tiempoEnTexto = tiempo.toTimeString()
    let [hora, minutos] = tiempoEnTexto.split(':')
  
    return `${hora}:${minutos}`
  }
  
  export const hora12 = tiempo => {
    let tiempoEnTexto = tiempo.toTimeString()
    let [hora, minutos] = tiempoEnTexto.split(':')
    let meridium = 'AM'
  
    if (hora >= 12) {
      if (hora > 12) {
        hora = hora - 12
      }
      meridium = 'PM'
    }
  
    return `${hora}:${minutos} ${meridium}`
  }
  
  export const fechaConHora = fecha => {
    const fechaEnTexto = fecha.toDateString()
    const [diaSemanaOriginal, mesOriginal, diaOriginal, annoOriginal] = fechaEnTexto.split(' ')
  
    let diaSemana = ''
    let mes = ''
    let anno = ''
  
    let tiempoEnTexto = fecha.toTimeString()
    let [hora, minutos] = tiempoEnTexto.split(':')
  
    switch (diaSemanaOriginal) {
      case 'Mon':
        diaSemana = 'Lunes'
        break
  
      case 'Tue':
        diaSemana = 'Martes'
        break
  
      case 'Wed':
        diaSemana = 'Miércoles'
        break
  
      case 'Thu':
        diaSemana = 'Jueves'
        break
  
      case 'Fri':
        diaSemana = 'Viernes'
        break
  
      case 'Sat':
        diaSemana = 'Sábado'
        break
  
      case 'Sun':
        diaSemana = 'Domingo'
        break
      default:
        break
    }
  
    switch (mesOriginal) {
      case 'Jan':
        mes = 'enero'
        break
  
      case 'Feb':
        mes = 'febrero'
        break
  
      case 'Mar':
        mes = 'marzo'
        break
  
      case 'Apr':
        mes = 'abril'
        break
  
      case 'May':
        mes = 'mayo'
        break
  
      case 'Jun':
        mes = 'junio'
        break
  
      case 'Jul':
        mes = 'julio'
        break
  
      case 'Aug':
        mes = 'agosto'
        break
  
      case 'Sep':
        mes = 'septiembre'
        break
  
      case 'Oct':
        mes = 'octubre'
        break
  
      case 'Nov':
        mes = 'noviembre'
        break
  
      case 'Dec':
        mes = 'diciembre'
        break
  
      default:
        break
    }
  
    anno = annoOriginal
  
    return `${diaSemana} ${diaOriginal} de ${mes}, ${anno} a las ${hora}:${minutos}`
  }
  
  export const fechaConHoraCorta = fecha => {
    const fechaEnTexto = fecha.toDateString()
    const [diaSemanaOriginal, mesOriginal, diaOriginal, annoOriginal] = fechaEnTexto.split(' ')
  
    let diaSemana = ''
    let mes = ''
    let anno = ''
  
    let tiempoEnTexto = fecha.toTimeString()
    let [hora, minutos] = tiempoEnTexto.split(':')
  
    switch (diaSemanaOriginal) {
      case 'Mon':
        diaSemana = 'Lun'
        break
  
      case 'Tue':
        diaSemana = 'Mar'
        break
  
      case 'Wed':
        diaSemana = 'Miér'
        break
  
      case 'Thu':
        diaSemana = 'Jue'
        break
  
      case 'Fri':
        diaSemana = 'Vie'
        break
  
      case 'Sat':
        diaSemana = 'Sáb'
        break
  
      case 'Sun':
        diaSemana = 'Dom'
        break
      default:
        break
    }
  
    switch (mesOriginal) {
      case 'Jan':
        mes = 'ene'
        break
  
      case 'Feb':
        mes = 'feb'
        break
  
      case 'Mar':
        mes = 'mar'
        break
  
      case 'Apr':
        mes = 'abr'
        break
  
      case 'May':
        mes = 'may'
        break
  
      case 'Jun':
        mes = 'jun'
        break
  
      case 'Jul':
        mes = 'jul'
        break
  
      case 'Aug':
        mes = 'ago'
        break
  
      case 'Sep':
        mes = 'sep'
        break
  
      case 'Oct':
        mes = 'oct'
        break
  
      case 'Nov':
        mes = 'nov'
        break
  
      case 'Dec':
        mes = 'dic'
        break
  
      default:
        break
    }
  
    anno = annoOriginal
  
    return `${diaSemana}, ${diaOriginal} de ${mes}. ${anno} a las ${hora}:${minutos}`
  }
  
  export const yearRange = (min, max) => {
    const size = max - min
    const array = new Array(size)
    for (let i = 0; i <= size; i++) {
      array[i] = max - i
    }
    return array
  }