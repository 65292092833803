import { Snackbar, Alert } from '@mui/material'
import { useCallback } from 'react'

/**
 * @typedef {object} ComponentParams
 * @property {[boolean, React.SetStateAction<undefined>]} displayState
 * @property {number} [autoHideDuration = 4000]
 * @property {string} message
 * @property {('error'|'info'|'success'|'warning')} severity
 */

/**
 *
 * @param {ComponentParams} params
 * @returns
 */

export default function SnackBarAlert(params) {
  const { displayState, severity, autoHideDuration, message } = params
  const [show, setShow] = displayState

  const onCloseHandler = useCallback((event, reason) => reason !== 'clickaway' && setShow(false), [])

  return (
    <Snackbar
      open={show}
      autoHideDuration={autoHideDuration ?? 4000}
      onClose={onCloseHandler}
    >
      <Alert
        severity={severity ?? 'success'}
        sx={{ width: '100%' }}
        onClose={onCloseHandler}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
