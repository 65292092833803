import React, { useState, useEffect } from 'react'
import { Container, Grid, Paper, Button, Stack, Box, Typography, CircularProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import BackspaceIcon from '@mui/icons-material/Backspace'
import SaveIcon from '@mui/icons-material/Save'
import Copyright from '../../General/Copyright'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function CutOffsReports() {
  const envConfig = {
    url: process.env.REACT_APP_ENVIROMENT === 'prod' ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
  }
  const actualDate = new Date()
  const previousDate = new Date(actualDate.getTime() - 3 * 24 * 60 * 60 * 1000)

  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState(undefined)
  const [companies, setCompanies] = useState([{ A: 'a' }])
  const [users, setUsers] = useState([{ A: 'a' }])
  const [operation, setOperation] = useState({ opMovement: 0 })
  const [companieId, setCompanieId] = useState({ companieId: 0 })
  const [userId, setUserId] = useState({ userId: 0 })
  const [value] = useState('');
  const [descarga, setDescarga] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: previousDate,
      endDate: actualDate,
      key: 'selection'
    }
  ])
  const companieOptions = (companiesArray) => {
    return companiesArray.map((companie) => {
      return {
        label: companie.nombre_comercial,
        value: companie.id,
      };
    });
  };
  
  const userOptions = (usersArray) => {
    return usersArray.map((user) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  };

  const isValidUUID = value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);
  const UUIDValue = isValidUUID ? value : null;

  async function saveReports() {
    try {
    setLoading(true);
    setDescarga(true);
      const params = new URLSearchParams({
      UUIDValue: UUIDValue,
      userId: userId['userId'],
      companieId: companieId['companieId'],
      operation: operation['opMovement'],
      start: dateRange[0].startDate.toISOString().split('T', 1),
      end: dateRange[0].endDate.toISOString().split('T', 1)
    })
    
    const response = await fetch(`${envConfig.url}/api/v2/cut-offs/reports/save?${params}`, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
      }
  });
  
    if (response.ok) {
      
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `Reporte del ${params.get('start')} hasta el ${params.get('end')}.xlsx`;
    document.body.appendChild(link);
    link.click();
    link.remove();
    }
    setLoading(false)
    
    if (response != null) {
    setReports(response.data)
    }
    }
    catch (error) {
      console.error("Error en la solicitud:", error);
      setReports(null);
    } finally {
      setLoading(false);
    }
  }

  async function getReports() {
    const params = new URLSearchParams({
      UUIDValue: UUIDValue,
      userId: userId['userId'],
      companieId: companieId['companieId'],
      operation: operation['opMovement'],
      start: dateRange[0].startDate.toISOString().split('T', 1),
      end: dateRange[0].endDate.toISOString().split('T', 1)
    })
    try {
      setLoading(true);
  
      const response = await fetch(`${envConfig.url}/api/v2/cut-offs/reports?${params}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setReports(data.data);
      } else {
        setReports(null);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setReports(null);
    } finally {
      setLoading(false);
    }
  }
      
  async function getUsers() {
    try {
      const response = await fetch(`${envConfig.url}/api/v2/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        let filteredUsers = data.users;
        if (companieId.companieId && companieId.companieId !== 0) {
          filteredUsers = data.users.filter(user => user.empresa_id === companieId.companieId);
        }
        filteredUsers.unshift({ id: 0, name: 'Todos' });
        setUsers(filteredUsers);
      } else {
        console.error("Error en la respuesta", response);
        setUsers([]);
      }
    } catch (error) {

      console.error("Error en la solicitud", error);
      setUsers([]);
    }
  }
  async function getCompanies() {
    try {
      const response = await fetch(`${envConfig.url}/api/v2/companies`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        data.companies.unshift({ id: 0, nombre_comercial: 'Todos' });
        setCompanies(data.companies);
      } else {
        console.error("Error en la respuesta", response);
        setCompanies([]);
      }
    } catch (error) {

      console.error("Error en la solicitud", error);
      setCompanies([]);
    }
  }

  useEffect(() => {
    getUsers()
    getCompanies()
  }, [companieId])

  return (
    <Container
      maxWidth='lg'
      sx={{ mt: 4, mb: 4 }}
    >
      {/* Main */}

      <Grid
        container
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
      >
        {/* Formulario */}
        <Grid
          item
          xs={12}
          md='auto'
        >
          <Paper sx={{ p: 2 }}>
            <Stack
              spacing={2}
              direction={{ xs: 'column', sm: 'row', md: 'column' }}
            >
              {/* Calendario */}
              <DateRange
                editableDateInputs={false}
                sx={{ width: '100%' }}
                onChange={item => setDateRange([item.selection])}
                moveRangeOnFirstSelection={true}
                ranges={dateRange}
              />
              {/* Botones */}
              <Stack
                spacing={2}
                sx={{ width: '100%' }}
              >
                {/* Selector */}
                 <Autocomplete
                    disablePortal
                    id="companie-autocomplete"
                    options={companieOptions(companies)}
                    sx={{ width: '100%' }}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      setCompanieId({ companieId: newValue ? newValue.value : 0});
                    }}
                    renderInput={(params) => <TextField {...params} label="Compañía" />}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    value={companieOptions(companies).find(c => c.value === companieId.companieId) || null}
                  />
                <Autocomplete
                  disablePortal
                  id="user-autocomplete"
                  options={userOptions(users)}
                  sx={{ width: '100%' }}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    setUserId({ userId: newValue ? newValue.value :0 });
                  }}
                  renderInput={(params) => <TextField {...params} label="Usuario" />}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  value={userOptions(users).find(u => u.value === userId.userId) || null}
                />
                {/* <input
                  type="text"
                  value={value}
                  onChange={handleChange}
                  placeholder="Ingrese un UUID"
                />
                <p>Valor a enviar: {UUIDValue ? UUIDValue : 'No válido'}</p> */}

                {/* Enviar */}
                <Button
                  variant='contained'
                  type='submit'
                  onClick={() => getReports()}
                  fullWidth={true}
                  disabled={loading}
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </Button>

                {/* Guardar */}
                {reports?.length > 0 && loading === false && (
                  <Button
                    variant='outlined'
                    color='info'
                    fullWidth={true}
                    type='reset'
                    disabled={loading}
                    startIcon={<SaveIcon />}
                    onClick={saveReports}
                  >
                    Guardar como Excel
                  </Button>
                  
                )}
                {/* Limpiar */}
                <Button
                  variant='outlined'
                  fullWidth={true}
                  type='reset'
                  disabled={loading}
                  color='error'
                  startIcon={<BackspaceIcon />}
                  onClick={() => {
                    setDateRange([
                      {
                        startDate: previousDate,
                        endDate: actualDate,
                        key: 'selection'
                      }
                    ])
                    setOperation({ opMovement: 1 })
                    setReports(undefined)
                  }}
                >
                  Limpiar
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Grid>

        {/* Resultados */}
        <Grid
          item
          xs={12}
          md
        >
          <Paper sx={{ width: '100%', height: '100%', minHeight: '30em', p: 2 }}>
            {reports === undefined && loading === false && (
              <Stack
                paddingX={'20%'}
                justifyContent='center'
                alignContent='center'
                sx={{ height: '100%' }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <SearchIcon sx={{ fontSize: 75 }} />
                  <Typography variant='h5'>Generar reporte</Typography>
                  <Typography variant='body1'>Para empezar, selecciona un rango de fechas y el proceso del que deseas buscar</Typography>
                </Box>
              </Stack>
            )}

            {loading === true && (
              <Stack
                paddingX={'20%'}
                justifyContent='center'
                alignContent='center'
                sx={{ height: '100%' }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CircularProgress size={75} />
                  <Typography variant='h5'>
                    {descarga ? 'Descargando guías' : 'Buscando guías'}
                  </Typography>
                  <Typography variant='body1'>Espera un momento en lo que encontramos realizamos la búsqueda</Typography>
                </Box>
              </Stack>
            )}

            {reports !== undefined &&
              loading === false &&
              (reports?.length > 0 ? (
                <Box sx={{ height: '100%', width: '100%' }}>
                  <DataGrid
                    sx={{ minHeight:400, height: '100%', width: '100%' }}
                    rows={reports}
                    columns={[
                      {
                        field: 'date',
                        headerName: 'Fecha',
                        resizable: true,
                        width: 175
                      },
                      {
                        field: 'user',
                        headerName: 'Usuario',
                        resizable: true,
                        width: 200,
                        valueGetter: (params) => params.row.user.user
                      },
                      {
                        field: 'folio',
                        headerName: 'Folio',
                        resizable: true,
                        width: 100
                      },
                      {
                        field: 'amount',
                        headerName: 'Monto',
                        resizable: true,
                        width: 100,
                        valueFormatter: (params) => `$${params.value}`
                      }
                    ]}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                  />
                </Box>
              ) : (
                <Stack
                  paddingX={'20%'}
                  justifyContent='center'
                  alignContent='center'
                  sx={{ height: '100%' }}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <SearchOffIcon sx={{ fontSize: 75 }} />
                    <Typography variant='h5'>Sin resultados</Typography>
                    <Typography variant='body1'>No encontramos guías que cumplan los parámetros que solicitaste</Typography>
                  </Box>
                </Stack>
              ))}
          </Paper>
        </Grid>
      </Grid>

      {/* Footer */}
      <Grid
        container
        paddingTop={2}
      >
        <Grid
          item
          xs={12}
        >
          <Paper
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 50
            }}
          >
            <Copyright sx={{ pt: 4 }} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
