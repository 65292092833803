import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Box, CardContent, CardActions, TextField, Grid, FormGroup, FormControlLabel, Checkbox, FormControl, FormHelperText, InputLabel, FilledInput, InputAdornment, IconButton, Typography, Container, OutlinedInput, Paper, CircularProgress } from '@mui/material'
import { circularProgressClasses } from '@mui/material/CircularProgress'

//import { useNavigate } from "react-router-dom";


import { Visibility, VisibilityOff, Key, AccountCircle } from '@mui/icons-material'

import '../GuiasAdmin/Shipping/shippingStyles.css'
import Copyright from '../../General/Copyright'

// export let flag = false;
// export function changeFlag(){
//     flag = !flag;
//     console.log(flag);
// }

export default function LogInIndex() {
  const navigate = useNavigate()


  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [isEmail, setIsEmail] = useState(true)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [waitAnResult, setWaitAnResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    emailError: {
      status: false,
      message: ''
    },
    passwordError: {
      status: false,
      message: ''
    },
    userNotFoundError: {
      status: false,
      message: ''
    }
  })

  const textFieldCSS = {
    backgroundColor: '#ffffff',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    width: '97%',
    marginTop: '12px',
    marginBottom: '-27px'
  }

  const gridButtonLogInCSS = theme => ({
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '12px',
    marginRight: '13px',
    [theme.breakpoints.between('xs', 'sm')]: {
      justifyContent: 'center',
      margin: '0 auto 12px auto'
    }
  })

  const buttonLogInCSS = {
    backgroundColor: '#ff4500',
    '&:hover': {
      backgroundColor: '#e93f01'
    }
  }

  const checkboxCSS = {
    marginLeft: '4px'
  }

  function goTo() {
    navigate('/dashboard')
  }

  const catchEmailOrPhone = event => {
    if (isNaN(event.target.value)) {
      setIsEmail(true)
      setEmail(event.target.value)
      setPhone('')
      return
    }

    setIsEmail(false)
    setEmail('')
    setPhone(event.target.value)
  }
  const catchPassword = event => setPassword(event.target.value)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = event => event.preventDefault()

  const envConfig = {
    url: process.env.REACT_APP_ENVIROMENT === 'prod' ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV,
    image: process.env.REACT_APP_IMAGE_LOGIN ? require(process.env.REACT_APP_IMAGE_LOGIN) : ''
  }

  const logIn = async event => {
    setWaitAnResult(true)
    setLoading(true)
    event.preventDefault()

    await fetch(`${envConfig.url}/api/auth/login`, {
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phone: phone,
        email: email,
        password: password
      })
    })
      .then(res => res.json())
      .then(data => {
        switch (data.data.status) {
          case 404:
            setErrors({
              emailError: {
                status: false,
                message: ''
              },
              passwordError: {
                status: false,
                message: ''
              },
              userNotFoundError: {
                status: true,
                message: data.data.error
              }
            })
            break
          case 422:
            if (email === '' && phone === '' && password === '') {
              setErrors({
                emailError: {
                  status: true,
                  message: data.data.error.email[1]
                },
                passwordError: {
                  status: true,
                  message: data.data.error.password
                },
                userNotFoundError: {
                  status: false,
                  message: ''
                }
              })}
              
    /*const textFieldCSS = {
        backgroundColor : '#ffffff',
        borderTopLeftRadius : '5px',
        borderTopRightRadius : '5px',
        width : '97%',
        marginTop : '12px',
        marginBottom : '-27px'
    };

    const gridButtonLogInCSS = ( theme ) => ({
        display : 'flex',
        justifyContent : 'end',
        marginBottom : '12px',
        marginRight : '13px',
        [ theme.breakpoints.between( 'xs' , 'sm' ) ] : {
            justifyContent : 'center',
            margin : '0 auto 12px auto'
        }
    })*/

    const buttonLogInCSS = {
        backgroundColor : '#ff4500',
        '&:hover': {
            backgroundColor: '#e93f01'
        },
    }

    /*const checkboxCSS = {
        marginLeft : '4px'
    }

    function goTo(){
        navigate('/dashboard');
    }*/

    const catchEmailOrPhone = event => {
        if( isNaN( event.target.value ) ){
            setIsEmail( true );
            setEmail( event.target.value );
            setPhone( '' );
            return;
        }

        setIsEmail( false );
        setEmail( '' );
        setPhone( event.target.value );
    }
    const catchPassword = event => setPassword( event.target.value );
    const handleClickShowPassword = () => setShowPassword( !showPassword );
    const handleMouseDownPassword = event => event.preventDefault();

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
        image:process.env.REACT_APP_IMAGE_LOGIN?require(process.env.REACT_APP_IMAGE_LOGIN):""
    };

    const logIn = async ( event ) => {
        setWaitAnResult( true );
        setLoading( true );
        event.preventDefault();

        await fetch(`${envConfig.url}/api/auth/login`, {
            method : "POST",
            headers : {
                "Cache-Control": "no-cache",
                "Content-Type" : "application/json"
            },
            body : JSON.stringify( {
                phone : phone,
                email : email,
                password : password,
            } )
        } )
            .then( res => res.json() )
            .then( data => {
                switch( data.data.status ){
                    case 404: 
                        setErrors({
                            emailError : {
                                status : false,
                                message : ''
                            },
                            passwordError : {
                                status : false,
                                message : ''
                            },
                            userNotFoundError : {
                                status : true,
                                message : data.data.error
                            }
                        });
                    break;
                    case 422:
                        if( email === '' && phone === '' && password === '' ){
                            setErrors({
                                emailError : {
                                    status : true,
                                    message : data.data.error.email[1]
                                },
                                passwordError : {
                                    status : true,
                                    message : data.data.error.password
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }

                        if( email === '' && password !== '' && phone === '' ){
                            setErrors({
                                emailError : {
                                    status : true,
                                    message : data.data.error.email[1]
                                },
                                passwordError : {
                                    status : false,
                                    message : ''
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }

                        if( password === '' && email !== '' && phone === '' ){
                            setErrors({
                                emailError : {
                                    status : false,
                                    message : ''
                                },
                                passwordError : {
                                    status : true,
                                    message : data.data.error.password
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }

                        if( phone.length !== 10 && ( password === '' || password !== '' ) && email === '' ){
                            setErrors({
                                emailError : {
                                    status : true,
                                    message : data.data.error.phone[0]
                                },
                                passwordError : {
                                    status : ( password === '' ) ? true : false,
                                    message : ( password === '' ) ? data.data.error.password : ''
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }

                        if( !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
                            && ( password === '' || password !== '' ) && phone === '' ){
                            setErrors({
                                emailError : {
                                    status : true,
                                    message : data.data.error.email[0]
                                },
                                passwordError : {
                                    status : ( password === '' ) ? true : false,
                                    message : ( password === '' ) ? data.data.error.password : ''
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }
                    break;
                    case 200:
                        setErrors({
                            emailError : {
                                status : false,
                                message : ''
                            },
                            passwordError : {
                                status : false,
                                message : ''
                            },
                            userNotFoundError : {
                                status : false,
                                message : ''
                            }
                        });

                        sessionStorage.setItem( 'token' , data.data.token );
                        sendUserToken();
                    break;
                }
              })
              return
            }

            if (email === '' && password !== '' && phone === '') {
              setErrors({
                emailError: {
                  status: true,
                  message: data.data.error.email[1]
                },
                passwordError: {
                  status: false,
                  message: ''
                },
                userNotFoundError: {
                  status: false,
                  message: ''
                }
              })
              return
            }

            if (password === '' && email !== '' && phone === '') {
              setErrors({
                emailError: {
                  status: false,
                  message: ''
                },
                passwordError: {
                  status: true,
                  message: data.data.error.password
                },
                userNotFoundError: {
                  status: false,
                  message: ''
                }
              })
              return
            }

            if (phone.length !== 10 && (password === '' || password !== '') && email === '') {
              setErrors({
                emailError: {
                  status: true,
                  message: data.data.error.phone[0]
                },
                passwordError: {
                  status: password === '' ? true : false,
                  message: password === '' ? data.data.error.password : ''
                },
                userNotFoundError: {
                  status: false,
                  message: ''
                }
              })
              return
            }

            if (!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) && (password === '' || password !== '') && phone === '') {
              setErrors({
                emailError: {
                  status: true,
                  message: data.data.error.email[0]
                },
                passwordError: {
                  status: password === '' ? true : false,
                  message: password === '' ? data.data.error.password : ''
                },
                userNotFoundError: {
                  status: false,
                  message: ''
                }
              })
              return
            }
            break
          case 200:
            setErrors({
              emailError: {
                status: false,
                message: ''
              },
              passwordError: {
                status: false,
                message: ''
              },
              userNotFoundError: {
                status: false,
                message: ''
              }
            })

            sessionStorage.setItem('token', data.data.token)
            sendUserToken()
            break
        }
      })
      .catch(error => alert(`Algo ocurrio :C : ${JSON.stringify(error)}`))

    setLoading(false)
    setWaitAnResult(false)
  }

  const sendUserToken = async () => {
    await fetch(`${envConfig.url}/api/v2/profile`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': `application/json`,
        'Cache-Control': 'no-cache'
      }
    })
      .then(res => res.json())
      .then(data => {
        sessionStorage.setItem('user', JSON.stringify(data.data))
        getRolePermissions()
      })
      .catch(error => alert(`error: ${JSON.stringify(error)}`))
  }

  const getRolePermissions = async () => {
    await fetch(`${envConfig.url}/api/v2/permissions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Cache-Control': 'no-cache'
      }
    })
      .then(res => res.json())
      .then(data => {
        sessionStorage.setItem('permissions', JSON.stringify(data.data))
        window.location.reload()
      })
      .catch(error => alert(`error :C: ${JSON.stringify(error)}`))
  }

  return (
    <Container 
        maxWidth='sm'
        sx={{
          marginTop : '150px'
        }}
    >
      <Box
        sx={{
          width: '275px',
          height: '115px',
          borderRadius : '1px'
        }}
        component = "img"
        src = {envConfig.image}
        alt = "Login"
      />

      <Paper
        elevation={3}
        sx={{ marginY: 3, paddingX: 5, paddingY: 5 }}
      >
        <form onSubmit={logIn}>
          <Container sx={{ textAlign: 'center' }}>
            <Grid
              container
              spacing={5}
            >
              <Grid
                item
                xs={12}
                md={12}
              >
                <TextField
                  error={errors.emailError.status}
                  id='outlined-text'
                  label='Ingrese su correo electronico o telefono'
                  variant='outlined'
                  onChange={catchEmailOrPhone}
                  value={isEmail ? email : phone}
                  helperText={errors.emailError.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountCircle sx={{ color: '#ff4500' }} />
                      </InputAdornment>
                    )
                  }}
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
              >
                <FormControl
                  error={errors.passwordError.status}
                  variant='outlined'
                  fullWidth={true}
                >
                  <InputLabel htmlFor='outlined-adornment-password'>Contraseña</InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-password'
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={catchPassword}
                    startAdornment={
                      <InputAdornment position='start'>
                        <Key sx={{ color: '#ff4500' }} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff sx={{ color: '#ff4500' }} /> : <Visibility sx={{ color: '#ff4500' }} />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Contraseña'
                  />
                  <FormHelperText>{errors.passwordError.message}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
              >
                <div align='left'>
                  <Button
                    variant='contained'
                    sx={buttonLogInCSS}
                    type='submit'
                    disabled={waitAnResult}
                  >
                    Ingresar
                  </Button>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={7}
              >
                {loading ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <Box>
                        <CircularProgress
                          variant='indeterminate'
                          disableShrink
                          size={40}
                          sx={{
                            color: '#ff4500',
                            animationDuration: '550ms',
                            [`& .${circularProgressClasses.circle}`]: {
                              strokeLinecap: 'round'
                            }
                          }}
                        />
                      </Box>
                      <Typography
                        variant='body2'
                        fontWeight='bold'
                      >
                        Procesando, espere un momento...
                      </Typography>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Container>

          <Container sx={{ textAlign: 'center' }}>
            {errors.userNotFoundError.status ? (
              <h5
                style={{
                  marginTop: '12px',
                  marginBottom: '-20px',
                  color: '#b40000'
                }}
              >
                {errors.userNotFoundError.message}
              </h5>
            ) : (
              <></>
            )}
          </Container>
        </form>
      </Paper>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  )
}
