import React from 'react';
import {Grid} from '@mui/material';

import './shippingStyles.css';
import ButtonLink from'./MarcoComponents/ButtonLink';
import CardService from './MarcoComponents/CardService';

function LoadingShippingInfo(props){
    const data = require("./shippingInfoList.json");
    const { shipping } = data;

    const cardsShipping = shipping.map(
        ( item , index ) => {
            return(
                <Grid
                    key = { index }
                    item 
                    xs = { 12 }>
                    <CardService 
                        url = "/shippingInsurance"
                        service = { item }
                    />
                </Grid>
            );
        }
    );

    return(
        <div
            className = "containerGrid"
        >
            <Grid
                container 
                spacing = { shipping.length }
            >

                {cardsShipping}

            </Grid>
        </div>
    );
}

export default function ShippingInfoList(){

    return(
        <div className = "container container--shippingInfoList">
            
            <ButtonLink 
                url = "/shipping" 
                clases = "containerboton" 
                texto = "Regresar"
            />

            <LoadingShippingInfo />

        </div>
    );
} 