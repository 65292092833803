import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

const SaveAddressCheckbox = ({ onSave, disabled, recipient }) => {
  const [checked, setChecked] = useState(false);
  const [mensajeDeError, setMensajeDeError] = useState('')
  const [tituloDeError, setTituloDeError] = useState('')
  const [error, setError] = useState(false)
  const [cargando, setCargando] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [saveSuccessful, setSaveSuccessful] = useState(false);


  const envConfig = {
    url: process.env.REACT_APP_ENVIROMENT === 'prod' ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
  }


  const guardarDireccion = async () => {
    await fetch(`${envConfig.url}/api/v2/addresses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify({
        nombre: recipient.nombre,
        apellidoPaterno: recipient.apellidoPaterno,
        apellidoMaterno: recipient.apellidoMaterno??'',
        telefono1: recipient.telefono1,
        telefono2: recipient.telefono2??'',
        calle: recipient.calle,
        numeroExterior: recipient.numeroExterior,
        numeroInterior: recipient.numeroInterior??'',
        colonia: recipient.colonia,
        municipio: recipient.municipio,
        estado: recipient.estado,
        pais: recipient.pais,
        codigoPostal: recipient.codigoPostal,
        empresa: recipient.empresa??'',
        referencia: recipient.referencia??'',
        coordenadaLatitud: recipient.coordenadaLatitud??'',
        coordenadaLongitud: recipient.coordenadaLongitud??''
      })
    })
      .then(respuesta => {
        if (respuesta.ok) {
          setButtonDisabled(true);
          setSaveSuccessful(true);
        } else {
          if (respuesta.status === 400) {
            setError(true)
            setTituloDeError('Ocurrió un problema')
            setMensajeDeError('El servidor tuvo un problema, inténtalo nuevamente.')
          }

          if (respuesta.status === 404) {
            setError(true)
            setTituloDeError('Ocurrió un problema')
            setMensajeDeError('Ingreso un código postal invalido, revíselo e inténtelo nuevamente')
          }
        }
      })
      .catch(error => {
        setError(true)
        setTituloDeError('Ocurrió un problema')
        setMensajeDeError('Tenemos problemas para comunicarnos con el servidor, inténtalo nuevamente más tarde, si el problema continúa, contacta con soporte técnico.')
      })

    setCargando(false)
  }
  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleSave = () => {
    if (checked && !disabled) {
      onSave();
      guardarDireccion();
    }
  };

  return (
    <div>
  {saveSuccessful ? (
    <div>La dirección se guardó exitosamente.</div>
  ) : (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheck}
            name="saveAddress"
            disabled={disabled} 
          />
        }
        label="Guardar esta dirección para futuros envíos"
      />
      <Button
        variant="contained"
        onClick={handleSave}
        disabled={!checked || disabled || buttonDisabled}
      >
        Guardar y continuar
      </Button>
    </>
  )}
</div>
  );
};

export default SaveAddressCheckbox;
