import { Modal, Box, Fade } from '@mui/material'
/**
 * @typedef {object} ComponentParams
 * @property {[boolean, React.SetStateAction<undefined>]} displayState
 * @property {string} transitionDuration
 * @property {string|number} width
 * @property {string|number} minWidth
 * @property {string|number} maxWidth
 * @property {string|number} height
 * @property {string|number} maxHeight
 * @property {string|number} minHeight
 * @property {number} padding
 * @property {boolean} avoidClose
 * @property {React.ReactNode} children
 */

/**
 *
 * @param {ComponentParams} params
 * @returns
 */

export default function ModalView(params) {
  const { displayState, children, transitionDuration, width, height, maxHeight, maxWidth, minWidth, minHeight, padding, avoidClose } = params
  const [show, setShow] = displayState

  return (
    <Modal
      open={show}
      onClose={() => (avoidClose ? null : setShow(false))}
    >
      <Fade
        in={show}
        style={{ transitionDuration: transitionDuration ?? '300ms' }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: width ?? 'auto',
            minWidth: minWidth ?? 'auto',
            maxWidth: maxWidth ?? 'none',
            height: height ?? 'auto',
            minHeight: minHeight ?? 'auto',
            maxHeight: maxHeight ?? 'none',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: padding ?? 4,
            overflowY: 'scroll'
          }}
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  )
}
