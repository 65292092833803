import { Skeleton, Stack } from '@mui/material'
import { useCallback } from 'react'
/**
 * @typedef {('rectangular'|'rounded'|'circular')} Skeleton
 */

/**
 * @typedef {object} ComponentParams
 * @property {('column'|'row'|'column-reverse'|'row-reverse')} direction
 * @property {Skeleton[]} types - Array of forms 'rectangular'|'rounded'|'rounded'
 * @property {number} numberOfItems
 * @property {number} width
 * @property {number} height
 * @property {boolean} randomHeight
 * @property {boolean} randomWidth
 * @property {number[]} widthRange
 * @property {number[]} heightRange
 * @property {('pulse'|'wave')} animation
 */

/**
 *
 * @param {ComponentParams} params
 * @returns
 */

export default function SkeletonLoad(params) {
  const { direction, types, numberOfItems, width, height, randomHeight, randomWidth, widthRange, heightRange, animation } = params
  const times = new Array(numberOfItems).fill()
  const randomInterval = useCallback((min, max) => Math.floor(Math.random() * (max - min + 1)) + min, [])
  const SkeletonItem = () => {
    let random = 0
    if (types?.length > 1) random = Math.floor(Math.random() * types?.length)
    const variant = types[random]
    let h, w
    if (randomHeight) {
      const [min, max] = heightRange
      h = randomInterval(min, max)
    }

    if (randomWidth) {
      const [min, max] = widthRange
      w = randomInterval(min, max)
    }

    return (
      <Skeleton
        animation={animation ?? 'wave'}
        variant={variant}
        width={w ?? width}
        height={h ?? height}
      />
    )
  }

  return (
    <Stack
      paddingY={1}
      direction={direction ?? 'column'}
      spacing={1}
    >
      {numberOfItems > 0 && times.map((item, index) => <SkeletonItem key={index} />)}
    </Stack>
  )
}
