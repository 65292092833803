import React from 'react'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';



export default function ContactSupport() {
    return(
        <Paper
            container
            marginTop={2}
        >
            <Stack
                alignItems='center'
                sx={{ textAlign: 'center' }}
            >
                    <SupportAgentIcon sx={{ fontSize: 130 }} />
                    <Typography>Hola!</Typography>
                    <Typography>Contacta con nosotros para solicitar ayuda personalizada.</Typography>
                    <Typography>Si tienes alguna duda o necesitas algun reporte favor de contactarnos por medio de los siguientes enlaces.</Typography>

                <Stack
                    padding={1}
                    paddingX={3}
                    direction='row'
                    spacing={2}
                >

                    <Button
                        variant='outlined'
                        href={`mailto:${process.env.REACT_APP_SALES_EMAIL}`}
                    >
                        Mandar correo
                    </Button>
                    <Button
                        variant='outlined'
                        href={`https://wa.me/${process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER}?text=Necesito ayuda tecnica.`}
                        target='_blank'
                    >
                        Mandar WhatsApp
                    </Button>

                </Stack>
                <Box marginTop={2}/>
            </Stack>
        </Paper>

    )
}