import React from 'react'
import {Container, Paper,Typography} from '@mui/material'
import Copyright from '../../General/Copyright'
import VideoTutorials from './VideoTutorials'
import ContactSupport from './ContactSupport'
import QAFrequents from './QAFrequents';

export default function Support() {

    return (
        <Container
        maxWidth='lg'
        sx={{ mt: 4, mb: 4, textAlign: 'center' }}
        >
            <Paper
                sx={{
                    p: 1,
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 50
                }}>
                    <Typography variant='h1' sx={{ paddingY: 2 }} >
                    Ayuda Tecnica
                    </Typography>
            </Paper>

            <VideoTutorials />
            <ContactSupport />
            <QAFrequents/>

            <Paper
                sx={{
                    p: 1,
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 50
                }}>
                <Copyright sx={{ pt: 4 }} />
            </Paper>

        </Container>
    )
}